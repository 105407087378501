import React, { useEffect, useContext } from "react";
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
    CalendarIcon,
    ChartBarIcon,
    MenuAlt2Icon,
    OfficeBuildingIcon,
    UserCircleIcon,
    UserGroupIcon,
} from '@heroicons/react/outline'
import { useParams, useNavigate } from "react-router-dom";
import api from "../../api";
import Sidebar from '../components/Sidebar'
import Breadcrumbs from "../components/Breadcrumbs";
import TextInput from "../components/TextInput";
import DateInput from "../components/DateInput";
import SelectInput from "../components/SelectInput";
import InlineCheckbox from "../components/InlineCheckbox";
import RadioButtons from "../components/RadioButtons";
import { DataContext } from "../../data";

const monthNames = ["", "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Setiembre", "Octubre", "Noviembre", "Diciembre"]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function PatientDetailPage() {

    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [submitError, setSubmitError] = useState('')
    const [patient, setPatient] = useState("")
    const [theEstablishments, setTheEstablishments] = useState([])
    const { idPatient } = useParams()

    const { isConnected, loadingPatients, editPatient, loadingEstablishments, establishments, getPatientById } = useContext(DataContext)

    const navigate = useNavigate();
    const [editable, setEditable] = useState({
        firstName: false,
        lastName: false,
        gender: false,
        document: false,
        location: false,
        birthdate: false,
        registrationDate: false,
        address: false,
        telephone: false,
        establishment: false,
        dxs: false
    })
    const [errors, setErrors] = useState({
        firstName: "",
        lastName: "",
        govId: "",
        birthdate: "",
        neighbourhood: "",
        establishment: "",
        dxs: ""
    });

    const pages = [
        { name: 'Pacientes', href: '/patients', current: false },
        { name: 'Detalle  del paciente', href: `/patients/${idPatient}`, current: false },
        { name: 'Perfil del paciente', href: '#', current: true }
    ]

    const sexRadio = [
        { id: "male", default: true, label: "Masculino", value: 0 },
        { id: "female", default: false, label: "Femenino", value: 1 }
    ]

    const getSex = function (sexNumber) {
        if (sexNumber === 0) {
            return 'Masculino'
        } else {
            return 'Femenino'
        }
    }

    const today = new Date();
    const minBirthdate = new Date(today.setFullYear(today.getFullYear() - 120));
    const handleChange = (field, value) => setPatient((prev) => ({ ...prev, [field]: value }));
    const handleEdit = (field, value) => setEditable((prev) => ({ ...prev, [field]: value }))
    const handleErrors = (field, value) => setErrors((prev) => ({ ...prev, [field]: value }))

    useEffect(() => { // getting establishments and patient profile data if there is connection
        if (isConnected) {
            api.establishments.getMulti().then((response) => {
                let usfs = response.results
                setTheEstablishments(usfs)
                api.patients.getOne(idPatient).then((response) => {
                    setPatient({
                        firstName: response.firstName,
                        lastName: response.lastName,
                        govId: response.govId,
                        address: response.address,
                        gender: response.gender,
                        birthdate: response.birthdate,
                        documentType: response.documentType,
                        provisionalCode: response.provisionalCode,
                        registrationDate: response.registrationDate,
                        age: response.age,
                        phone: response.phone,
                        establishment: response.establishmentId,
                        isDiabetic: response.isDiabetic,
                        hasHta: response.hasHta,
                        hasCancer: response.hasCancer

                    })
                    setLoading(false);
                })
            })
        }
    }, [])

    useEffect(() => { // getting establishments and patient profile data from db if there is not connection
        if (!isConnected && !loadingEstablishments && !loadingPatients) { // patients and establishment data available
            let usfs = establishments
            setTheEstablishments(usfs)
            let response = getPatientById(idPatient)
            setPatient({
                firstName: response.firstName,
                lastName: response.lastName,
                govId: response.govId,
                address: response.address,
                gender: response.gender,
                birthdate: response.birthdate,
                documentType: response.documentType,
                provisionalCode: response.provisionalCode,
                registrationDate: response.registrationDate,
                age: response.age,
                phone: response.phone,
                establishment: response.establishmentId,
                isDiabetic: response.isDiabetic,
                hasHta: response.hasHta,
                hasCancer: response.hasCancer

            })
            setLoading(false);
        }
    }, [loadingEstablishments, loadingPatients])

    const handleSubmit = (event) => {
        event.preventDefault()
        let error = false
        if (!patient.isDiabetic && !patient.hasHta && !patient.hasCancer) {
            handleErrors('dxs', 'Debe seleccionar al menos uno');
            error = true
        } else {
            handleErrors('dxs', '');
        }
        if (!error) {
            setLoadingSubmit(true)
            setSubmitError('')
            editPatient(idPatient, patient, function () {
                setLoadingSubmit(false)
                navigate(`/patients/${idPatient}`)
            }, function (err) {
                setLoadingSubmit(false)
                setSubmitError(err)
                console.log(err)
            })
        }
    }

    return (
        <div className="bg-gray-100 min-h-screen h-full">
            <Sidebar currentScreen={'Pacientes'} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="md:pl-64 flex flex-col flex-1">
                <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-100">
                    <button
                        type="button"
                        className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                        onClick={() => setSidebarOpen(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="flex flex-wrap sm:flex-nowrap justify-between flex-1 sm:ml-6 mt-6 gap-y-4">
                        <Breadcrumbs pages={pages} />
                        <button
                            type="submit"
                            className="mr-8 ml-4 sm:ml-0 items-center px-4 py-2 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-blue-600 hover:bg-blue-700"
                        >
                            {loadingSubmit ?
                                <div className="w-5 h-5 mx-4 border-b-2 border-white rounded-full animate-spin"></div> :
                                'Guardar'
                            }
                        </button>
                    </div>
                    {loading ?
                        <div className="flex h-screen mt-auto items-center justify-center ">
                            <div className="w-16 h-16 border-b-2 border-blue-900 rounded-full animate-spin"></div>
                        </div> :
                        <main className="flex-1">
                            <div className="py-6">
                                {submitError && <div className="px-4 sm:px-6 md:px-8 text-sm text-red-600 text-right">
                                    {submitError}
                                </div>}
                                <div className="max-w-7xl flex items-center px-4 sm:px-6 md:px-8">
                                    <h1 className="text-lg mt-6 leading-6 font-medium text-gray-700">Información del paciente</h1>
                                </div>
                                <div className="max-w-7xl px-4 sm:px-6 md:px-8">
                                    <div style={{ maxWidth: 1000 }} className="py-4">
                                        <ul role="list" className="divide-y divide-gray-200">
                                            <li className="px-4 py-4 sm:px-0">
                                                <div className="flex flex-1 items-center mt-2">
                                                    <div className="flex-1">
                                                        <p className="text-sm leading-5 font-medium text-gray-500">Nombre</p>
                                                    </div>
                                                    <div className="flex-1 mx-4">{editable.firstName ?
                                                        <TextInput errorMessage={errors.firstName} value={patient.firstName} handleChange={handleChange} name="firstName" className="w-40 sm:w-60 shadow-sm h-8 pl-2 border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm rounded-md" />
                                                        : <p className="text-sm leading-5 font-normal text-gray-900">{patient?.firstName}</p>}
                                                    </div>
                                                    <button onClick={() => handleEdit('firstName', !editable.firstName)} type="button" className="text-sm leading-5 font-medium text-blue-500 hover:text-blue-700">Editar</button>

                                                </div>
                                            </li>
                                            <li className="px-4 py-4 sm:px-0">
                                                <div className="flex flex-1 items-center mt-2">
                                                    <div className="flex-1">
                                                        <p className="text-sm leading-5 font-medium text-gray-500">Apellido</p>
                                                    </div>
                                                    <div className="flex-1 mx-4">{editable.lastName ?
                                                        <TextInput errorMessage={errors.lastName} value={patient.lastName} handleChange={handleChange} name="lastName" className="w-40 sm:w-60 shadow-sm h-8 pl-2 border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm rounded-md" />
                                                        : <p className="text-sm leading-5 font-normal text-gray-900">{patient?.lastName}</p>}
                                                    </div>
                                                    <button onClick={() => handleEdit('lastName', !editable.lastName)} type="button" className="text-sm leading-5 font-medium text-blue-500 hover:text-blue-700">Editar</button>
                                                </div>
                                            </li>
                                            <li className="px-4 py-4 sm:px-0">
                                                <div className="flex flex-1 items-center mt-2">
                                                    <div className="flex-1">
                                                        <p className="text-sm leading-5 font-medium text-gray-500">Sexo</p>
                                                    </div>
                                                    <div className="flex-1 mx-4">{editable.gender ?
                                                        <RadioButtons defaultValue={patient.gender} value={patient.gender} handleChange={handleChange} name="gender" options={sexRadio} />
                                                        : <p className="text-sm leading-5 font-normal text-gray-900">{getSex(+patient.gender)}</p>}
                                                    </div>
                                                    <button onClick={() => handleEdit('gender', !editable.gender)} type="button" className={editable.gender ? "text-sm leading-5 font-medium text-blue-500 hover:text-blue-700" : "text-sm leading-5 font-medium text-blue-500 hover:text-blue-700"}>Editar</button>
                                                </div>
                                            </li>
                                            <li className="px-4 py-4 sm:px-0">
                                                <div className="flex flex-1 items-center mt-2">
                                                    <div className="flex-1">
                                                        <p className="text-sm leading-5 font-medium text-gray-500">Fecha de nacimiento</p>
                                                    </div>
                                                    <div className="flex-1 mx-4">{editable.birthdate ?
                                                        <DateInput containerStyle={'w-40 sm:w-60'} mindate={minBirthdate.toISOString().substring(0, 10)} maxdate={new Date().toISOString().substring(0, 10)} errorMessage={errors.birthdate} defaultValue={patient.birthdate ? patient.birthdate : new Date().toISOString().substring(0, 10)} handleChange={handleChange} name="birthdate" />
                                                        : <p className="text-sm leading-5 font-normal text-gray-900">{patient.birthdate ? patient.birthdate.substring(8, 11) + " de " + monthNames[parseInt(patient.birthdate.substring(5, 7))] + " de " + patient.birthdate.substring(0, 4) : "-"}</p>}
                                                    </div>
                                                    <button onClick={() => handleEdit('birthdate', !editable.birthdate)} type="button" className={editable.birthdate ? "text-sm leading-5 font-medium text-blue-500 hover:text-blue-700" : "text-sm leading-5 font-medium text-blue-500 hover:text-blue-700"}>Editar</button>
                                                </div>
                                            </li>
                                            <li className="px-4 py-4 sm:px-0">
                                                <div className="flex flex-1 items-center mt-2">
                                                    <div className="flex-1">
                                                        <p className="text-sm leading-5 font-medium text-gray-500">Número de documento</p>
                                                    </div>
                                                    <div className="flex-1 mx-4">{editable.document ?
                                                        <TextInput errorMessage={errors.govId} value={patient.documentType == '0' ? patient.provisionalCode : patient.govId} handleChange={handleChange} name='govId' className="w-40 sm:w-60 shadow-sm h-8 pl-2 border border-gray-300 block sm:text-sm rounded-md" />
                                                        : <p className="text-sm leading-5 font-normal text-gray-900">{patient.documentType == 0 ? patient.provisionalCode : patient.govId}</p>}
                                                    </div>
                                                    {patient.documentType != 0 ?
                                                        < button onClick={() => handleEdit('document', !editable.document)} type="button" className="text-sm leading-5 font-medium text-blue-500 hover:text-blue-700">Editar</button>
                                                        : null}
                                                </div>
                                            </li>
                                            <li className="px-4 py-4 sm:px-0">
                                                <div className="flex flex-1 items-center mt-2">
                                                    <div className="flex-1">
                                                        <p className="text-sm leading-5 font-medium text-gray-500">Antecedentes personales</p>
                                                    </div>

                                                    <div className="flex-1 mx-4">{editable.dxs ?
                                                        <div className="flex flex-col space-y-4">
                                                            <InlineCheckbox checked={patient.isDiabetic} handleChange={handleChange} name="isDiabetic" label="Diabetes" />
                                                            <InlineCheckbox checked={patient.hasHta} handleChange={handleChange} name="hasHta" label="Conocido HTA" />
                                                            <InlineCheckbox checked={patient.hasCancer} handleChange={handleChange} name="hasCancer" label="Cáncer" />
                                                        </div >
                                                        :
                                                        <div className="space-y-4">
                                                            <div>
                                                                {
                                                                    patient.isDiabetic ? <span className="justify-center inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800">
                                                                        DM
                                                                    </span>
                                                                        : null
                                                                }
                                                                {patient.hasHta ? <span className="justify-center inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-yellow-100 text-yellow-800">
                                                                    HTA
                                                                </span>
                                                                    : null}
                                                                {patient.hasCancer ? <span className="justify-center inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-pink-100 text-pink-800">
                                                                    Cancer
                                                                </span>
                                                                    : null}
                                                            </div>
                                                        </div>
                                                    }
                                                    </div>
                                                    <button onClick={() => {
                                                        if (!patient.isDiabetic && !patient.hasHta && !patient.hasCancer) {
                                                            handleErrors('dxs', 'Debe seleccionar al menos uno');
                                                        } else {
                                                            handleErrors('dxs', '')
                                                            handleEdit('dxs', !editable.dxs)
                                                        }
                                                    }} type="button" className={editable.dxs ? "text-sm leading-5 font-medium text-blue-500 hover:text-blue-700" : "text-sm leading-5 font-medium text-blue-500 hover:text-blue-700"}>Editar</button>
                                                </div>
                                                <p className="mt-2 text-sm text-red-600" id="dxs-errors">
                                                    {errors.dxs}
                                                </p>
                                            </li>
                                            <li className="px-4 py-4 sm:px-0">
                                                <div className="flex flex-1 items-center mt-2">
                                                    <div className="flex-1">
                                                        <p className="text-sm leading-5 font-medium text-gray-500">Domicilio</p>
                                                    </div>
                                                    <div className="flex-1 mx-4">{editable.address ?
                                                        <TextInput value={patient.address} name="address" handleChange={handleChange} className="w-40 xs:w-50 sm:w-75 shadow-sm h-8 px-2 border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm rounded-md" />
                                                        : <p className="text-sm leading-5 font-normal text-gray-900">{patient?.address} <br /> {patient?.city}</p>}
                                                    </div>
                                                    <button onClick={() => handleEdit('address', !editable.address)} type="button" className="text-sm leading-5 font-medium text-blue-500 hover:text-blue-700">Editar</button>
                                                </div>
                                            </li>
                                            <li className="px-4 py-4 sm:px-0">
                                                <div className="flex flex-1 items-center mt-2">
                                                    <div className="flex-1">
                                                        <p className="text-sm leading-5 font-medium text-gray-500">Número de teléfono</p>
                                                    </div>
                                                    <div className="flex-1 mx-4">{editable.phone ?
                                                        <TextInput maxLength={20} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} value={patient.phone} name="phone" handleChange={handleChange} className="w-40 sm:w-60 shadow-sm h-8 px-2 border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm rounded-md" />
                                                        : <p className="text-sm leading-5 font-normal text-gray-900">{patient?.phone}</p>}
                                                    </div>
                                                    <button onClick={() => handleEdit('phone', !editable.phone)} type="button" className="text-sm leading-5 font-medium text-blue-500 hover:text-blue-700">Editar</button>
                                                </div>
                                            </li>
                                            <li className="px-4 py-4 sm:px-0">
                                                <div className="flex flex-1 items-center mt-2">
                                                    <div className="flex-1">
                                                        <p className="text-sm leading-5 font-medium text-gray-500">Establecimiento</p>
                                                    </div>
                                                    <div className="flex-1 mx-4">{editable.establishment ?
                                                        <SelectInput errorMessage={errors.establishment} value={patient.establishment} name="establishment" handleChange={handleChange} options={theEstablishments.map((element) => { return (<option value={element.id} label={element.name} />) })} className="w-40 sm:w-60 focus:ring-indigo-500 focus:border-indigo-500 block pr-10 sm:text-sm border-gray-300 rounded-md" />
                                                        : <p className="text-sm leading-5 font-normal text-gray-900">{patient.establishment.length > 2 ? patient.establishment : theEstablishments.find(element => element.id == patient.establishment).name}</p>}
                                                    </div>
                                                    <button onClick={() => handleEdit('establishment', !editable.establishment)} type="button" className={editable.establishment ? "text-sm leading-5 font-medium text-blue-500 hover:text-blue-700" : "text-sm leading-5 font-medium text-blue-500 hover:text-blue-700"}>Editar</button>
                                                </div>
                                            </li>
                                            <li className="px-4 py-4 sm:px-0">
                                                <div className="flex flex-1 items-center mt-2">
                                                    <div className="flex-1">
                                                        <p className="text-sm leading-5 font-medium text-gray-500">Fecha de registro</p>
                                                    </div>
                                                    <div className="flex-1 mx-4">{editable.registrationDate ?
                                                        <DateInput containerStyle={'w-40 sm:w-60'} maxdate={new Date().toISOString().substring(0, 10)} defaultValue={patient.registrationDate ? patient.registrationDate : new Date().toISOString().substring(0, 10)} handleChange={handleChange} name="registrationDate" />
                                                        : <p className="text-sm leading-5 font-normal text-gray-900">{patient.registrationDate ? patient.registrationDate.substring(8, 11) + " de " + monthNames[parseInt(patient.registrationDate.substring(5, 7))] + " de " + patient.registrationDate.substring(0, 4) : "-"}</p>}
                                                    </div>
                                                    <button onClick={() => handleEdit('registrationDate', !editable.registrationDate)} type="button" className={editable.registrationDate ? "text-sm leading-5 font-medium text-blue-500 hover:text-blue-700" : "text-sm leading-5 font-medium text-blue-500 hover:text-blue-700"} >Editar</button>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </main>

                    }
                </form>
            </div >
        </div >
    );
}
