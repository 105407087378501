import React from "react";
import { CalendarIcon } from "@heroicons/react/outline";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function DateInput(props) {

    return (
        <div className={props.containerStyle ? props.containerStyle : "w-48"}>
            {props.label && <label htmlFor={props.name} className={props.labelClassName ? props.labelClassName : "text-sm font-medium text-gray-700"}>
                {props.label}
            </label>}
            <div className="mt-1 relative rounded-md shadow-sm">
                <input
                    min={props.min}
                    onChange={(event) => props.handleChange(props.name, event.target.value)}
                    type="date"
                    defaultValue={props.defaultValue ? props.defaultValue : props.value}
                    name={props.name}
                    required={props.required}
                    id={props.name}
                    min={props.mindate}
                    max={props.maxdate}
                    className={props.className ? props.className : "focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"}
                />
            </div>
            <p className="mt-2 text-sm text-red-600" id={`${props.name}-error`}>
                {props.errorMessage}
            </p>
        </div>
    )
}