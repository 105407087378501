import React from "react";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function DescribedSelectInput(props) {

    return (
        <div className={props.containerClassName ? props.containerClassName : null}>
            <label htmlFor={props.name} className="block text-sm font-medium text-gray-700">
                {props.label}
            </label>
            <div className="mt-1 relative rounded-md">
                <div className="absolute inset-y-0 left-0 flex items-center">
                    <label htmlFor={props.name} className="sr-only">
                        {props.label}
                    </label>
                    <select
                        onChange={(event) => props.handleChange(props.name, event.target.value)}
                        id={props.name}
                        defaultValue={props.defaultValue}
                        name={props.name}
                        autoComplete={props.name}
                        className={props.className ? props.className : "focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-3 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"}
                    >
                        <option hidden selected value> -- </option>
                        {props.options}
                    </select>
                </div>
                <input
                    type="text"
                    name={props.name}
                    id={props.name}
                    disabled
                    className={props.placeholderClassName ? props.placeholderClassName : "focus:ring-indigo-500 focus:border-indigo-500 block w-32 pl-16 sm:text-sm border-gray-300 rounded-md"}
                    placeholder={props.placeholder}
                />
            </div>
        </div>
    )

}