import React, { useEffect } from "react";
import { useState } from 'react'
import { Link, useSearchParams } from "react-router-dom";
import {
    CalendarIcon,
    ChartBarIcon,
    OfficeBuildingIcon,
    UserCircleIcon,
    UserGroupIcon,
    PlusIcon,
    LocationMarkerIcon
} from '@heroicons/react/outline'
import { BanIcon } from "@heroicons/react/solid";
import api from "../../api";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import Table from "../components/Table";
import InlineCheckbox from "../components/InlineCheckbox";

const navigation = [
    { name: 'Pacientes', href: '/patients', icon: UserGroupIcon, current: false },
    { name: 'Reportes', href: '/reports', icon: ChartBarIcon, current: false },
    { name: 'Establecimientos', href: '/establishments', icon: OfficeBuildingIcon, current: false },
    { name: 'Usuarios', href: '/users', icon: UserCircleIcon, current: true },
    { name: 'Distritos', href: '/districts', icon: LocationMarkerIcon, current: false },
    // { name: 'Calendarios', href: '/calendars', icon: CalendarIcon, current: false },
]

const columns = [
    { name: 'name', displayText: 'Nombre y Apellido', clickable: true },
    { name: 'establishment', displayText: 'USF' },
    { name: 'document', displayText: 'CI' }
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function UserPage() {

    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [users, setUsers] = useState([])
    const [establishments, setEstablishments] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        api.establishments.getMulti().then((response) => {
            setEstablishments(response.results.map(({ name, id, department, district }) => {
                return ({
                    name: name, id: id, department: department, district: district
                })
            }));
            return response.results
        }
        ).then((response) => {
            let usfs = response
            api.users.getMulti().then((response) => {
                setUsers(response.results.filter((array) => { return array.firstName ? true : false }).map(({ firstName, lastName, profile, id }) => {
                    return ({
                        name: firstName + " " + lastName, id: id, document: profile?.govId ? profile.govId : null, establishment: profile?.establishment ? usfs[profile.establishment - 1].name : 'Administrador'
                    })
                })
                )
                setLoading(false)
            }
            )
        })

    }, [])


    return (
        <div>
            <Sidebar currentScreen={'Usuarios'} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="md:pl-64 flex flex-col h-screen flex-1">
                <Header setSidebarOpen={setSidebarOpen} searchPlaceholder="Buscar por nombre o número de documento de identidad" buttonText={!users.length ? null : "Nuevo usuario"} href="/users/new" />
                <main className="flex-1 bg-gray-100">
                    {loading ?
                        <div className="flex h-full items-center justify-center ">
                            <div className="w-16 h-16 border-b-2 border-blue-900 rounded-full animate-spin"></div>
                        </div> :
                        users.length ?
                            <div className="py-6">
                                <div className="px-4 sm:px-6 md:px-8">
                                    <Table module="users" columns={columns} rows={users} actiontext="Editar" />
                                </div>
                            </div>
                            :
                            <div className="flex h-full">
                                <div className="mx-auto my-auto text-center">
                                    <BanIcon className="h-12 w-12 mx-auto text-gray-400" aria-hidden="true" />
                                    <h3 className="mt-2 text-sm font-semibold text-gray-900">No se encontraron resultados</h3>
                                    <p className="mt-1 text-sm font-semibold text-gray-500">Probá con otros datos o ingresá un nuevo usuario.</p>
                                    <div className="mt-6">
                                        <Link
                                            to="/users/new"
                                            type="button"
                                            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-blue-600 hover:bg-blue-700"
                                        >
                                            <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                            Nuevo usuario
                                        </Link>
                                    </div>
                                </div>
                            </div>
                    }
                </main>
            </div>
        </div >
    );
}
