import React, { useContext, useEffect } from "react";
import { useState } from 'react'
import {
    PencilAltIcon,
    PencilIcon,
    MenuAlt2Icon
} from '@heroicons/react/outline'
import { useParams, useNavigate } from "react-router-dom";
import api from "../../api";
import Sidebar from "../components/Sidebar";
import Breadcrumbs from "../components/Breadcrumbs";
import DateInput from '../components/DateInput';
import ConsultationDetail from "../components/ConsultationDetail";
import { DataContext } from "../../data";

let tabs = [
    { name: 'Determinaciones', current: true },
    { name: 'Control anual', current: false },
    { name: 'Medicamentos', current: false },
    { name: 'Laboratorio', current: false },
    { name: 'Hábitos', current: false },
    { name: 'Educación', current: false },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

let dateToday = new Date()
dateToday = dateToday.toISOString().split('T')[0]

export default function ConsultationDetailPage() {

    const navigate = useNavigate()

    const [error, setError] = useState('')
    const [errorSubmit, setErrorSubmit] = useState('')

    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [tab, setTab] = useState(tabs[0])
    const [consultation, setConsultation] = useState({})
    const [editableDate, setEditableDate] = useState(false)
    const [errorDate, setErrorDate] = useState('')
    const [inputDate, setInputDate] = useState(null)

    const { idPatient } = useParams()
    const { idConsultation } = useParams()

    const { isConnected, loadingConsultations, getConsultationsById } = useContext(DataContext)

    const changeTab = ((value) => {
        tabs[tabs.findIndex((tab) => tab.current)].current = false
        tabs[tabs.findIndex((tab) => tab.name === value)].current = true
        setTab(tabs[tabs.findIndex((tab) => tab.current)])
    })

    useEffect(() => { // getting current consultation if there is connection
        if (isConnected) {
            api.consultations.getOne(idConsultation).then((response) => {
                setConsultation({
                    id: response.id,
                    attentionDate: response.attentionDate,
                    establishment: response.establishment.name,
                    weight: response.weight,
                    height: response.height,
                    waist: response.waist,
                    imc: response.weight && response.height ? Math.round((response.weight / ((response.height / 100) * (response.height / 100))) * 100) / 100 : null,
                    systolic: response.systolic,
                    diastolic: response.diastolic,
                    bloodGlucose: response.bloodGlucose,
                    lastMeal: response.lastMeal,
                    poliuria: response.poliuria,
                    polidipsia: response.polidipsia,
                    polifagia: response.polifagia,
                    weightloss: response.weightloss,
                    obsBloodGlucose: response.obsBloodGlucose,
                    diabeticFoot: response.diabeticFoot,
                    diabeticFootObs: response.diabeticFootObs,
                    diabeticFootDerive: response.diabeticFootDerive,
                    diabeticFootReferToLab: response.diabeticFootReferToLab,
                    fundus: response.fundus,
                    fundusObs: response.fundusObs,
                    fundusDerive: response.fundusDerive,
                    urineAnalysis: response.urineAnalysis,
                    urineAnalysisObs: response.urineAnalysisObs,
                    urineAnalysisReferToLab: response.urineAnalysisReferToLab,
                    nextVisit: response.nextVisit,
                    basalGlucose: response.basalGlucose,
                    totalCholesterol: response.totalCholesterol,
                    hba1c: response.hba1c,
                    ldlCol: response.ldlCol,
                    hdlCol: response.hdlCol,
                    triglycerides: response.triglycerides,
                    creatinine: response.creatinine,
                    addSaltWhenCooking: response.addSaltWhenCooking,
                    addSaltToFood: response.addSaltToFood,
                    fruitServingsPerDay: response.fruitServingsPerDay,
                    vegetableServingsPerDay: response.vegetableServingsPerDay,
                    performPhysicalActivity: response.performPhysicalActivity,
                    physicalActivityType: response.physicalActivityType,
                    physicalActivityDuration: response.physicalActivityDuration,
                    physicalActivityFrequency: response.physicalActivityFrequency,
                    smoke: response.smoke,
                    cigarettesPerDay: response.cigarettesPerDay,
                    timeOfTheFirstCigarette: response.timeOfTheFirstCigarette,
                    vape: response.vape,
                    wantToQuitSmoking: response.wantToQuitSmoking,
                    drinkAlcoholicBeverages: response.drinkAlcoholicBeverages,
                    drinksPerDay: response.drinksPerDay,
                    drinksPerWeek: response.drinksPerWeek,
                    hasDmMedication: response.hasDmMedication,
                    hasHtaMedication: response.hasHtaMedication,
                    dmMedications: response.dmMedications,
                    htaMedications: response.htaMedications,
                    otherDmMedication: response.otherDmMedication,
                    otherDmMedicationObs: response.otherDmMedicationObs,
                    otherHtaMedication: response.otherHtaMedication,
                    otherHtaMedicationObs: response.otherHtaMedicationObs,
                    nutritionEducation: response.nutritionEducation,
                    physicalActivityEducation: response.physicalActivityEducation,
                    smokingEducation: response.smokingEducation,
                    alcoholicBeveragesEducation: response.alcoholicBeveragesEducation,
                    importanceHtaMedication: response.importanceHtaMedication,
                    importanceDmMedication: response.importanceDmMedication,
                    etcEducation: response.etcEducation
                });
                setLoading(false);
            }, err => {
                console.log(err)
                setError('Hubo un problema al encontrar los datos de la consulta, inténtalo más adelante')
                setLoading(false);
            })
        }
    }, [])

    useEffect(() => { // getting current consultation from db if there is not connection
        if (!isConnected && !loadingConsultations) {
            let response = getConsultationsById(idConsultation)
            if (response) {
                setConsultation({
                    id: response.id,
                    attentionDate: response.attentionDate,
                    establishment: response.establishment.name,
                    weight: response.weight,
                    height: response.height,
                    waist: response.waist,
                    imc: response.weight && response.height ? Math.round((response.weight / ((response.height / 100) * (response.height / 100))) * 100) / 100 : null,
                    systolic: response.systolic,
                    diastolic: response.diastolic,
                    bloodGlucose: response.bloodGlucose,
                    lastMeal: response.lastMeal,
                    poliuria: response.poliuria,
                    polidipsia: response.polidipsia,
                    polifagia: response.polifagia,
                    weightloss: response.weightloss,
                    obsBloodGlucose: response.obsBloodGlucose,
                    diabeticFoot: response.diabeticFoot,
                    diabeticFootObs: response.diabeticFootObs,
                    diabeticFootDerive: response.diabeticFootDerive,
                    diabeticFootReferToLab: response.diabeticFootReferToLab,
                    fundus: response.fundus,
                    fundusObs: response.fundusObs,
                    fundusDerive: response.fundusDerive,
                    urineAnalysis: response.urineAnalysis,
                    urineAnalysisObs: response.urineAnalysisObs,
                    urineAnalysisReferToLab: response.urineAnalysisReferToLab,
                    nextVisit: response.nextVisit,
                    basalGlucose: response.basalGlucose,
                    totalCholesterol: response.totalCholesterol,
                    hba1c: response.hba1c,
                    ldlCol: response.ldlCol,
                    hdlCol: response.hdlCol,
                    triglycerides: response.triglycerides,
                    creatinine: response.creatinine,
                    addSaltWhenCooking: response.addSaltWhenCooking,
                    addSaltToFood: response.addSaltToFood,
                    fruitServingsPerDay: response.fruitServingsPerDay,
                    vegetableServingsPerDay: response.vegetableServingsPerDay,
                    performPhysicalActivity: response.performPhysicalActivity,
                    physicalActivityType: response.physicalActivityType,
                    physicalActivityDuration: response.physicalActivityDuration,
                    physicalActivityFrequency: response.physicalActivityFrequency,
                    smoke: response.smoke,
                    cigarettesPerDay: response.cigarettesPerDay,
                    timeOfTheFirstCigarette: response.timeOfTheFirstCigarette,
                    vape: response.vape,
                    wantToQuitSmoking: response.wantToQuitSmoking,
                    drinkAlcoholicBeverages: response.drinkAlcoholicBeverages,
                    drinksPerDay: response.drinksPerDay,
                    drinksPerWeek: response.drinksPerWeek,
                    hasDmMedication: response.hasDmMedication,
                    hasHtaMedication: response.hasHtaMedication,
                    dmMedications: response.dmMedications,
                    htaMedications: response.htaMedications,
                    otherDmMedication: response.otherDmMedication,
                    otherDmMedicationObs: response.otherDmMedicationObs,
                    otherHtaMedication: response.otherHtaMedication,
                    otherHtaMedicationObs: response.otherHtaMedicationObs,
                    nutritionEducation: response.nutritionEducation,
                    physicalActivityEducation: response.physicalActivityEducation,
                    smokingEducation: response.smokingEducation,
                    alcoholicBeveragesEducation: response.alcoholicBeveragesEducation,
                    importanceHtaMedication: response.importanceHtaMedication,
                    importanceDmMedication: response.importanceDmMedication,
                    etcEducation: response.etcEducation
                });
            } else {
                setError('No pudimos encontrar los datos de la consulta, intentalo más adelante')
            }
            setLoading(false);
        }
    }, [loadingConsultations])

    const handleSubmit = function () {
        if (loadingSubmit) {
            console.log('wait until submit is over')
        } else {
            setErrorSubmit('')
            setLoadingSubmit(true)
            api.consultations.update(+idConsultation, consultation).then(res => {
                setLoadingSubmit(false)
                console.log('updated consultation')
                navigate(`/patients/${idPatient}/history`)
            }, err => {
                console.log('error updating consultation: ', err.response)
                setLoadingSubmit(false)
                setErrorSubmit('Hubo un error al actualizar los datos de la consulta, intentalo más adelante')
            })
        }
    }

    const pages = [
        { name: 'Pacientes', href: '/patients', current: false },
        { name: 'Detalle del paciente', href: `/patients/${idPatient}`, current: false },
        { name: 'Historial del Paciente', href: `/patients/${idPatient}/history`, current: false },
        { name: consultation.attentionDate, href: '#', current: true },
    ]

    return (
        <div className="bg-gray-100 min-h-screen">
            <Sidebar currentScreen={'Pacientes'} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="md:pl-64 flex flex-col flex-1">
                <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-100">
                    <button
                        type="button"
                        className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                        onClick={() => setSidebarOpen(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                {loading ?
                    <div className="flex h-screen items-center justify-center ">
                        <div className="w-16 h-16 border-b-2 border-blue-900 rounded-full animate-spin"></div>
                    </div>
                    :
                    error ?
                        <div className="mx-12">
                            <p className="my-10 text-sm text-red-600">
                                {error}
                            </p>
                        </div> :
                        <>
                            <div className="flex flex-1 sm:ml-6 mt-6 gap-y-4">
                                <Breadcrumbs pages={pages} />
                            </div>
                            <main className="flex-1">
                                <div className="py-6 mt-6">
                                    <div className="flex justify-between items-center">
                                        <div className="flex-col items-center px-4 sm:px-6 md:px-8">
                                            <div className="flex gap-x-6 items-center">
                                                <h1 className="text-lg leading-6 font-medium text-gray-700">
                                                    Información de consulta {!editableDate && consultation.attentionDate}
                                                </h1>
                                                {editableDate && <DateInput
                                                    errorMessage={errorDate}
                                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                    defaultValue={consultation.attentionDate}
                                                    value={inputDate}
                                                    handleChange={(field, value) => { setInputDate(value); console.log(value) }}
                                                    name="attentionDate"
                                                    icon={true}
                                                />}
                                                <button
                                                    className={`p-1 ${editableDate ? 'text-blue-200 bg-blue-700' : 'text-blue-700 bg-blue-200'} rounded`}
                                                    onClick={() => {
                                                        if (editableDate) {
                                                            console.log(inputDate)
                                                            if (!inputDate) {
                                                                setErrorDate('Introduzca una fecha válida')
                                                            } else if (inputDate > dateToday) {
                                                                setErrorDate('La fecha no puede ser futura')
                                                            } else {
                                                                setErrorDate('')
                                                                setConsultation({
                                                                    ...consultation,
                                                                    attentionDate: inputDate
                                                                })
                                                                setEditableDate(!editableDate)
                                                            }
                                                        } else {
                                                            setEditableDate(!editableDate)
                                                        }
                                                    }}
                                                >
                                                    {editableDate ?
                                                        <PencilAltIcon className="h-4 w-4" aria-hidden="true" /> :
                                                        <PencilIcon className="h-4 w-4" aria-hidden="true" />
                                                    }
                                                </button>
                                            </div>
                                            <span className="text-sm leading-5 font-normal text-gray-500">Lugar: {consultation.establishment}</span>
                                        </div>
                                        <div className="flex flex-col items-end mr-8 ml-4">
                                            <button
                                                onClick={() => handleSubmit()}
                                                type="submit"
                                                className="sm:ml-0 items-center px-4 py-2 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-blue-600 hover:bg-blue-700"
                                            >
                                                {loadingSubmit ?
                                                    <div className="w-5 h-5 mx-4 border-b-2 border-white rounded-full animate-spin"></div> :
                                                    'Guardar'
                                                }
                                            </button>
                                            {!!errorSubmit && <p style={{ maxWidth: 240 }} className="mt-2 text-sm text-red-600">
                                                {errorSubmit}
                                            </p>}
                                        </div>

                                    </div>
                                    <div className="mx-8 mt-4">
                                        <div className="sm:hidden">
                                            <label htmlFor="tabs" className="sr-only">
                                                Seleccione una página
                                            </label>
                                            {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                                            <select
                                                onChange={(event) => changeTab(event.target.value)}
                                                id="tabs"
                                                name="tabs"
                                                className="block w-full focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                                                defaultValue={tabs.find((tab) => tab.current).name}
                                            >
                                                {tabs.map((tab) => (
                                                    <option key={tab.name}>{tab.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="hidden sm:block">
                                            <nav className="flex flex-wrap gap-x-4 gap-y-2" aria-label="Tabs">
                                                {tabs.map((tab) => (
                                                    <button
                                                        key={tab.name}
                                                        className={classNames(
                                                            tab.current ? 'bg-blue-100 text-blue-700' : 'text-gray-500 hover:text-gray-700',
                                                            'px-3 py-2 font-medium text-sm rounded-md'
                                                        )}
                                                        onClick={() => changeTab(tab.name)}
                                                        aria-current={tab.current ? 'page' : undefined}
                                                    >
                                                        {tab.name}
                                                    </button>
                                                ))}
                                            </nav>
                                        </div>
                                        <div>
                                            <ConsultationDetail setConsultation={setConsultation} consultation={consultation} tab={tab.name} />
                                        </div>
                                    </div>
                                </div>
                            </main>
                        </>
                }
            </div>
        </div >
    );
}
