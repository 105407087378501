import React, { useEffect } from "react";
import { useState } from 'react'
import { Link } from "react-router-dom";
import {
    CalendarIcon,
    ChartBarIcon,
    OfficeBuildingIcon,
    UserCircleIcon,
    UserGroupIcon,
    PlusIcon,
    LocationMarkerIcon,
    BanIcon
} from '@heroicons/react/outline'
import api from "../../api";
import Sidebar from '../components/Sidebar';
import Header from "../components/Header";
import Table from "../components/Table";

const navigation = [
    { name: 'Pacientes', href: '/patients', icon: UserGroupIcon, current: false },
    { name: 'Reportes', href: '/reports', icon: ChartBarIcon, current: false },
    { name: 'Establecimientos', href: '/establishments', icon: OfficeBuildingIcon, current: true },
    { name: 'Usuarios', href: '/users', icon: UserCircleIcon, current: false },
    { name: 'Distritos', href: '/districts', icon: LocationMarkerIcon, current: false },
    // { name: 'Calendarios', href: '/calendars', icon: CalendarIcon, current: false },
]

const columns = [
    { name: 'name', displayText: 'Nombre', clickable: true },
    { name: 'district', displayText: 'Distrito' },
    { name: 'department', displayText: 'Departamento' },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function EstablishmentPage() {

    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [establishments, setEstablishments] = useState([])
    const [search, setSearch] = useState("")
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        api.establishments.getMulti().then((response) => {
            setEstablishments(response.results.map(({ name, id, department, district }) => {
                return ({
                    name: name, id: id, department: department, district: district
                })
            }));
            setLoading(false)
        }
        )
    }, [])

    const searchEstablishment = () => {
        setLoading(true)
        api.establishments.getMulti(null, search).then((response) => {
            setEstablishments(response.results.map(({ name, id, department, district }) => {
                return ({
                    name: name, id: id, department: department, district: district
                })
            }));
            setLoading(false)
        })
    }

    return (
        <div>
            <Sidebar currentScreen={'Establecimientos'} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="md:pl-64 flex flex-col h-screen flex-1">
                <Header value={search} setSearch={setSearch} search={searchEstablishment} setSidebarOpen={setSidebarOpen} searchPlaceholder="Buscar por nombre de establecimiento." buttonText={!establishments.length ? null : "Nuevo establecimiento"} href="/establishments/new" />
                <main className="flex-1 bg-gray-100">
                    {loading ?
                        <div className="flex h-full items-center justify-center ">
                            <div className="w-16 h-16 border-b-2 border-blue-900 rounded-full animate-spin"></div>
                        </div> :
                        !establishments.length ?
                            <div className="flex h-full">
                                <div className="mx-auto my-auto text-center">
                                    <BanIcon className="h-12 w-12 mx-auto text-gray-400" aria-hidden="true" />
                                    <h3 className="mt-2 text-sm font-semibold text-gray-900">No se encontraron resultados</h3>
                                    <p className="mt-1 text-sm font-semibold text-gray-500">Probá con otros datos o ingresá un nuevo establecimiento.</p>
                                    <div className="mt-6">
                                        <Link
                                            to="/establishments/new"
                                            type="button"
                                            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-blue-600 hover:bg-blue-700"
                                        >
                                            <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                            Nuevo establecimiento
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="py-6">
                                <div className="px-4 sm:px-6 md:px-8">
                                    <Table firstColumnBold={true} module="establishments" actiontext="Editar" rows={establishments} columns={columns} />
                                </div>
                            </div>
                    }
                </main>
            </div>
        </div >
    );
}
