import React, { useState, useEffect } from "react";
import {
    PencilAltIcon,
    PencilIcon,
    XIcon,
    CheckIcon
} from '@heroicons/react/outline'

const editableContainerStyle = 'flex items-center gap-x-3'
const inputContainerStyle = 'flex w-30 items-center py-0.5 px-2 rounded-lg border border-blue-700 bg-blue-100 text-gray-900'
const inputStyle = 'w-full text-sm bg-transparent outline-none border-none'
const errorStyle = 'text-xs w-30 text-red-600 mt-1'
const inputUnitStyle = 'text-sm text-gray-500'
const textStyle = 'text-sm leading-5 w-30 font-normal text-gray-900'

const EditButton = function ({ active, setActive }) {
    return <button
        className={`p-1 ${active ? 'text-blue-200 bg-blue-700' : 'text-blue-700 bg-blue-200'} rounded`}
        onClick={() => setActive()}
    >
        {active ?
            <PencilAltIcon className="h-4 w-4" aria-hidden="true" /> :
            <PencilIcon className="h-4 w-4" aria-hidden="true" />
        }
    </button>
}

const Determinations = function (props) {

    const setConsultation = props.setConsultation

    const consultation = props.consultation

    const [editWeight, setEditWeight] = useState(false)
    const [inputWeight, setInputWeight] = useState(consultation.weight)
    const [errorWeight, setErrorWeight] = useState('')
    const [editHeight, setEditHeight] = useState(false)
    const [inputHeight, setInputHeight] = useState(consultation.height)
    const [errorHeight, setErrorHeight] = useState('')
    const [currentImc, setCurrentImc] = useState(consultation.imc)
    const [editWaist, setEditWaist] = useState(false)
    const [inputWaist, setInputWaist] = useState(consultation.waist)
    const [editDia, setEditDia] = useState(false)
    const [inputDia, setInputDia] = useState(consultation.diastolic)
    const [errorDia, setErrorDia] = useState('')
    const [editSys, setEditSys] = useState(false)
    const [inputSys, setInputSys] = useState(consultation.systolic)
    const [errorSys, setErrorSys] = useState('')
    const [editGluc, setEditGluc] = useState(false)
    const [inputGluc, setInputGluc] = useState(consultation.bloodGlucose)
    const [editLastFood, setEditLastFood] = useState(false)
    const [inputLastFood, setInputLastFood] = useState(consultation.lastMeal)

    useEffect(() => {
        const imc = (inputWeight / ((inputHeight / 100) * (inputHeight / 100))).toFixed(2)
        setCurrentImc(+imc)
    }, [inputWeight, inputHeight])

    useEffect(() => {
        if (!editWeight) {
            setConsultation({
                ...consultation,
                weight: +inputWeight,
                imc: +currentImc
            })
        }

    }, [editWeight])

    useEffect(() => {
        if (!editHeight) {
            setConsultation({
                ...consultation,
                height: +inputHeight,
                imc: +currentImc
            })
        }

    }, [editHeight])

    useEffect(() => {
        if (!editWaist) {
            setConsultation({
                ...consultation,
                waist: +inputWaist
            })
        }

    }, [editWaist])

    useEffect(() => {
        if (!editDia) {
            setConsultation({
                ...consultation,
                diastolic: +inputDia
            })
        }

    }, [editDia])

    useEffect(() => {
        if (!editSys) {
            setConsultation({
                ...consultation,
                systolic: +inputSys
            })
        }
    }, [editSys])

    useEffect(() => {
        if (!editGluc) {
            setConsultation({
                ...consultation,
                bloodGlucose: +inputGluc
            })
        }

    }, [editGluc])

    useEffect(() => {
        if (!editLastFood) {
            setConsultation({
                ...consultation,
                lastMeal: +inputLastFood
            })
        }

    }, [editLastFood])

    return (
        <ul style={{ minWidth: 300 }} role="list" className="divide-y divide-gray-200">
            <li className="px-4 py-4 sm:px-0">
                <div className="flex flex-1 gap-x-6 justify-between items-center mt-2">
                    <p className="text-sm leading-5 w-40 font-medium text-gray-500">Peso</p>

                    <div className={editableContainerStyle}>
                        <EditButton active={editWeight} setActive={() => {
                            // validate weight
                            if (!editWeight) {
                                setEditWeight(!editWeight)
                            } else {
                                if (!inputWeight) { // is not completed
                                    setErrorWeight('Este campo es obligatorio')
                                } else if (+inputWeight > 700) { // is more than 700
                                    setErrorWeight('El peso no puede ser mayor a 700 kg')
                                } else { // is valid
                                    setEditWeight(!editWeight)
                                    setErrorWeight('')
                                }
                            }
                        }} />
                        {editWeight ? <div>
                            <div className={inputContainerStyle}>
                                <input
                                    onKeyPress={(event) => { if (!/[0-9\.]/.test(event.key)) { event.preventDefault(); } }}
                                    className={inputStyle}
                                    defaultValue={inputWeight}
                                    onChange={(event) => setInputWeight(event.target.value)}
                                />
                                <div className={inputUnitStyle}>
                                    kg
                                </div>
                            </div>
                            {!!errorWeight && <div className={errorStyle}>
                                {errorWeight}
                            </div>}
                        </div> : <p className={textStyle}>
                            {inputWeight ? inputWeight + ' kg' : '--'}
                        </p>}
                    </div>

                </div>
            </li>
            <li className="px-4 py-4 sm:px-0">
                <div className="flex flex-1 gap-x-6 justify-between items-center mt-2">
                    <p className="text-sm leading-5 w-40 font-medium text-gray-500">Talla</p>

                    <div className={editableContainerStyle}>
                        <EditButton active={editHeight} setActive={() => {
                            if (!editHeight) {
                                setEditHeight(!editHeight)
                            } else {
                                // validate height
                                if (!inputHeight) { // is not completed
                                    setErrorHeight('Este campo es obligatorio')
                                } else if (+inputHeight > 300) { // is more than 300
                                    setErrorHeight('La altura no puede ser mayor a 300 cm')
                                } else { // is valid
                                    setEditHeight(!editHeight)
                                    setErrorHeight('')
                                }
                            }
                        }} />
                        {editHeight ? <div>
                            <div className={inputContainerStyle}>
                                <input
                                    onKeyPress={(event) => { if (!/[0-9\.]/.test(event.key)) { event.preventDefault(); } }}
                                    className={inputStyle}
                                    defaultValue={inputHeight}
                                    onChange={(event) => setInputHeight(event.target.value)}
                                />
                                <div className={inputUnitStyle}>
                                    cm
                                </div>
                            </div>
                            {!!errorHeight && <div className={errorStyle}>
                                {errorHeight}
                            </div>}
                        </div> : <p className={textStyle}>
                            {inputHeight ? inputHeight + ' cm' : '--'}
                        </p>}
                    </div>

                </div>
            </li>
            <li className="px-4 py-4 sm:px-0">
                <div className="flex flex-1 gap-x-6 justify-between items-center mt-2">
                    <p className="text-sm leading-5 w-40 font-medium text-gray-500">IMC</p>
                    <p className={textStyle}>{currentImc ? currentImc : '--'}</p>
                </div>
            </li>
            <li className="px-4 py-4 sm:px-0">
                <div className="flex flex-1 gap-x-6 justify-between items-center mt-2">
                    <p className="text-sm leading-5 w-40 font-medium text-gray-500">Cintura</p>

                    <div className={editableContainerStyle}>
                        <EditButton active={editWaist} setActive={() => setEditWaist(!editWaist)} />
                        {editWaist ? <div className={inputContainerStyle}>
                            <input
                                onKeyPress={(event) => { if (!/[0-9\.]/.test(event.key)) { event.preventDefault(); } }}
                                className={inputStyle}
                                defaultValue={inputWaist}
                                onChange={(event) => setInputWaist(event.target.value)}
                            />
                            <div className={inputUnitStyle}>
                                cm
                            </div>
                        </div> : <p className={textStyle}>
                            {inputWaist ? inputWaist + ' cm' : '--'}
                        </p>}
                    </div>

                </div>
            </li>
            <li className="px-4 py-4 sm:px-0">
                <div className="flex flex-1 gap-x-6 justify-between items-center mt-2">
                    <p className="text-sm leading-5 w-40 font-medium text-gray-500">Diastólica</p>

                    <div className={editableContainerStyle}>
                        <EditButton active={editDia} setActive={() => {
                            if (!editDia) {
                                setEditDia(!editDia)
                            } else {
                                // validate diastolic
                                if (!inputDia) { // is not completed
                                    setErrorDia('Este campo es obligatorio')
                                } else if (+inputDia > 500) { // is more than 500
                                    setErrorDia('La diastólica no puede ser mayor a 500 mmHg')
                                } else { // is valid
                                    setEditDia(!editDia)
                                    setErrorDia('')
                                }
                            }
                        }} />
                        {editDia ? <div>
                            <div className={inputContainerStyle}>
                                <input
                                    onKeyPress={(event) => { if (!/[0-9\.]/.test(event.key)) { event.preventDefault(); } }}
                                    className={inputStyle}
                                    defaultValue={inputDia}
                                    onChange={(event) => setInputDia(event.target.value)}
                                />
                                <div className={inputUnitStyle}>
                                    mmHg
                                </div>
                            </div>
                            {!!errorDia && <div className={errorStyle}>
                                {errorDia}
                            </div>}
                        </div> : <p className={textStyle}>
                            {inputDia ? inputDia + ' mmHg' : '--'}
                        </p>}
                    </div>

                </div>
            </li>
            <li className="px-4 py-4 sm:px-0">
                <div className="flex flex-1 gap-x-6 justify-between items-center mt-2">
                    <p className="text-sm leading-5 w-40 font-medium text-gray-500">Sistólica</p>

                    <div className={editableContainerStyle}>
                        <EditButton active={editSys} setActive={() => {
                            if (!editSys) {
                                setEditSys(!editSys)
                            } else {
                                // validate systolic
                                if (!inputSys) { // is not completed
                                    setErrorSys('Este campo es obligatorio')
                                } else if (+inputSys > 500) { // is more than 500
                                    setErrorSys('La sistólica no puede ser mayor a 500 mmHg')
                                } else { // is valid
                                    setEditSys(!editSys)
                                    setErrorSys('')
                                }
                            }
                        }} />
                        {editSys ? <div>
                            <div className={inputContainerStyle}>
                                <input
                                    onKeyPress={(event) => { if (!/[0-9\.]/.test(event.key)) { event.preventDefault(); } }}
                                    className={inputStyle}
                                    defaultValue={inputSys}
                                    onChange={(event) => setInputSys(event.target.value)}
                                />
                                <div className={inputUnitStyle}>
                                    mmHg
                                </div>
                            </div>
                            {!!errorSys && <div className={errorStyle}>
                                {errorSys}
                            </div>}
                        </div> : <p className={textStyle}>
                            {inputSys ? inputSys + ' mmHg' : '--'}
                        </p>}
                    </div>

                </div>
            </li>
            <li className="px-4 py-4 sm:px-0">
                <div className="flex flex-1 gap-x-6 justify-between items-center mt-2">
                    <p className="text-sm leading-5 w-40 font-medium text-gray-500">Glucemia capilar</p>

                    <div className={editableContainerStyle}>
                        <EditButton active={editGluc} setActive={() => setEditGluc(!editGluc)} />
                        {editGluc ? <div className={inputContainerStyle}>
                            <input
                                onKeyPress={(event) => { if (!/[0-9\.]/.test(event.key)) { event.preventDefault(); } }}
                                className={inputStyle}
                                defaultValue={inputGluc}
                                onChange={(event) => setInputGluc(event.target.value)}
                            />
                            <div className={inputUnitStyle}>
                                mg/dL
                            </div>
                        </div> : <p className={textStyle}>
                            {inputGluc ? inputGluc + ' mg/dL' : '--'}
                        </p>}
                    </div>

                </div>
            </li>
            <li className="px-4 py-4 sm:px-0">
                <div className="flex flex-1 gap-x-6 justify-between items-center mt-2">
                    <p className="text-sm leading-5 w-40 font-medium text-gray-500">Última comida</p>

                    <div className={editableContainerStyle}>
                        <EditButton active={editLastFood} setActive={() => setEditLastFood(!editLastFood)} />
                        {editLastFood ? <div className={inputContainerStyle}>
                            <input
                                onKeyPress={(event) => { if (!/[0-9\.]/.test(event.key)) { event.preventDefault(); } }}
                                className={inputStyle}
                                defaultValue={inputLastFood}
                                onChange={(event) => setInputLastFood(event.target.value)}
                            />
                            <div className={inputUnitStyle}>
                                horas
                            </div>
                        </div> : <p className={textStyle}>
                            {inputLastFood ? inputLastFood + ' horas' : '--'}
                        </p>}
                    </div>

                </div>
            </li>
            <li className="px-4 py-4 sm:px-0">
                <div className="flex flex-1 gap-x-6 justify-between items-center mt-2">
                    <p className="text-sm leading-5 w-40 font-medium text-gray-500">Polifagia</p>
                    <div className={editableContainerStyle}>
                        <button
                            className={`p-1 ${consultation.polifagia ? 'text-blue-200 bg-blue-700' : 'text-blue-700 bg-blue-200'} rounded`}
                            onClick={() => setConsultation({
                                ...consultation,
                                polifagia: !consultation.polifagia
                            })}
                        >
                            {consultation.polifagia ?
                                <CheckIcon className="h-4 w-4" aria-hidden="true" /> :
                                <XIcon className="h-4 w-4" aria-hidden="true" />
                            }
                        </button>
                        <p className={textStyle}>{consultation.polifagia ? 'Si' : 'No'}</p>
                    </div>
                </div>
            </li>
        </ul>
    )
};

const AnualCheck = function (props) {

    return (
        <ul style={{ minWidth: 480 }} role="list" className="divide-y divide-gray-200">
            <li className="px-4 py-4 sm:px-0">
                <div className="flex flex-1 gap-x-6 justify-between items-center mt-2">
                    <p className="text-sm leading-5 w-40 font-medium invisible text-gray-500">Esquina</p>
                    <p className="text-sm leading-5 w-40 font-normal invisible text-gray-500">Resultado</p>
                    <p className="text-sm leading-5 w-40 font-normal text-gray-500">Obs.</p>
                    <p className="text-sm leading-5 w-40 font-normal text-gray-500">Derivar</p>
                    <p className="text-sm leading-5 w-40 font-normal text-gray-500">Derivar al Lab</p>
                </div>
            </li>
            <li className="px-4 py-4 sm:px-0">
                <div className="flex flex-1 gap-x-6 justify-between items-center mt-2">
                    <p className="text-sm leading-5 w-40 font-medium text-gray-500">Pie diabético</p>
                    <p className="text-sm leading-5 w-40 font-normal text-gray-500">{props.consultation.diabeticFoot ? 'Si' : 'No'}</p>
                    <p className="text-sm leading-5 w-40 font-normal text-gray-900">{props.consultation.diabeticFootObs ? props.consultation.diabeticFootObs : '--'} </p>
                    <p className="text-sm leading-5 w-40 font-normal text-gray-900">{props.consultation.diabeticFootDerive ? 'Si' : '--'} </p>
                    <p className="text-sm leading-5 w-40 font-normal text-gray-900">{props.consultation.diabeticFootReferToLab ? 'Si' : '--'}</p>
                </div>
            </li>
            <li className="px-4 py-4 sm:px-0">
                <div className="flex flex-1 gap-x-6 justify-between items-center mt-2">
                    <p className="text-sm leading-5 w-40 font-medium text-gray-500">Fondo de ojo</p>
                    <p className="text-sm leading-5 w-40 font-normal text-gray-500">{props.consultation.fundus ? 'Si' : 'No'}</p>
                    <p className="text-sm leading-5 w-40 font-normal text-gray-900">{props.consultation.fundusObs ? props.consultation.fundusObs : '--'}</p>
                    <p className="text-sm leading-5 w-40 font-normal text-gray-900">{props.consultation.fundusDerive ? 'Si' : '--'}</p>
                    <p className="text-sm leading-5 w-40 font-normal text-gray-900">--</p>
                </div>
            </li>
            <li className="px-4 py-4 sm:px-0">
                <div className="flex flex-1 gap-x-6 justify-between items-center mt-2">
                    <p className="text-sm leading-5 w-40 font-medium text-gray-500">Análisis de orina 24hs</p>
                    <p className="text-sm leading-5 w-40 font-normal text-gray-500">{props.consultation.urineAnalysis ? 'Si' : 'No'}</p>
                    <p className="text-sm leading-5 w-40 font-normal text-gray-900">{props.consultation.urineAnalysisObs ? props.consultation.urineAnalysisObs : '--'}</p>
                    <p className="text-sm leading-5 w-40 font-normal text-gray-900">{props.consultation.urineAnalysisReferToLab ? 'Si' : '--'}</p>
                    <p className="text-sm leading-5 w-40 font-normal text-gray-900">--</p>
                </div>
            </li>
        </ul>
    )
};

const Medications = function (props) {

    const dmMedicine = [
        'Metformina 850 mg',
        'Glimepirida 2 mg',
        'Glimepirida 4 mg',
        'Sitagliptina 100 mg',
        'Insulina NPH',
        'Insulina Cristalina',
        'Insulina Glargina 100 mg',
        'Insulina Glargina 300 mg',
        'Atorvastatina 40 mg',
        'Gemfibrozilo 600 mg'
    ]

    const htaMedicine = [
        'AAS 125 mg',
        'Atenolol 50 mg',
        'Valsartan 50 mg',
        'Losartan 50 mg',
        'Losartan 100 mg',
        'Enalapril 5 mg',
        'Enalapril 10 mg',
        'Enalapril 20 mg',
        'Losartan 160 mg',
        'Amlodipina 10 mg',
        'Hidroclorotiazida 25 mg',
        'Valsartan 160 mg',
        'Anlodipino 5 mg',
        'Anlodipino 10 mg',
        'Atorvastatina 40 mg',
        'Carvedidol 6,25 mg',
        'Carvedidol 25 mg',
        'Furosemida 40 mg',
        'Espironolactona 25 mg'
    ]

    return (
        <ul style={{ minWidth: 480 }} role="list" className="divide-y divide-gray-200">
            <li className="px-4 py-4 sm:px-0">
                <div className="flex flex-1 flex-wrap gap-x-4 justify-between items-center mt-2">
                    <p className="text-sm leading-5 w-40 font-medium text-gray-500">Medicación para DM</p>
                    {props.consultation.otherDmMedication ?
                        <p>{props.consultation.otherDmMedication.replace(';', '\n')}</p> : props.consultation.dmMedications.map((element) => {
                            return (
                                <>
                                    <p className="text-sm leading-5 w-40 font-normal text-gray-900">{element.medicine != null ? dmMedicine[element.medicine] : element.otherMedicine}</p>
                                    <p className="text-sm leading-5 w-40 font-normal text-gray-900">{element.indications}</p>
                                </>
                            )
                        })}
                </div>
            </li>
            <li className="px-4 py-4 sm:px-0">
                <div className="flex flex-1 flex-wrap gap-x-4 justify-between items-center mt-2">
                    <p className="text-sm leading-5 w-40 font-medium text-gray-500">Medicación para HTA</p>
                    {props.consultation.otherHtaMedication ?
                        <p className="text-sm leading-5 w-40 font-normal text-gray-900">{props.consultation.otherHtaMedication.replace(';', '\n')}</p> : props.consultation.htaMedications.map((element) => {
                            return (
                                <>
                                    <p className="text-sm leading-5 w-40 font-normal text-gray-900">{element.medicine != null ? htaMedicine[element.medicine] : element.otherMedicine}</p>
                                    <p className="text-sm leading-5 w-40 font-normal text-gray-900">{element.indications}</p>
                                </>
                            )
                        })}
                </div>
            </li>
        </ul>
    )
}

const Laboratory = function (props) {

    const setConsultation = props.setConsultation

    const consultation = props.consultation

    const [editBasalGluc, setEditBasalGluc] = useState(false)
    const [inputBasalGluc, setInputBasalGluc] = useState(consultation.basalGlucose)
    const [editCholesterol, setEditCholesterol] = useState(false)
    const [inputCholesterol, setInputCholesterol] = useState(consultation.totalCholesterol)
    const [editHba1c, setEditHba1c] = useState(false)
    const [inputHba1c, setInputHba1c] = useState(consultation.hba1c)
    const [editLdlCol, setEditLdlCol] = useState(false)
    const [inputLdlCol, setInputLdlCol] = useState(consultation.ldlCol)
    const [editHdlCol, setEditHdlCol] = useState(false)
    const [inputHdlCol, setInputHdlCol] = useState(consultation.hdlCol)
    const [editTriglycerides, setEditTriglycerides] = useState(false)
    const [inputTriglycerides, setInputTriglycerides] = useState(consultation.triglycerides)
    const [editCreatinine, setEditCreatinine] = useState(false)
    const [inputCreatinine, setInputCreatinine] = useState(consultation.creatinine)

    useEffect(() => {
        if (!editBasalGluc) {
            setConsultation({
                ...consultation,
                basalGlucose: +inputBasalGluc,
            })
        }

    }, [editBasalGluc])

    useEffect(() => {
        if (!editCholesterol) {
            setConsultation({
                ...consultation,
                totalCholesterol: +inputCholesterol,
            })
        }

    }, [editCholesterol])

    useEffect(() => {
        if (!editHba1c) {
            setConsultation({
                ...consultation,
                hba1c: +inputHba1c,
            })
        }

    }, [editHba1c])

    useEffect(() => {
        if (!editLdlCol) {
            setConsultation({
                ...consultation,
                ldlCol: +inputLdlCol,
            })
        }

    }, [editLdlCol])

    useEffect(() => {
        if (!editHdlCol) {
            setConsultation({
                ...consultation,
                hdlCol: +inputHdlCol,
            })
        }

    }, [editHdlCol])

    useEffect(() => {
        if (!editTriglycerides) {
            setConsultation({
                ...consultation,
                triglycerides: +inputTriglycerides,
            })
        }

    }, [editTriglycerides])

    useEffect(() => {
        if (!editCreatinine) {
            setConsultation({
                ...consultation,
                creatinine: +inputCreatinine,
            })
        }

    }, [editCreatinine])

    return (
        <ul style={{ minWidth: 300 }} role="list" className="divide-y divide-gray-200">
            <li className="px-4 py-4 sm:px-0">
                <div className="flex flex-1 gap-x-6 justify-between items-center mt-2">
                    <p className="text-sm leading-5 w-40 font-medium text-gray-500">Glucosa basal</p>

                    <div className={editableContainerStyle}>
                        <EditButton active={editBasalGluc} setActive={() => setEditBasalGluc(!editBasalGluc)} />
                        {editBasalGluc ? <div className={inputContainerStyle}>
                            <input
                                onKeyPress={(event) => { if (!/[0-9\.]/.test(event.key)) { event.preventDefault(); } }}
                                className={inputStyle}
                                defaultValue={inputBasalGluc}
                                onChange={(event) => setInputBasalGluc(event.target.value)}
                            />
                            <div className={inputUnitStyle}>
                                mg/dL
                            </div>
                        </div> : <p className={textStyle}>
                            {inputBasalGluc ? inputBasalGluc + ' mg/dL' : '--'}
                        </p>}
                    </div>

                </div>
            </li>
            <li className="px-4 py-4 sm:px-0">
                <div className="flex flex-1 gap-x-6 justify-between items-center mt-2">
                    <p className="text-sm leading-5 w-40 font-medium text-gray-500">Colesterol Total</p>

                    <div className={editableContainerStyle}>
                        <EditButton active={editCholesterol} setActive={() => setEditCholesterol(!editCholesterol)} />
                        {editCholesterol ? <div className={inputContainerStyle}>
                            <input
                                onKeyPress={(event) => { if (!/[0-9\.]/.test(event.key)) { event.preventDefault(); } }}
                                className={inputStyle}
                                defaultValue={inputCholesterol}
                                onChange={(event) => setInputCholesterol(event.target.value)}
                            />
                            <div className={inputUnitStyle}>
                                mg/dL
                            </div>
                        </div> : <p className={textStyle}>
                            {inputCholesterol ? inputCholesterol + ' mg/dL' : '--'}
                        </p>}
                    </div>

                </div>
            </li>
            <li className="px-4 py-4 sm:px-0">
                <div className="flex flex-1 gap-x-6 justify-between items-center mt-2">
                    <p className="text-sm leading-5 w-40 font-medium text-gray-500">HbA1C</p>

                    <div className={editableContainerStyle}>
                        <EditButton active={editHba1c} setActive={() => setEditHba1c(!editHba1c)} />
                        {editHba1c ? <div className={inputContainerStyle}>
                            <input
                                onKeyPress={(event) => { if (!/[0-9\.]/.test(event.key)) { event.preventDefault(); } }}
                                className={inputStyle}
                                defaultValue={inputHba1c}
                                onChange={(event) => setInputHba1c(event.target.value)}
                            />
                            <div className={inputUnitStyle}>
                                %
                            </div>
                        </div> : <p className={textStyle}>
                            {inputHba1c ? inputHba1c + ' %' : '--'}
                        </p>}
                    </div>

                </div>
            </li>
            <li className="px-4 py-4 sm:px-0">
                <div className="flex flex-1 gap-x-6 justify-between items-center mt-2">
                    <p className="text-sm leading-5 w-40 font-medium text-gray-500">LDL col</p>

                    <div className={editableContainerStyle}>
                        <EditButton active={editLdlCol} setActive={() => setEditLdlCol(!editLdlCol)} />
                        {editLdlCol ? <div className={inputContainerStyle}>
                            <input
                                onKeyPress={(event) => { if (!/[0-9\.]/.test(event.key)) { event.preventDefault(); } }}
                                className={inputStyle}
                                defaultValue={inputLdlCol}
                                onChange={(event) => setInputLdlCol(event.target.value)}
                            />
                            <div className={inputUnitStyle}>
                                mg/dL
                            </div>
                        </div> : <p className={textStyle}>
                            {inputLdlCol ? inputLdlCol + ' mg/dL' : '--'}
                        </p>}
                    </div>

                </div>
            </li>
            <li className="px-4 py-4 sm:px-0">
                <div className="flex flex-1 gap-x-6 justify-between items-center mt-2">
                    <p className="text-sm leading-5 w-40 font-medium text-gray-500">HDL col</p>

                    <div className={editableContainerStyle}>
                        <EditButton active={editHdlCol} setActive={() => setEditHdlCol(!editHdlCol)} />
                        {editHdlCol ? <div className={inputContainerStyle}>
                            <input
                                onKeyPress={(event) => { if (!/[0-9\.]/.test(event.key)) { event.preventDefault(); } }}
                                className={inputStyle}
                                defaultValue={inputHdlCol}
                                onChange={(event) => setInputHdlCol(event.target.value)}
                            />
                            <div className={inputUnitStyle}>
                                mg/dL
                            </div>
                        </div> : <p className={textStyle}>
                            {inputHdlCol ? inputHdlCol + ' mg/dL' : '--'}
                        </p>}
                    </div>

                </div>
            </li>
            <li className="px-4 py-4 sm:px-0">
                <div className="flex flex-1 gap-x-6 justify-between items-center mt-2">
                    <p className="text-sm leading-5 w-40 font-medium text-gray-500">Triglicéridos</p>

                    <div className={editableContainerStyle}>
                        <EditButton active={editTriglycerides} setActive={() => setEditTriglycerides(!editTriglycerides)} />
                        {editTriglycerides ? <div className={inputContainerStyle}>
                            <input
                                onKeyPress={(event) => { if (!/[0-9\.]/.test(event.key)) { event.preventDefault(); } }}
                                className={inputStyle}
                                defaultValue={inputTriglycerides}
                                onChange={(event) => setInputTriglycerides(event.target.value)}
                            />
                            <div className={inputUnitStyle}>
                                mg/dL
                            </div>
                        </div> : <p className={textStyle}>
                            {inputTriglycerides ? inputTriglycerides + ' mg/dL' : '--'}
                        </p>}
                    </div>

                </div>
            </li>
            <li className="px-4 py-4 sm:px-0">
                <div className="flex flex-1 gap-x-6 justify-between items-center mt-2">
                    <p className="text-sm leading-5 w-40 font-medium text-gray-500">Creatinina</p>

                    <div className={editableContainerStyle}>
                        <EditButton active={editCreatinine} setActive={() => setEditCreatinine(!editCreatinine)} />
                        {editCreatinine ? <div className={inputContainerStyle}>
                            <input
                                onKeyPress={(event) => { if (!/[0-9\.]/.test(event.key)) { event.preventDefault(); } }}
                                className={inputStyle}
                                defaultValue={inputCreatinine}
                                onChange={(event) => setInputCreatinine(event.target.value)}
                            />
                            <div className={inputUnitStyle}>
                                mg/dL
                            </div>
                        </div> : <p className={textStyle}>
                            {inputCreatinine ? inputCreatinine + ' mg/dL' : '--'}
                        </p>}
                    </div>

                </div>
            </li>
        </ul>
    )
}

const Habits = function (props) {

    return (
        <ul style={{ minWidth: 480 }} role="list" className="divide-y divide-gray-200">
            <li className="px-4 py-4 sm:px-0">
                <div className="flex flex-1 gap-x-6 items-center mt-2">
                    <p className="flex-1 text-sm leading-5 w-40 font-medium text-gray-500">Agrega sal a la comida</p>
                    <p className="text-sm leading-5 w-32 font-normal text-gray-900">{props.consultation.addSaltToFood ? "Sí" : "No"}</p>
                </div>
            </li>
            <li className="px-4 py-4 sm:px-0">
                <div className="flex flex-1 gap-x-6 items-center mt-2">
                    <p className="flex-1 text-sm leading-5 w-40 font-medium text-gray-500">Agrega sal al cocinar</p>
                    <p className="text-sm leading-5 w-32 font-normal text-gray-900">{props.consultation.addSaltWhenCooking ? "Sí" : "No"}</p>
                </div>
            </li>
            <li className="px-4 py-4 sm:px-0">
                <div className="flex flex-1 gap-x-6 items-center mt-2">
                    <p className="flex-1 text-sm leading-5 w-40 font-medium text-gray-500">Consumo de frutas</p>
                    <p className="text-sm leading-5 w-32 font-normal text-gray-900">{props.consultation.fruitServingsPerDay ? props.consultation.fruitServingsPerDay + ' porciones al día' : "No"}</p>
                </div>
            </li>
            <li className="px-4 py-4 sm:px-0">
                <div className="flex flex-1 gap-x-6 items-center mt-2">
                    <p className="flex-1 text-sm leading-5 w-40 font-medium text-gray-500">Consumo de hortalizas</p>
                    <p className="text-sm leading-5 w-32 font-normal text-gray-900">{props.consultation.vegetableServingsPerDay ? props.consultation.vegetableServingsPerDay + ' porciones al día' : "No"}</p>
                </div>
            </li>
            <li className="px-4 py-4 sm:px-0">
                <div className="flex flex-1 gap-x-6 items-center mt-2">
                    <p className="flex-1 text-sm leading-5 w-40 font-medium text-gray-500">Actividad física</p>
                    <p className="flex-1"></p>
                    <p className="flex-1 text-sm leading-5 w-32 font-normal text-gray-900">{props.consultation.performPhysicalActivity ? 'Si' : 'No'}</p>
                    <p className="text-sm leading-5 w-32 font-normal text-gray-900">{props.consultation.physicalActivityType ? props.consultation.physicalActivityType : '--'}</p>
                </div>
                <div className="flex flex-1 gap-x-6 items-center mt-4">
                    <p className="flex-1 text-sm leading-5 w-40 font-medium invisible text-gray-500">Actividad física</p>
                    <p className="flex-1"></p>
                    <p className="flex-1 text-sm leading-5 w-32 font-normal text-gray-900">{props.consultation.physicalActivityDuration ? props.consultation.physicalActivityDuration + ' minutos' : '--'}</p>
                    <p className="text-sm leading-5 w-32 font-normal text-gray-900">{props.consultation.physicalActivityFrequency ? props.consultation.physicalActivityFrequency + ' veces a la semana' : '--'}</p>
                </div>
            </li>
            <li className="px-4 py-4 sm:px-0">
                <div className="flex flex-1 gap-x-6 items-center mt-2">
                    <p className="flex-1 text-sm leading-5 w-40 font-medium text-gray-500">Tabaquismo</p>
                    <p className="flex-1 text-sm leading-5 w-32 font-normal text-gray-900">{props.consultation.smoke ? 'Si' : 'No'}</p>
                    <p className="flex-1 text-sm leading-5 w-32 font-normal text-gray-900">{props.consultation.vape ? 'Vapea' : '--'}</p>
                    <p className="text-sm leading-5 w-32 font-normal text-gray-900">{props.consultation.wantToQuitSmoking ? 'Quiere dejar de fumar' : '--'}</p>
                </div>
            </li>
            <li className="px-4 py-4 sm:px-0">
                <div className="flex flex-1 gap-x-6 items-center mt-2">
                    <p className="flex-1 text-sm leading-5 w-40 font-medium text-gray-500">Hábito de fumador</p>
                    <p className="flex-1"></p>
                    <p className="flex-1 text-sm leading-5 w-32 font-normal text-gray-900">{props.consultation.cigarettesPerDay ? props.consultation.cigarettesPerDay + ' cigarrillos al día' : '--'}</p>
                    <p className="text-sm leading-5 w-32 font-normal text-gray-900">{props.consultation.cigarettesPerDay ? props.consultation.timeOfTheFirstCigarette + ':00 hs' : '--'}</p>
                </div>
            </li>
            <li className="px-4 py-4 sm:px-0">
                <div className="flex flex-1 gap-x-6 items-center mt-2">
                    <p className="flex-1 text-sm leading-5 w-40 font-medium text-gray-500">Consume alcohol</p>
                    <p className="flex-1 text-sm leading-5 w-32 font-normal text-gray-900">{props.consultation.drinkAlcoholicBeverages ? 'Si' : 'No'}</p>
                    <p className="flex-1 text-sm leading-5 w-32 font-normal text-gray-900">{props.consultation.drinksPerDay ? props.consultation.drinksPerDay + ' ls al dia' : '--'}</p>
                    <p className="text-sm leading-5 w-32 font-normal text-gray-900">{props.consultation.drinksPerDay ? props.consultation.drinksPerWeek + ' porciones a la semana' : '--'}</p>
                </div>
            </li>
        </ul>
    )
}

const Education = function (props) {

    return (
        <div className="flex">
            <ul role="list">
                <li className="py-4 sm:px-0">
                    <div className="flex flex-col w-4/5 mt-2">
                        <p className="text-sm leading-5 font-medium text-gray-500">¿Recibió educación acerca de factores de riesgo en la alimentación?</p>
                        <p className="text-sm leading-5 font-normal text-gray-900">{props.consultation.nutritionEducation ? 'Si' : 'No'}</p>
                    </div>
                </li>
                <li className="py-4 sm:px-0">
                    <div className="flex flex-col w-4/5 mt-2">
                        <p className="text-sm leading-5 font-medium text-gray-500">¿Recibió educación acerca de factores de riesgo en el tabaquismo?</p>
                        <p className="text-sm leading-5 font-normal text-gray-900">{props.consultation.smokingEducation ? 'Si' : 'No'}</p>
                    </div>
                </li>
                <li className="py-4 sm:px-0">
                    <div className="flex flex-col w-4/5 mt-2">
                        <p className="text-sm leading-5 font-medium text-gray-500">¿Recibió educación acerca de factores de riesgo en la importancia
                            de la medicación HTA?</p>
                        <p className="text-sm leading-5 font-normal text-gray-900">{props.consultation.importanceHtaMedication ? 'Si' : 'No'}</p>
                    </div>
                </li>
            </ul>
            <ul role="list">
                <li className="py-4 sm:px-0">
                    <div className="flex flex-col w-5/6 mt-2">
                        <p className="text-sm leading-5 font-medium text-gray-500">¿Recibió información acerca de factores de riesgo en la actividad física?</p>
                        <p className="text-sm leading-5 font-normal text-gray-900">{props.consultation.physicalActivityEducation ? 'Si' : 'No'}</p>
                    </div>
                </li>
                <li className="py-4 sm:px-0">
                    <div className="flex flex-col w-5/6 mt-2">
                        <p className="text-sm leading-5 font-medium text-gray-500">¿Recibió educación acerca de factores de riesgo en el alcohol?</p>
                        <p className="text-sm leading-5 font-normal text-gray-900">{props.consultation.alcoholicBeveragesEducation ? 'Si' : 'No'}</p>
                    </div>
                </li>
                <li className="py-4 sm:px-0">
                    <div className="flex flex-col w-5/6 mt-2">
                        <p className="text-sm leading-5 font-medium text-gray-500">¿Recibió educación acerca de factores de riesgo en la importancia
                            de la medicación DM?</p>
                        <p className="text-sm leading-5 font-normal text-gray-900">{props.consultation.importanceDmMedication ? 'Si' : 'No'}</p>
                    </div>
                </li>
            </ul>
        </div>
    )
}


export default function ConsultationDetail(props) {

    return (
        <div style={{ overflowX: 'auto' }}>
            {props.tab === 'Determinaciones' && <Determinations
                setConsultation={props.setConsultation}
                consultation={props.consultation}
            />
            }
            {props.tab === 'Control anual' && <AnualCheck
                consultation={props.consultation}
            />}
            {props.tab === 'Medicamentos' && <Medications
                consultation={props.consultation}
            />}
            {props.tab === 'Laboratorio' && <Laboratory
                setConsultation={props.setConsultation}
                consultation={props.consultation}
            />}
            {props.tab === 'Hábitos' && <Habits
                consultation={props.consultation}
            />}
            {props.tab === 'Educación' && <Education
                consultation={props.consultation}
            />}

        </div>
    )
}