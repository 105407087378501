import React, { useEffect, useState } from "react";
import { ClipboardListIcon } from '@heroicons/react/outline'
import api from "../../api";
import {
    XYPlot,
    XAxis,
    YAxis,
    HorizontalGridLines,
    HorizontalBarSeries,
    VerticalBarSeries,
    VerticalGridLines,
    LineSeries,
    RadialChart,
    Hint,
    Crosshair
} from 'react-vis';
import {
    useWindowWidth,
    useWindowHeight
} from '@react-hook/window-size'
import Table from "../components/Table";

const colors = ['#87D9C9', '#FFA750', '#EF5369', '#FF8469', '#0063F6', '#3500CB', '#7A9FFF', '#5887FF', '#FF6C4B', '#6FD0BD', '#EA943F']
const hintStyle = 'px-4 py-3 text-xs text-white bg-gray-700 rounded-md shadow-md space-y-1 whitespace-nowrap'

// graphic elements

const legendLabelStyle = 'text-gray-500 text-sm font-medium'
const legendLabelContainerStyle = 'flex items-center'

const getBarWidth = function (data, screenWidth) {
    const usfsAmount = data.length
    if (usfsAmount === 1 && screenWidth > 1200) {
        return 0.15
    } else if (usfsAmount === 1 && screenWidth > 400) {
        return 0.3
    } else if (usfsAmount === 1 && screenWidth < 400) {
        return 0.5
    } else if (usfsAmount < 4 && screenWidth > 1200) {
        return 0.3
    } else if (usfsAmount < 4 && screenWidth > 500) {
        return 0.5
    } else if (usfsAmount < 7 && screenWidth > 1200) {
        return 0.5
    } else {
        return 0.8
    }
}

const Square = function ({ color }) {
    return <div style={{ height: 12, width: 12, borderRadius: 4, backgroundColor: color, marginRight: 9 }} />
}

const IndicatorContainer = function ({ columns, children, outline }) {

    const fourColumns = columns > 3

    return <dl
        style={{ maxWidth: fourColumns ? 1000 : 750 + (!outline ? (fourColumns ? 3 : 2) : 0) }}
        className={`grid ${fourColumns ? 'xs:grid-cols-2 lg:grid-cols-4' : 'xs:grid-cols-3'} rounded-lg grid-cols-1 ${!outline ? 'gap-x-0 gap-y-px xs:gap-x-px xs:gap-y-0' : ''}`}
    >
        {children}
    </dl>
}

const Indicator = function ({ color, outline, title, data, position }) {
    const indicatorTitleStyle = `font-semibold ${outline ? 'text-gray-500' : 'text-white'}`
    const indicatorDataStyle = `mt-1 text-2xl font-semibold ${outline ? 'text-gray-500' : 'text-white'}`

    let borderRadius = {}
    let containerClassName = "px-4 py-5"
    if (position === 'left') {
        containerClassName = `${containerClassName} ${'rounded-t-lg xs:rounded-l-lg xs:rounded-tr-none'}`
    } else if (position === 'right') {
        containerClassName = `${containerClassName} ${'rounded-b-lg xs:rounded-r-lg xs:rounded-bl-none'}`
    } else if (position === 'unique') {
        containerClassName = `${containerClassName} ${'rounded-lg'}`
    }

    // used only on 4 or more columns mode
    if (position === 'first') {
        containerClassName = `${containerClassName} ${'rounded-t-lg xs:rounded-tr-none lg:rounded-bl-lg'}`
    } else if (position === 'second') {
        containerClassName = `${containerClassName} ${'xs:rounded-tr-lg lg:rounded-tr-none'}`
    } else if (position === 'third') {
        containerClassName = `${containerClassName} ${'xs:rounded-bl-lg lg:rounded-bl-none'}`
    } else if (position === 'four') {
        containerClassName = `${containerClassName} ${'rounded-b-lg xs:rounded-bl-none lg:rounded-tr-lg'}`
    }

    const containerStyle = outline ? {
        borderColor: color,
        borderWidth: 2,
        backgroundColor: '#ffffff',
        ...borderRadius
    } : {
        backgroundColor: color,
        ...borderRadius
    }

    return <div style={containerStyle} className={containerClassName}>
        <dt className={indicatorTitleStyle}>{title}</dt>
        <dd className={indicatorDataStyle}>{data}</dd>
    </div>
}

const SexGridContainer = function ({ columns, children }) {
    // columns is 1, 2, 3 or 4
    const containerClassName = `grid grid-cols-1 rounded-lg bg-white border border-gray-200 p-1.5  ${columns > 1 ? 'lg:grid-cols-2' : ''} ${columns > 2 ? (columns === 3 ? 'xl:grid-cols-3' : '2xl:grid-cols-4') : ''}`

    return <dl style={{ maxWidth: columns * 700 }} className={containerClassName}>
        {children}
    </dl>
}

const SexGridObject = function ({ children }) {
    return <div className="flex px-6 py-3 justify-between items-center flex-wrap">
        {children}
    </div>
}

const SexLegend = function ({ title }) {

    return <div className="flex flex-col flex-1 mr-2.5">
        <div className="text-gray-600 text-base font-normal h-19">
            {title}
        </div>
        <div className="flex flex-col gap-y-2">
            <div className={legendLabelContainerStyle}>
                <Square color={colors[6]} />
                <div className={legendLabelStyle}>Hombres</div>
            </div>
            <div className={legendLabelContainerStyle}>
                <Square color={colors[3]} />
                <div className={legendLabelStyle}>Mujeres</div>
            </div>
        </div>
    </div>
}

// generic sex radial chart
const SexChart = function ({ maleData, femaleData, pluralName, horizontalAlign }) {

    const hAlign = horizontalAlign ? horizontalAlign : 'auto'

    // hovered
    const [sexRadiantHover, setSexRadiantHover] = useState(null)

    return <RadialChart
        colorType="literal"
        showLabels={true}
        labelsRadiusMultiplier={0.8}
        labelsStyle={{
            fontSize: 12,
            fontWeight: 500
        }}
        data={[
            {
                angle: maleData,
                label: maleData,
                subLabel: !isNaN(100 * maleData / (maleData + femaleData)) && `${+(100 * maleData / (maleData + femaleData)).toFixed(1)}%`,
                title: 'Hombres',
                quantity: maleData,
                color: sexRadiantHover?.title === 'Hombres' ? colors[7] : colors[6]
            },
            {
                angle: femaleData,
                label: femaleData,
                subLabel: !isNaN(100 * femaleData / (maleData + femaleData)) && `${+(100 * femaleData / (maleData + femaleData)).toFixed(1)}%`,
                title: 'Mujeres',
                quantity: femaleData,
                color: sexRadiantHover?.title === 'Mujeres' ? colors[8] : colors[3]
            }
        ]}
        onValueMouseOver={(value) => setSexRadiantHover(value)}
        onValueMouseOut={() => setSexRadiantHover(null)}
        width={165}
        height={165}
    >
        {sexRadiantHover && <Hint value={sexRadiantHover} align={{ horizontal: hAlign, vertical: 'auto' }}>
            <div className={hintStyle}>
                <div>{sexRadiantHover.title}: {sexRadiantHover.quantity} {pluralName ? pluralName : 'pacientes'} {!!sexRadiantHover.subLabel && `(${sexRadiantHover.subLabel})`}</div>
            </div>
        </Hint>}
    </RadialChart>
}

// recomended using up to 4 items
const GenericLegend = function ({ title, items }) {

    const colorsIndex = [6, 3, 0, 1]

    return <div className="flex flex-col flex-1 mr-2.5">
        <div className="text-gray-600 text-base font-normal h-19">
            {title}
        </div>
        <div className="flex flex-col gap-y-2">
            {!!items && items.map((item, indx) => <div className={legendLabelContainerStyle}>
                <Square color={colors[colorsIndex[indx % 4]]} />
                <div className={legendLabelStyle}>{item}</div>
            </div>)}
        </div>
    </div>
}

// recomended using up to 4 items
const GenericRadialChart = function ({ itemsData, pluralName, horizontalAlign }) { // itemsData: { title: string , value: number }

    const hAlign = horizontalAlign ? horizontalAlign : 'auto'

    // hovered
    const [radialHover, setRadialHover] = useState(null)

    const colorsIndex = [6, 3, 0, 1]
    const hoveredColorsIndex = [7, 8, 9, 10]

    let aux = 0
    itemsData.forEach(id => {
        aux += id.value
    })

    const totalItemsData = aux

    const data = itemsData.map((itemData, indx) => {
        return {
            angle: itemData.value,
            label: itemData.value,
            subLabel: !isNaN(((100 * itemData.value) / totalItemsData).toFixed(1)) && `${+((100 * itemData.value) / totalItemsData).toFixed(1)}%`,
            title: itemData.title,
            quantity: itemData.value,
            color: radialHover?.title === itemData.title ? colors[hoveredColorsIndex[indx % 4]] : colors[colorsIndex[indx % 4]]
        }
    })

    return <RadialChart
        colorType="literal"
        showLabels={true}
        labelsRadiusMultiplier={0.8}
        labelsStyle={{
            fontSize: 12,
            fontWeight: 500
        }}
        data={data}
        onValueMouseOver={(value) => setRadialHover(value)}
        onValueMouseOut={() => setRadialHover(null)}
        width={165}
        height={165}
    >
        {radialHover && <Hint value={radialHover} align={{ horizontal: hAlign, vertical: 'auto' }} >
            <div className={hintStyle}>
                <div>{radialHover.title}: {radialHover.quantity} {pluralName ? pluralName : 'pacientes'}  {!!radialHover.subLabel && `(${radialHover.subLabel})`}</div>
            </div>
        </Hint>}
    </RadialChart>
}

const BarChartContainer = function ({ children }) {
    return <div className="px-5 pt-4 bg-white rounded-lg border border-gray-200">
        {children}
    </div>
}

const BarChartLegend = function ({ items, vertical }) {

    let containerClassName = 'flex flex-wrap gap-x-7 gap-y-1.5 mb-2.5'
    if (vertical) { // if horizontal === false, keep the vertical default disposition
        containerClassName = containerClassName + ' flex-col'
    }

    return <div className={containerClassName}>
        {items.map((i, indx) => !!i && <div className={legendLabelContainerStyle}>
            <Square color={colors[indx % colors.length]} />
            <div className={legendLabelStyle}>{i}</div>
        </div>)}
    </div>
}

const verticalGraphWidth = function (screenWidth) {
    return screenWidth > 1260 ? (screenWidth - 755) : (screenWidth > 750 ? (screenWidth - 365) : (screenWidth - 120))
}

const verticalGraphFullWidth = function (screenWidth) {
    return screenWidth > 750 ? (screenWidth - 365) : (screenWidth - 120)
}

const horizontalGraphWidth = function (screenWidth) {
    return screenWidth > 1260 ? 308 : (screenWidth > 750 ? (screenWidth - 365) : (screenWidth - 120))
}

const horizontalGraphHeight = function (screenWidth, indicatorsQuantity) {
    return screenWidth > 1260 ? (518 - 25 * indicatorsQuantity) : 210
}

const isVerticalGraph = function (screenWidth) {
    return screenWidth > 1260
}

const StyledXAxis = function () {
    return <XAxis
        tickSizeInner={0}
        tickPadding={5}
        style={{
            ticks: { stroke: '#111827' },
            text: { fontSize: 10, stroke: 'none', fill: '#111827', fontWeight: 400 }
        }}
    />
}

const StyledVerticalXAxis = function (screenWidth) {
    return <XAxis
        tickSizeInner={0}
        tickPadding={5}
        style={{
            ticks: { stroke: '#111827' },
            text: { fontSize: 9, stroke: 'none', fill: '#111827', fontWeight: 400 }
        }}
        tickLabelAngle={screenWidth > 1280 ? -45 : -90}
        tickFormat={v => `${v.substr(0, 4)}`}
    />
}

const StyledYAxis = function () {
    return <YAxis
        tickSizeInner={0}
        tickPadding={5}
        style={{
            ticks: { stroke: '#111827' },
            text: { fontSize: 10, stroke: 'none', fill: '#111827', fontWeight: 400 }
        }}
    />
}
// end of graphic elements

export const GraphsTab = function (props) {
    const { graphsData, groups, selectedGroup } = props
    const screenWidth = useWindowWidth()

    const GraphicsGroupContent = function () {

        const titleStyle = 'font-bold text-2xl leading-7 mb-2 text-gray-600'
        const subtitleStyle = 'font-bold text-lg leading-5 mb-3 text-gray-600'
        const barChartsContainerStyle = 'flex flex-1 flex-col xl:flex-row'
        const horizontalChartsContainerStyle = 'flex flex-col flex-1 xl:mr-7'

        switch (selectedGroup) {
            case groups[0].id: // visits
                const visitsData = graphsData.visits

                if (!visitsData) {
                    return <div>
                        <div className="mb-6">
                            <div className={titleStyle}>Consultas</div>
                        </div>
                        <div className="text-red-600">
                            Hubo un problema al obtener las estadísticas de consultas
                        </div>
                    </div>
                }

                const VisitsByDateGraphic = function () {

                    const [visitsHoveredValue, setVisitsHoveredValue] = useState(null)

                    const setCrosshair = function (xValue, index) {
                        setVisitsHoveredValue([
                            {
                                x: xValue,
                                y: visitsData.newPatientsByDate[index][xValue]
                            },
                            {
                                x: xValue,
                                y: visitsData.consultationsByDate[index][xValue]
                            },
                            {
                                x: xValue,
                                y: visitsData.consultingPatientsByDate[index][xValue]
                            }
                        ])
                    }

                    return <XYPlot
                        xType="ordinal"
                        width={verticalGraphWidth(screenWidth)}
                        height={211}
                        onMouseLeave={() => setVisitsHoveredValue(null)}
                    >
                        <HorizontalGridLines />
                        <VerticalGridLines />
                        {StyledXAxis()}
                        {StyledYAxis()}

                        <LineSeries
                            color={colors[0]}
                            onNearestX={(value, { index }) => setCrosshair(value.x, index)}
                            data={visitsData.newPatientsByDate.map(p => {
                                return {
                                    x: Object.keys(p)[0],
                                    y: Object.values(p)[0],
                                }
                            })}
                        />
                        <LineSeries
                            color={colors[1]}
                            data={visitsData.consultationsByDate.map(p => {
                                return {
                                    x: Object.keys(p)[0],
                                    y: Object.values(p)[0],
                                }
                            })}
                        />
                        <LineSeries
                            color={colors[2]}
                            data={visitsData.consultingPatientsByDate.map(p => {
                                return {
                                    x: Object.keys(p)[0],
                                    y: Object.values(p)[0],
                                }
                            })}
                        />

                        {visitsHoveredValue && <Crosshair values={visitsHoveredValue}>
                            <div className={hintStyle}>
                                <div>{visitsHoveredValue[0].x}</div>
                                <div>Nuevos registrados: {visitsHoveredValue[0].y}</div>
                                <div>Nº de consultas: {visitsHoveredValue[1].y}</div>
                                <div>Nº de pacientes que consultaron: {visitsHoveredValue[2].y}</div>
                            </div>
                        </Crosshair>}
                    </XYPlot>
                }

                const ConsultationsTypeByDateGraphic = function () {

                    const [visitsHoveredValue, setVisitsHoveredValue] = useState(null)

                    const setCrosshair = function (xValue, index) {
                        setVisitsHoveredValue([
                            {
                                x: xValue,
                                y: visitsData.consultationsInClinicByDate[index][xValue]
                            },
                            {
                                x: xValue,
                                y: visitsData.consultationsAtHomeByDate[index][xValue]
                            },
                            {
                                x: xValue,
                                y: visitsData.consultationsExtraWallByDate[index][xValue]
                            }
                        ])
                    }

                    return <XYPlot
                        xType="ordinal"
                        width={verticalGraphWidth(screenWidth)}
                        height={211}
                        onMouseLeave={() => setVisitsHoveredValue(null)}
                    >
                        <HorizontalGridLines />
                        <VerticalGridLines />
                        {StyledXAxis()}
                        {StyledYAxis()}

                        <LineSeries
                            color={colors[0]}
                            onNearestX={(value, { index }) => setCrosshair(value.x, index)}
                            data={visitsData.consultationsInClinicByDate.map(p => {
                                return {
                                    x: Object.keys(p)[0],
                                    y: Object.values(p)[0],
                                }
                            })}
                        />
                        <LineSeries
                            color={colors[1]}
                            data={visitsData.consultationsAtHomeByDate.map(p => {
                                return {
                                    x: Object.keys(p)[0],
                                    y: Object.values(p)[0],
                                }
                            })}
                        />
                        <LineSeries
                            color={colors[2]}
                            data={visitsData.consultationsExtraWallByDate.map(p => {
                                return {
                                    x: Object.keys(p)[0],
                                    y: Object.values(p)[0],
                                }
                            })}
                        />

                        {visitsHoveredValue && <Crosshair values={visitsHoveredValue}>
                            <div className={hintStyle}>
                                <div>{visitsHoveredValue[0].x}</div>
                                <div>Nº de consultas en consultorio: {visitsHoveredValue[0].y}</div>
                                <div>Nº de consultas en domicilio: {visitsHoveredValue[1].y}</div>
                                <div>Nº de consultas en Extramuro: {visitsHoveredValue[2].y}</div>
                            </div>
                        </Crosshair>}
                    </XYPlot>
                }

                const VisitsByEstablishment = function () {

                    const [visitsHoveredValue, setVisitsHoveredValue] = useState(null)

                    const setHint = function (value) {
                        const establishment = visitsData.establishments.find(e => e.establishmentAbbreviation === value.x)
                        setVisitsHoveredValue({
                            x: value.x,
                            x0: establishment.establishmentName,
                            y: value.y,
                            y0: establishment.newPatients,
                            y1: establishment.consultations,
                            y2: establishment.consultingPatients
                        })
                    }

                    return <XYPlot
                        xType="ordinal"
                        width={verticalGraphFullWidth(screenWidth)}
                        height={200}
                    >
                        <HorizontalGridLines />
                        <VerticalGridLines />
                        {StyledVerticalXAxis(screenWidth)}
                        {StyledYAxis()}
                        <VerticalBarSeries
                            colorType="literal"
                            onValueMouseOver={(value) => setHint(value)}
                            onValueMouseOut={() => setVisitsHoveredValue(null)}
                            data={visitsData.establishments.map(e => {
                                return {
                                    x: e.establishmentAbbreviation,
                                    y: e.newPatients,
                                    color: colors[0]
                                }
                            })}
                            barWidth={getBarWidth(visitsData.establishments, screenWidth)}
                        />
                        <VerticalBarSeries
                            colorType="literal"
                            onValueMouseOver={(value) => setHint(value)}
                            onValueMouseOut={() => setVisitsHoveredValue(null)}
                            data={visitsData.establishments.map(e => {
                                return {
                                    x: e.establishmentAbbreviation,
                                    y: e.consultations,
                                    color: colors[1]
                                }
                            })}
                            barWidth={getBarWidth(visitsData.establishments, screenWidth)}
                        />
                        <VerticalBarSeries
                            colorType="literal"
                            onValueMouseOver={(value) => setHint(value)}
                            onValueMouseOut={() => setVisitsHoveredValue(null)}
                            data={visitsData.establishments.map(e => {
                                return {
                                    x: e.establishmentAbbreviation,
                                    y: e.consultingPatients,
                                    color: colors[2]
                                }
                            })}
                            barWidth={getBarWidth(visitsData.establishments, screenWidth)}
                        />
                        {visitsHoveredValue && <Hint value={visitsHoveredValue}>
                            <div className={hintStyle}>
                                <div>{visitsHoveredValue.x0}</div>
                                <div>Nuevos registrados: {visitsHoveredValue.y0} ({+(100 * visitsHoveredValue.y0 / visitsData.totalNewPatients).toFixed(1)}%)</div>
                                <div>Nº de consultas: {visitsHoveredValue.y1} ({+(100 * visitsHoveredValue.y1 / visitsData.totalConsultations).toFixed(1)}%)</div>
                                <div>Nº de pacientes que consultaron: {visitsHoveredValue.y2} ({+(100 * visitsHoveredValue.y2 / visitsData.totalConsultingPatients).toFixed(1)}%)</div>
                            </div>
                        </Hint>}
                    </XYPlot>
                }

                const VisitsByAgeGraphic = function () {

                    const [visitsHoveredValue, setVisitsHoveredValue] = useState(null)

                    const isVertical = isVerticalGraph(screenWidth)

                    const setHint = function (value) {
                        const index = isVertical ? value.y : value.x
                        setVisitsHoveredValue({
                            x: value.x,
                            y: value.y,
                            a0: visitsData.newPatientsByAgeRange.find(p => +p[index] === 0 || p[index])[index],
                            a1: visitsData.consultationsByAgeRange.find(p => +p[index] === 0 || p[index])[index],
                            a2: visitsData.consultingPatientsByAgeRange.find(p => +p[index] === 0 || p[index])[index]
                        })
                    }

                    return <XYPlot
                        yType={isVertical ? 'ordinal' : 'linear'}
                        xType={isVertical ? 'linear' : 'ordinal'}
                        stackBy={isVertical ? 'x' : 'y'}
                        width={horizontalGraphWidth(screenWidth)}
                        height={horizontalGraphHeight(screenWidth, 3)}
                    >
                        <HorizontalGridLines />
                        <VerticalGridLines />
                        {StyledXAxis()}
                        {StyledYAxis()}
                        {isVertical ? <HorizontalBarSeries
                            colorType="literal"
                            onValueMouseOver={(value) => setHint(value)}
                            onValueMouseOut={() => setVisitsHoveredValue(null)}
                            data={visitsData.newPatientsByAgeRange.map(p => {
                                return {
                                    y: Object.keys(p)[0],
                                    x: Object.values(p)[0],
                                    color: colors[0]
                                }
                            })}
                        /> : <VerticalBarSeries
                            colorType="literal"
                            onValueMouseOver={(value) => setHint(value)}
                            onValueMouseOut={() => setVisitsHoveredValue(null)}
                            data={visitsData.newPatientsByAgeRange.map(p => {
                                return {
                                    x: Object.keys(p)[0],
                                    y: Object.values(p)[0],
                                    color: colors[0]
                                }
                            })}
                        />}
                        {isVertical ? <HorizontalBarSeries
                            colorType="literal"
                            onValueMouseOver={(value) => setHint(value)}
                            onValueMouseOut={() => setVisitsHoveredValue(null)}
                            data={visitsData.consultationsByAgeRange.map(p => {
                                return {
                                    y: Object.keys(p)[0],
                                    x: Object.values(p)[0],
                                    color: colors[1]
                                }
                            })}
                        /> : <VerticalBarSeries
                            colorType="literal"
                            onValueMouseOver={(value) => setHint(value)}
                            onValueMouseOut={() => setVisitsHoveredValue(null)}
                            data={visitsData.consultationsByAgeRange.map(p => {
                                return {
                                    x: Object.keys(p)[0],
                                    y: Object.values(p)[0],
                                    color: colors[1]
                                }
                            })}
                        />}
                        {isVertical ? <HorizontalBarSeries
                            colorType="literal"
                            onValueMouseOver={(value) => setHint(value)}
                            onValueMouseOut={() => setVisitsHoveredValue(null)}
                            data={visitsData.consultingPatientsByAgeRange.map(p => {
                                return {
                                    y: Object.keys(p)[0],
                                    x: Object.values(p)[0],
                                    color: colors[2]
                                }
                            })}
                        /> : <VerticalBarSeries
                            colorType="literal"
                            onValueMouseOver={(value) => setHint(value)}
                            onValueMouseOut={() => setVisitsHoveredValue(null)}
                            data={visitsData.consultingPatientsByAgeRange.map(p => {
                                return {
                                    x: Object.keys(p)[0],
                                    y: Object.values(p)[0],
                                    color: colors[2]
                                }
                            })}
                        />}
                        {visitsHoveredValue && <Hint value={visitsHoveredValue}>
                            <div className={hintStyle}>
                                <div>{isVertical ? visitsHoveredValue.y : visitsHoveredValue.x} años</div>
                                <div>Nuevos registrados: {visitsHoveredValue.a0} ({+(100 * visitsHoveredValue.a0 / visitsData.totalNewPatients).toFixed(1)}%)</div>
                                <div>Nº de consultas: {visitsHoveredValue.a1} ({+(100 * visitsHoveredValue.a0 / visitsData.totalConsultations).toFixed(1)}%)</div>
                                <div>Nº de pacientes que consultaron: {visitsHoveredValue.a2} ({+(100 * visitsHoveredValue.a0 / visitsData.totalConsultingPatients).toFixed(1)}%)</div>
                            </div>
                        </Hint>}
                    </XYPlot>
                }

                return <div>
                    <div className="mb-6">
                        <div className={titleStyle}>Consultas</div>
                        <IndicatorContainer columns={3} outline >
                            <Indicator outline color={colors[0]} title={'Nuevos registrados'} data={visitsData.totalNewPatients} position={'left'} />
                            <Indicator outline color={colors[1]} title={'Nº de consultas'} data={visitsData.totalConsultations} />
                            <Indicator outline color={colors[2]} title={'Nº de pacientes que consultaron'} data={visitsData.totalConsultingPatients} position={'right'} />
                        </IndicatorContainer>
                    </div>
                    <div className="w-full mb-6">
                        <div className={subtitleStyle}>Sexo y lugar</div>
                        <SexGridContainer columns={3}>
                            <SexGridObject>
                                <SexLegend title={'Nuevos registrados'} />
                                <SexChart maleData={visitsData.newMalePatients} femaleData={visitsData.newFemalePatients} />
                            </SexGridObject>
                            <SexGridObject>
                                <SexLegend title={'Pacientes que consultaron'} />
                                <SexChart maleData={visitsData.malePatients} femaleData={visitsData.femalePatients} />
                            </SexGridObject>
                            <SexGridObject>
                                <GenericLegend
                                    title={'Consultas por lugar'}
                                    items={['Consultorio', 'Domicilio', 'Extramuro']}
                                />
                                <GenericRadialChart
                                    itemsData={[
                                        { title: 'Consultorio', value: visitsData.consultationsInClinic },
                                        { title: 'Domicilio', value: visitsData.consultationsAtHome },
                                        { title: 'Extramuro', value: visitsData.consultationsExtraWall }
                                    ]}
                                    pluralName={'consultas'}
                                    horizontalAlign={'left'}
                                />
                            </SexGridObject>
                        </SexGridContainer>
                    </div>
                    <div className={barChartsContainerStyle}>
                        <div className={horizontalChartsContainerStyle}>
                            <div className="mb-6">
                                <div className={subtitleStyle}>Consultas por tiempo</div>
                                <BarChartContainer>
                                    <BarChartLegend
                                        items={['Nuevos registrados', 'Nº de consultas', 'Nº de pacientes que consultaron']}
                                    />
                                    <VisitsByDateGraphic />
                                </BarChartContainer>
                            </div>
                            <div className="mb-6">
                                <BarChartContainer>
                                    <BarChartLegend
                                        items={['Consultas en consultorio', 'Consultas en domicilio', 'Consultas en Extramuro']}
                                    />
                                    <ConsultationsTypeByDateGraphic />
                                </BarChartContainer>
                            </div>
                        </div>
                        <div className="mb-6">
                            <div className={subtitleStyle}>Consultas por rango de edad</div>
                            <BarChartContainer>
                                <BarChartLegend
                                    items={['Nuevos registrados', 'Nº de consultas', 'Nº de pacientes que consultaron']}
                                    vertical
                                />
                                <VisitsByAgeGraphic />
                            </BarChartContainer>
                        </div>

                    </div>
                    <div className="mb-6">
                        <div className={subtitleStyle}>Consultas por USF</div>
                        <BarChartContainer>
                            <BarChartLegend
                                items={['Nuevos registrados', 'Nº de consultas', 'Nº de pacientes que consultaron']}
                            />
                            <VisitsByEstablishment />
                        </BarChartContainer>
                    </div>
                </div>
            case groups[1].id: // education
                const educationData = graphsData.education

                if (!educationData) {
                    return <div>
                        <div className="mb-6">
                            <div className={titleStyle}>Educación</div>
                        </div>
                        <div className="text-red-600">
                            Hubo un problema al obtener las estadísticas de educación
                        </div>
                    </div>
                }

                const EducationByDateGraphic = function () {

                    const [educationHoveredValue, setEducationHoveredValue] = useState(null)

                    const setCrosshair = function (xValue, index) {
                        setEducationHoveredValue([
                            {
                                x: xValue,
                                y: educationData.consultingRoomEducationByDate[index][xValue]
                            }
                        ])
                    }

                    return <XYPlot
                        xType="ordinal"
                        width={verticalGraphWidth(screenWidth)}
                        height={170}
                        onMouseLeave={() => setEducationHoveredValue(null)}
                    >
                        <HorizontalGridLines />
                        <VerticalGridLines />
                        {StyledXAxis()}
                        {StyledYAxis()}
                        <LineSeries
                            color={colors[0]}
                            onNearestX={(value, { index }) => setCrosshair(value.x, index)}
                            data={educationData.consultingRoomEducationByDate.map(p => {
                                return {
                                    x: Object.keys(p)[0],
                                    y: Object.values(p)[0],
                                }
                            })}
                        />
                        {/*<LineSeries
                            color={colors[1]}
                            data={educationData.workshopsByDate.map(p => {
                                return {
                                    x: Object.keys(p)[0],
                                    y: Object.values(p)[0],
                                }
                            })}
                        />*/}

                        {educationHoveredValue && <Crosshair values={educationHoveredValue}>
                            <div className={hintStyle}>
                                <div>{educationHoveredValue[0].x}</div>
                                <div>Nº de educaciones en consultorio: {educationHoveredValue[0].y}</div>
                            </div>
                        </Crosshair>}
                    </XYPlot>
                }

                const EducationByEstablishment = function () {

                    const [educationHoveredValue, setEducationHoveredValue] = useState(null)

                    const setHint = function (value) {
                        const establishment = educationData.establishments.find(e => e.establishmentAbbreviation === value.x)
                        setEducationHoveredValue({
                            x: value.x,
                            x0: establishment.establishmentName,
                            y: value.y,
                            y0: establishment.consultingRoomEducation,
                            y1: establishment.participants
                        })
                    }

                    return <XYPlot
                        xType="ordinal"
                        width={verticalGraphWidth(screenWidth)}
                        height={220}
                    >
                        <HorizontalGridLines />
                        <VerticalGridLines />
                        {StyledVerticalXAxis(screenWidth)}
                        {StyledYAxis()}
                        <VerticalBarSeries
                            colorType="literal"
                            onValueMouseOver={(value) => setHint(value)}
                            onValueMouseOut={() => setEducationHoveredValue(null)}
                            data={educationData.establishments.map(e => {
                                return {
                                    x: e.establishmentAbbreviation,
                                    y: e.consultingRoomEducation,
                                    color: colors[0]
                                }
                            })}
                            barWidth={getBarWidth(educationData.establishments, screenWidth)}
                        />
                        <VerticalBarSeries
                            colorType="literal"
                            onValueMouseOver={(value) => setHint(value)}
                            onValueMouseOut={() => setEducationHoveredValue(null)}
                            data={educationData.establishments.map(e => {
                                return {
                                    x: e.establishmentAbbreviation,
                                    y: e.participants,
                                    color: colors[1]
                                }
                            })}
                            barWidth={getBarWidth(educationData.establishments, screenWidth)}
                        />
                        {educationHoveredValue && <Hint value={educationHoveredValue}>
                            <div className={hintStyle}>
                                <div>{educationHoveredValue.x0}</div>
                                <div>Nº de educaciones en consultorio: {educationHoveredValue.y0} ({+(100 * educationHoveredValue.y0 / educationData.consultingRoomEducation).toFixed(1)}%)</div>
                                <div>Nº de personas que recibieron educación: {educationHoveredValue.y1} ({+(100 * educationHoveredValue.y1 / educationData.participants).toFixed(1)}%)</div>
                            </div>
                        </Hint>}
                    </XYPlot>
                }

                const EducationByAgeGraphic = function () {

                    const [educationHoveredValue, setEducationHoveredValue] = useState(null)

                    const isVertical = isVerticalGraph(screenWidth)

                    const setHint = function (value) {
                        setEducationHoveredValue({
                            x: value.x,
                            y: value.y
                        })
                    }

                    return <XYPlot
                        yType={isVertical ? 'ordinal' : 'linear'}
                        xType={isVertical ? 'linear' : 'ordinal'}
                        stackBy={isVertical ? 'x' : 'y'}
                        width={horizontalGraphWidth(screenWidth)}
                        height={horizontalGraphHeight(screenWidth, 1)}
                    >
                        <HorizontalGridLines />
                        <VerticalGridLines />
                        {StyledXAxis()}
                        {StyledYAxis()}
                        {isVertical ? <HorizontalBarSeries
                            colorType="literal"
                            onValueMouseOver={(value) => setHint(value)}
                            onValueMouseOut={() => setEducationHoveredValue(null)}
                            data={educationData.educationByAgeRange.map(p => {
                                return {
                                    y: Object.keys(p)[0],
                                    x: Object.values(p)[0],
                                    color: colors[0]
                                }
                            })}
                        /> : <VerticalBarSeries
                            colorType="literal"
                            onValueMouseOver={(value) => setHint(value)}
                            onValueMouseOut={() => setEducationHoveredValue(null)}
                            data={educationData.educationByAgeRange.map(p => {
                                return {
                                    x: Object.keys(p)[0],
                                    y: Object.values(p)[0],
                                    color: colors[0]
                                }
                            })}
                        />}
                        {educationHoveredValue && <Hint value={educationHoveredValue}>
                            <div className={hintStyle}>
                                <div>{isVertical ? educationHoveredValue.y : educationHoveredValue.x} años</div>
                                <div>Educaciones: {isVertical ? educationHoveredValue.x : educationHoveredValue.y} ({+(100 * (isVertical ? educationHoveredValue.x : educationHoveredValue.y) / educationData.consultingRoomEducation).toFixed(1)}%)</div>
                            </div>
                        </Hint>}
                    </XYPlot>
                }

                return <div>
                    <div className="mb-6">
                        <div className={titleStyle}>Educación</div>
                        <IndicatorContainer columns={2} outline >
                            <Indicator outline color={colors[0]} title={'Nº de educaciones en consultorio'} data={educationData.consultingRoomEducation} position={'left'} />
                            <Indicator outline color={colors[1]} title={'Nº de personas que recibieron educación'} data={educationData.participants} position={'right'} />
                        </IndicatorContainer>
                    </div>
                    <div className="w-full mb-6">
                        <div className={subtitleStyle}>Sexo</div>
                        <SexGridContainer columns={2}>
                            <SexGridObject>
                                <SexLegend title={'Consultas por sexo'} />
                                <SexChart maleData={educationData.consultingRoomEducationMale} femaleData={educationData.consultingRoomEducationFemale} />
                            </SexGridObject>
                            {<SexGridObject>
                                <SexLegend title={'Personas que recibieron educación'} />
                                <SexChart maleData={educationData.maleParticipants} femaleData={educationData.femaleParticipants} horizontalAlign={'left'} />
                            </SexGridObject>}
                        </SexGridContainer>
                    </div>
                    <div className={barChartsContainerStyle}>
                        <div className={horizontalChartsContainerStyle}>
                            <div className="mb-6">
                                <div className={subtitleStyle}>Educación por tiempo</div>
                                <BarChartContainer>
                                    <BarChartLegend
                                        items={['Nº de educaciones']}
                                    />
                                    <EducationByDateGraphic />
                                </BarChartContainer>
                            </div>
                            <div className="mb-6">
                                <div className={subtitleStyle}>Educación por USF</div>
                                <BarChartContainer>
                                    <BarChartLegend
                                        items={['Nº de educaciones']}
                                    />
                                    <EducationByEstablishment />
                                </BarChartContainer>
                            </div>
                        </div>
                        <div>
                            <div className={subtitleStyle}>Educación por rango de edad</div>
                            <BarChartContainer>
                                <BarChartLegend
                                    items={['Nº de educaciones']}
                                    vertical
                                />
                                <EducationByAgeGraphic />
                            </BarChartContainer>
                        </div>
                    </div>
                </div>
            case groups[2].id: // measurements
                const measurementsData = graphsData.measurements

                if (!measurementsData) {
                    return <div>
                        <div className="mb-6">
                            <div className={titleStyle}>Mediciones</div>
                        </div>
                        <div className="text-red-600">
                            Hubo un problema al obtener las estadísticas de mediciones
                        </div>
                    </div>
                }

                const MeasurementsByDateGraphic = function () {

                    const [measurementsHoveredValue, setMeasurementsHoveredValue] = useState(null)

                    const setCrosshair = function (xValue, index) {
                        setMeasurementsHoveredValue([
                            {
                                x: xValue,
                                y: measurementsData.measurementsByDate[index][xValue]
                            },
                            {
                                x: xValue,
                                y: measurementsData.patientsByDate[index][xValue]
                            }
                        ])
                    }

                    return <XYPlot
                        xType="ordinal"
                        width={verticalGraphWidth(screenWidth)}
                        height={170}
                        onMouseLeave={() => setMeasurementsHoveredValue(null)}
                    >
                        <HorizontalGridLines />
                        <VerticalGridLines />
                        {StyledXAxis()}
                        {StyledYAxis()}

                        <LineSeries
                            color={colors[0]}
                            onNearestX={(value, { index }) => setCrosshair(value.x, index)}
                            data={measurementsData.measurementsByDate.map(p => {
                                return {
                                    x: Object.keys(p)[0],
                                    y: Object.values(p)[0],
                                }
                            })}
                        />
                        <LineSeries
                            color={colors[1]}
                            data={measurementsData.patientsByDate.map(p => {
                                return {
                                    x: Object.keys(p)[0],
                                    y: Object.values(p)[0],
                                }
                            })}
                        />

                        {measurementsHoveredValue && <Crosshair values={measurementsHoveredValue}>
                            <div className={hintStyle}>
                                <div>{measurementsHoveredValue[0].x}</div>
                                <div>Nº de mediciones: {measurementsHoveredValue[0].y}</div>
                                <div>Nº de pacientes con mediciones: {measurementsHoveredValue[1].y}</div>
                            </div>
                        </Crosshair>}
                    </XYPlot>
                }

                const MeasurementsByEstablishment = function () {

                    const [measurementsHoveredValue, setMeasurementsHoveredValue] = useState(null)

                    const setHint = function (value) {
                        const establishment = measurementsData.establishments.find(e => e.establishmentAbbreviation === value.x)
                        setMeasurementsHoveredValue({
                            x: value.x,
                            x0: establishment.establishmentName,
                            y: value.y,
                            y0: establishment.measurements,
                            y1: establishment.patients
                        })
                    }

                    return <XYPlot
                        xType="ordinal"
                        width={verticalGraphWidth(screenWidth)}
                        height={220}
                    >
                        <HorizontalGridLines />
                        <VerticalGridLines />
                        {StyledVerticalXAxis(screenWidth)}
                        {StyledYAxis()}
                        <VerticalBarSeries
                            colorType="literal"
                            onValueMouseOver={(value) => setHint(value)}
                            onValueMouseOut={() => setMeasurementsHoveredValue(null)}
                            data={measurementsData.establishments.map(e => {
                                return {
                                    x: e.establishmentAbbreviation,
                                    y: e.measurements,
                                    color: colors[0]
                                }
                            })}
                            barWidth={getBarWidth(measurementsData.establishments, screenWidth)}
                        />
                        <VerticalBarSeries
                            colorType="literal"
                            onValueMouseOver={(value) => setHint(value)}
                            onValueMouseOut={() => setMeasurementsHoveredValue(null)}
                            data={measurementsData.establishments.map(e => {
                                return {
                                    x: e.establishmentAbbreviation,
                                    y: e.patients,
                                    color: colors[1]
                                }
                            })}
                            barWidth={getBarWidth(measurementsData.establishments, screenWidth)}
                        />
                        {measurementsHoveredValue && <Hint value={measurementsHoveredValue}>
                            <div className={hintStyle}>
                                <div>{measurementsHoveredValue.x0}</div>
                                <div>Nº de mediciones: {measurementsHoveredValue.y0} ({+(100 * measurementsHoveredValue.y0 / measurementsData.totalMeasurements).toFixed(1)}%)</div>
                                <div>Nº de pacientes con mediciones: {measurementsHoveredValue.y1} ({+(100 * measurementsHoveredValue.y1 / measurementsData.totalPatients).toFixed(1)}%)</div>
                            </div>
                        </Hint>}
                    </XYPlot>
                }

                const MeasurementsByAgeGraphic = function () {

                    const [measurementsHoveredValue, setMeasurementsHoveredValue] = useState(null)

                    const isVertical = isVerticalGraph(screenWidth)

                    const setHint = function (value) {
                        setMeasurementsHoveredValue({
                            x: value.x,
                            y: value.y
                        })
                    }

                    return <XYPlot
                        yType={isVertical ? 'ordinal' : 'linear'}
                        xType={isVertical ? 'linear' : 'ordinal'}
                        stackBy={isVertical ? 'x' : 'y'}
                        width={horizontalGraphWidth(screenWidth)}
                        height={horizontalGraphHeight(screenWidth, 1)}
                    >
                        <HorizontalGridLines />
                        <VerticalGridLines />
                        {StyledXAxis()}
                        {StyledYAxis()}
                        {isVertical ? <HorizontalBarSeries
                            colorType="literal"
                            onValueMouseOver={(value) => setHint(value)}
                            onValueMouseOut={() => setMeasurementsHoveredValue(null)}
                            data={measurementsData.patientsByAgeRange.map(p => {
                                return {
                                    y: Object.keys(p)[0],
                                    x: Object.values(p)[0],
                                    color: colors[0]
                                }
                            })}
                        /> : <VerticalBarSeries
                            colorType="literal"
                            onValueMouseOver={(value) => setHint(value)}
                            onValueMouseOut={() => setMeasurementsHoveredValue(null)}
                            data={measurementsData.patientsByAgeRange.map(p => {
                                return {
                                    x: Object.keys(p)[0],
                                    y: Object.values(p)[0],
                                    color: colors[0]
                                }
                            })}
                        />}
                        {measurementsHoveredValue && <Hint value={measurementsHoveredValue}>
                            <div className={hintStyle}>
                                <div>{isVertical ? measurementsHoveredValue.y : measurementsHoveredValue.x} años</div>
                                <div>Pacientes por edad: {isVertical ? measurementsHoveredValue.x : measurementsHoveredValue.y} ({+(100 * (isVertical ? measurementsHoveredValue.x : measurementsHoveredValue.y) / measurementsData.totalMeasurements).toFixed(1)}%)</div>
                            </div>
                        </Hint>}
                    </XYPlot>
                }

                return <div>
                    <div className="mb-6">
                        <div className={titleStyle}>Mediciones</div>
                        <IndicatorContainer columns={2} outline >
                            <Indicator outline color={colors[0]} title={'Nº de mediciones'} data={measurementsData.totalMeasurements} position={'left'} />
                            <Indicator outline color={colors[1]} title={'Nº de pacientes con mediciones'} data={measurementsData.totalPatients} position={'right'} />
                        </IndicatorContainer>
                    </div>
                    <div className="w-full mb-6">
                        <div className={subtitleStyle}>Sexo</div>
                        <SexGridContainer columns={1}>
                            <SexGridObject>
                                <SexLegend title={'Pacientes con mediciones por sexo'} />
                                <SexChart maleData={measurementsData.totalMalePatients} femaleData={measurementsData.totalFemalePatients} />
                            </SexGridObject>
                        </SexGridContainer>
                    </div>
                    <div className={barChartsContainerStyle}>
                        <div className={horizontalChartsContainerStyle}>
                            <div className="mb-6">
                                <div className={subtitleStyle}>Mediciones por tiempo</div>
                                <BarChartContainer>
                                    <BarChartLegend
                                        items={['Nº de mediciones', 'Nº de pacientes con mediciones']}
                                    />
                                    <MeasurementsByDateGraphic />
                                </BarChartContainer>
                            </div>
                            <div className="mb-6">
                                <div className={subtitleStyle}>Mediciones por USF</div>
                                <BarChartContainer>
                                    <BarChartLegend
                                        items={['Nº de mediciones', 'Nº de pacientes con mediciones']}
                                    />
                                    <MeasurementsByEstablishment />
                                </BarChartContainer>
                            </div>
                        </div>
                        <div>
                            <div className={subtitleStyle}>Mediciones por rango de edad</div>
                            <BarChartContainer>
                                <BarChartLegend
                                    items={['Nº de pacientes con mediciones']}
                                    vertical
                                />
                                <MeasurementsByAgeGraphic />
                            </BarChartContainer>
                        </div>
                    </div>
                </div>
            case groups[3].id: // demographic
                const demographicData = graphsData.demographic

                if (!demographicData) {
                    return <div>
                        <div className="mb-6">
                            <div className={titleStyle}>Demografía</div>
                        </div>
                        <div className="text-red-600">
                            Hubo un problema al obtener las estadísticas de demografía
                        </div>
                    </div>
                }

                const DemographicByEstablishment = function () {

                    const [demographicHoveredValue, setDemographicHoveredValue] = useState(null)

                    const setHint = function (value) {
                        const establishment = demographicData.byEstablishments.find(e => e.establishmentAbbreviation === value.x)
                        setDemographicHoveredValue({
                            x: value.x,
                            x0: establishment.establishmentName,
                            y: value.y,
                            y0: establishment.obesePatients,
                            y1: establishment.smokingPatients,
                            y2: establishment.medicatedPatients
                        })
                    }

                    return <XYPlot
                        xType="ordinal"
                        width={verticalGraphFullWidth(screenWidth)}
                        height={220}
                    >
                        <HorizontalGridLines />
                        <VerticalGridLines />
                        {StyledVerticalXAxis(screenWidth)}
                        {StyledYAxis()}
                        <VerticalBarSeries
                            colorType="literal"
                            onValueMouseOver={(value) => setHint(value)}
                            onValueMouseOut={() => setDemographicHoveredValue(null)}
                            data={demographicData.byEstablishments.map(e => {
                                return {
                                    x: e.establishmentAbbreviation,
                                    y: e.obesePatients,
                                    color: colors[0]
                                }
                            })}
                            barWidth={getBarWidth(demographicData.byEstablishments, screenWidth)}
                        />
                        <VerticalBarSeries
                            colorType="literal"
                            onValueMouseOver={(value) => setHint(value)}
                            onValueMouseOut={() => setDemographicHoveredValue(null)}
                            data={demographicData.byEstablishments.map(e => {
                                return {
                                    x: e.establishmentAbbreviation,
                                    y: e.smokingPatients,
                                    color: colors[1]
                                }
                            })}
                            barWidth={getBarWidth(demographicData.byEstablishments, screenWidth)}
                        />
                        <VerticalBarSeries
                            colorType="literal"
                            onValueMouseOver={(value) => setHint(value)}
                            onValueMouseOut={() => setDemographicHoveredValue(null)}
                            data={demographicData.byEstablishments.map(e => {
                                return {
                                    x: e.establishmentAbbreviation,
                                    y: e.medicatedPatients,
                                    color: colors[2]
                                }
                            })}
                            barWidth={getBarWidth(demographicData.byEstablishments, screenWidth)}
                        />
                        {demographicHoveredValue && <Hint value={demographicHoveredValue}>
                            <div className={hintStyle}>
                                <div>{demographicHoveredValue.x0}</div>
                                <div>Pacientes con obesidad: {demographicHoveredValue.y0} ({+(100 * demographicHoveredValue.y0 / demographicData.totalObesePatients).toFixed(1)}%)</div>
                                <div>Pacientes fumadores: {demographicHoveredValue.y1} ({+(100 * demographicHoveredValue.y1 / demographicData.totalSmokingPatients).toFixed(1)}%)</div>
                                <div>Pacientes con medicación: {demographicHoveredValue.y2} ({+(100 * demographicHoveredValue.y2 / demographicData.totalMedicatedPatients).toFixed(1)}%)</div>
                            </div>
                        </Hint>}
                    </XYPlot>
                }

                const DemographicByAgeGraphic = function () {

                    const [demographicHoveredValue, setDemographicHoveredValue] = useState(null)

                    const setHint = function (value) {
                        const index = value.x
                        setDemographicHoveredValue({
                            x: value.x,
                            y: value.y,
                            a0: demographicData.obesityByAge.find(p => +p[index] === 0 || p[index])[index],
                            a1: demographicData.smokingByAge.find(p => +p[index] === 0 || p[index])[index],
                            a2: demographicData.medicatedByAge.find(p => +p[index] === 0 || p[index])[index],
                            a3: demographicData.exercisingByAge.find(p => +p[index] === 0 || p[index])[index]
                        })
                    }

                    return <XYPlot
                        xType={'ordinal'}
                        stackBy={'y'}
                        width={verticalGraphFullWidth(screenWidth)}
                        height={220}
                    >
                        <HorizontalGridLines />
                        <VerticalGridLines />
                        {StyledXAxis()}
                        {StyledYAxis()}
                        <VerticalBarSeries
                            colorType="literal"
                            onValueMouseOver={(value) => setHint(value)}
                            onValueMouseOut={() => setDemographicHoveredValue(null)}
                            data={demographicData.obesityByAge.map(p => {
                                return {
                                    x: Object.keys(p)[0],
                                    y: Object.values(p)[0],
                                    color: colors[0]
                                }
                            })}
                        />
                        <VerticalBarSeries
                            colorType="literal"
                            onValueMouseOver={(value) => setHint(value)}
                            onValueMouseOut={() => setDemographicHoveredValue(null)}
                            data={demographicData.smokingByAge.map(p => {
                                return {
                                    x: Object.keys(p)[0],
                                    y: Object.values(p)[0],
                                    color: colors[1]
                                }
                            })}
                        />
                        <VerticalBarSeries
                            colorType="literal"
                            onValueMouseOver={(value) => setHint(value)}
                            onValueMouseOut={() => setDemographicHoveredValue(null)}
                            data={demographicData.medicatedByAge.map(p => {
                                return {
                                    x: Object.keys(p)[0],
                                    y: Object.values(p)[0],
                                    color: colors[2]
                                }
                            })}
                        />
                        <VerticalBarSeries
                            colorType="literal"
                            onValueMouseOver={(value) => setHint(value)}
                            onValueMouseOut={() => setDemographicHoveredValue(null)}
                            data={demographicData.exercisingByAge.map(p => {
                                return {
                                    x: Object.keys(p)[0],
                                    y: Object.values(p)[0],
                                    color: colors[6]
                                }
                            })}
                        />
                        {demographicHoveredValue && <Hint value={demographicHoveredValue}>
                            <div className={hintStyle}>
                                <div>{demographicHoveredValue.x} años</div>
                                <div>Pacientes con obesidad: {demographicHoveredValue.a0} ({+(100 * demographicHoveredValue.a0 / demographicData.totalObesePatients).toFixed(1)}%)</div>
                                <div>Pacientes fumadores: {demographicHoveredValue.a1} ({+(100 * demographicHoveredValue.a1 / demographicData.totalSmokingPatients).toFixed(1)}%)</div>
                                <div>Pacientes con medicación: {demographicHoveredValue.a2} ({+(100 * demographicHoveredValue.a2 / demographicData.totalMedicatedPatients).toFixed(1)}%)</div>
                                <div>Pacientes con actividad física: {demographicHoveredValue.a3} ({+(100 * demographicHoveredValue.a3 / demographicData.totalPatientsExercising).toFixed(1)}%)</div>
                            </div>
                        </Hint>}
                    </XYPlot>
                }

                return <div>
                    <div className="mb-6">
                        <div className={titleStyle}>Demografía</div>
                        <IndicatorContainer columns={4} outline >
                            <Indicator outline color={colors[0]} title={'Pacientes con obesidad'} data={demographicData.totalObesePatients} position={'first'} />
                            <Indicator outline color={colors[1]} title={'Pacientes fumadores'} data={demographicData.totalSmokingPatients} position={'second'} />
                            <Indicator outline color={colors[2]} title={'Pacientes con medicación'} data={demographicData.totalMedicatedPatients} position={'third'} />
                            <Indicator outline color={colors[6]} title={'Pacientes con actividad física'} data={demographicData.totalPatientsExercising} position={'four'} />
                        </IndicatorContainer>
                    </div>
                    <div className="w-full mb-6">
                        <div className={subtitleStyle}>Sexo</div>
                        <SexGridContainer columns={4}>
                            <SexGridObject>
                                <SexLegend title={'Obesidad'} />
                                <SexChart maleData={demographicData.totalObeseMen} femaleData={demographicData.totalObeseWomen} />
                            </SexGridObject>
                            <SexGridObject>
                                <SexLegend title={'Fumadores'} />
                                <SexChart maleData={demographicData.totalSmokingMen} femaleData={demographicData.totalSmokingWomen} />
                            </SexGridObject>
                            <SexGridObject>
                                <SexLegend title={'Medicación'} />
                                <SexChart maleData={demographicData.totalMedicatedMen} femaleData={demographicData.totalMedicatedWomen} />
                            </SexGridObject>
                            <SexGridObject>
                                <SexLegend title={'Act. Física'} />
                                <SexChart maleData={demographicData.totalExercisingMen} femaleData={demographicData.totalExercisingWomen} horizontalAlign={'left'} />
                            </SexGridObject>
                        </SexGridContainer>
                    </div>
                    <div className={barChartsContainerStyle}>
                        <div className={horizontalChartsContainerStyle}>
                            <div className="mb-6">
                                <div className={subtitleStyle}>Demografía por USF</div>
                                <BarChartContainer>
                                    <BarChartLegend
                                        items={['Nº de pacientes con obesidad', 'Nº de fumadores', 'Nº de pacientes con medicación']}
                                    />
                                    <DemographicByEstablishment />
                                </BarChartContainer>
                            </div>
                            <div className="mb-6">
                                <div className={subtitleStyle}>Demografía por rango de edad</div>
                                <BarChartContainer>
                                    <BarChartLegend
                                        items={['Nº de pacientes con obesidad', 'Nº de fumadores', 'Nº de pacientes con medicación', '', '', '', 'Nº de pacientes con actividad física']}
                                    />
                                    <DemographicByAgeGraphic />
                                </BarChartContainer>
                            </div>
                        </div>
                    </div>
                </div>
            default:
                return <div>
                    default
                </div>

        }
    }

    return <div className="px-9 pb-8 pt-4 font-medium text-lg text-gray-700">
        <GraphicsGroupContent />
    </div >
}

export const TableTab = function (props) {

    const columnsByMonth = [
        { name: 'name', displayText: 'Indicador' },
        { name: 'total', displayText: 'Total' },
        { name: 'yearTotal', displayText: 'Total anual' },
        { name: 'month-1', displayText: 'Enero' },
        { name: 'month-2', displayText: 'Febrero' },
        { name: 'month-3', displayText: 'Marzo' },
        { name: 'month-4', displayText: 'Abril' },
        { name: 'month-5', displayText: 'Mayo' },
        { name: 'month-6', displayText: 'Junio' },
        { name: 'month-7', displayText: 'Julio' },
        { name: 'month-8', displayText: 'Agosto' },
        { name: 'month-9', displayText: 'Septiembre' },
        { name: 'month-10', displayText: 'Octubre' },
        { name: 'month-11', displayText: 'Noviembre' },
        { name: 'month-12', displayText: 'Diciembre' },
    ]

    const screenHeight = useWindowHeight()

    const { tables, selectedTable } = props

    const [columns, setColumns] = useState([])

    const [indicatorsData, setIndicatorsData] = useState([])
    const [monthlyData, setMonthlyData] = useState([])
    const [yearlyData, setYearlyData] = useState([])

    const [loading, setLoading] = useState(true)

    useEffect(() => {

        const establishments = props.establishments

        let auxColumns = [
            { name: 'name', displayText: 'Indicador' },
            { name: 'total', displayText: 'Total' },
        ]

        for (let e of establishments) {
            auxColumns.push({ name: `usf-${e.id}`, displayText: e.name })
        }
        setColumns(auxColumns)

        const tableData = props.tableData

        if (tableData.indicators) {
            let auxIndicatorsData = tableData.indicators.indicators.map(i => {
                let currentIndicator = {
                    name: i.text,
                    total: i.total,
                    isTitle: i.isTitle
                }
                for (let e of i.establishments) {
                    currentIndicator[`usf-${e.establishmentId}`] = e.value
                }
                return currentIndicator
            })
            setIndicatorsData(auxIndicatorsData)
        }

        let years = []
        let auxMonthlyData = []
        let auxYearlyData = [{ year: 'total', indicators: [] }]

        let currentYear = new Date().getFullYear()
        currentYear = (+currentYear)
        if (currentYear >= 2018) {
            let auxYear = 2018
            while (auxYear <= currentYear) {
                years.push(auxYear)
                auxYear = auxYear + 1
            }
        }

        if (tableData.year || tableData.month) {
            // getting years
            for (let y of years) {
                // also creating objects per year for yearly and monthly data
                auxMonthlyData.unshift({
                    year: y,
                    indicators: []
                })
                auxYearlyData.unshift({
                    year: y,
                    indicators: []
                })
            }

            // setting monthly data
            if (tableData.month) {
                for (let monthSummary of auxMonthlyData) {
                    const currentYear = monthSummary.year

                    // iterating on indicators
                    for (let ind of tableData.month?.indicators) {
                        // getting indicator data
                        let currentIndicator = { // total data
                            name: ind.text,
                            isTitle: ind.isTitle,
                        }
                        // is indicator is not just a title, get their values
                        if (!ind.isTitle) {
                            currentIndicator.total = ind.total
                            const currentYearData = ind.years.find(yr => +yr.year === +currentYear)
                            currentIndicator.yearTotal = currentYearData.total
                            for (let m in currentYearData.byMonth) { // by months
                                currentIndicator[`month-${m}`] = currentYearData.byMonth[m]
                            }
                        }
                        monthSummary.indicators.push(currentIndicator)
                    }
                }
                setMonthlyData(auxMonthlyData)
            }

            // setting yearly data
            if (tableData.year) {
                for (let yearSummary of auxYearlyData) {
                    const currentYear = yearSummary.year
                    const isTotal = currentYear === 'total'

                    // iterating on indicators
                    for (let ind of tableData.year.indicators) {
                        // getting indicator data
                        let currentIndicator = { // total data
                            name: ind.text,
                            isTitle: ind.isTitle
                        }
                        // is indicator is not just a title, get their values
                        if (!ind.isTitle) {
                            currentIndicator.total = isTotal ? ind['total'] : ind['totalByYear'][currentYear]
                            for (let est of ind.establishments) { // by establishmentdata
                                currentIndicator[`usf-${est.establishmentId}`] = isTotal ? est['total'] : est['byYear'][currentYear]
                            }
                        }
                        yearSummary.indicators.push(currentIndicator)
                    }
                }
                setYearlyData(auxYearlyData)
            }
        }
        setLoading(false)
    }, [])

    const TablesContent = function () {
        if (!props.isStaff) { // only table available 
            return monthlyData && monthlyData.length > 0 ? <div className="py-8 px-4">
                <div className="max-w-7xl mx-auto ml-2 px-4 mb-8 sm:px-6 md:px-8">
                    <h1 className="text-lg font-medium leading-9 text-gray-600">Resumen mensual</h1>
                </div>
                <div className={'flex flex-col pb-8 pt-2 px-4 gap-y-30'}>
                    {!!monthlyData && monthlyData.map(mth => <div key={`monthly-table-${mth.year}`}>
                        <div className="max-w-7xl mx-auto ml-2 px-4 mb-4 sm:px-6 md:px-8">
                            <h1 className="text-2xl font-bold leading-9 text-gray-600">Resumen {mth.year}</h1>
                        </div>
                        <div className="px-4 sm:px-6 md:px-8">
                            <div style={{ height: screenHeight - 295, minHeight: 300, overflowY: 'auto', position: 'relative' }}>
                                <Table sticky nonScrolleable={true} columns={columnsByMonth} rows={mth.indicators} />
                            </div>
                        </div>
                    </div>)}
                </div>
            </div> : <div className="px-8 py-3">
                <div className="font-medium text-lg text-red-600">
                    Hubo un problema al obtener el resumen por mes
                </div>
            </div>
        }
        switch (selectedTable) {
            case tables[0].id: // indicator
                return indicatorsData && indicatorsData.length > 0 ? <div className="py-8 px-4">
                    <div className="max-w-7xl mx-auto ml-2 px-4 mb-4 sm:px-6 md:px-8">
                        <h1 className="text-2xl font-bold leading-9 text-gray-600">Tabla de indicadores</h1>
                    </div>
                    {!!indicatorsData && <div className="px-4 sm:px-6 md:px-8 relative">
                        <div style={{ height: screenHeight - 295, minHeight: 300, overflowY: 'auto', position: 'relative' }}>
                            <Table sticky nonScrolleable={true} columns={columns} rows={indicatorsData} />
                        </div>
                    </div>}
                </div> : <div className="px-8 py-3">
                    <div className="font-medium text-lg text-red-600">
                        Hubo un problema al obtener la tabla de indicadores
                    </div>
                </div>
            case tables[1].id: // month
                return monthlyData && monthlyData.length > 0 ? <div className="py-8 px-4">
                    <div className="max-w-7xl mx-auto ml-2 px-4 mb-8 sm:px-6 md:px-8">
                        <h1 className="text-lg font-medium leading-9 text-gray-600">Resumen mensual</h1>
                    </div>
                    <div className={'flex flex-col pb-8 pt-2 px-4 gap-y-30'}>
                        {monthlyData.map(mth => <div key={`monthly-table-${mth.year}`}>
                            <div className="max-w-7xl mx-auto ml-2 px-4 mb-4 sm:px-6 md:px-8">
                                <h1 className="text-2xl font-bold leading-9 text-gray-600">Resumen {mth.year}</h1>
                            </div>
                            <div className="px-4 sm:px-6 md:px-8">
                                <div style={{ height: screenHeight - 295, minHeight: 300, overflowY: 'auto', position: 'relative' }}>
                                    <Table sticky nonScrolleable={true} columns={columnsByMonth} rows={mth.indicators} />
                                </div>
                            </div>
                        </div>)}
                    </div>
                </div> : <div className="px-8 py-3">
                    <div className="font-medium text-lg text-red-600">
                        Hubo un problema al obtener el resumen por mes
                    </div>
                </div>
            case tables[2].id: // year
                return yearlyData && yearlyData.length > 0 ? <div className="flex flex-col py-8 px-4 gap-y-30">
                    {!!yearlyData && yearlyData.map(yd => <div key={`yearly-table-${yd.year}`}>
                        <div className="max-w-7xl mx-auto ml-2 px-4 mb-4 sm:px-6 md:px-8">
                            <h1 className="text-2xl font-bold leading-9 text-gray-600">Resumen {yd.year}</h1>
                        </div>
                        <div className="px-4 sm:px-6 md:px-8">
                            <div style={{ height: screenHeight - 295, minHeight: 300, overflowY: 'auto', position: 'relative' }}>
                                <Table sticky nonScrolleable={true} columns={columns} rows={yd.indicators} />
                            </div>
                        </div>
                    </div>)}
                </div> : <div className="px-8 py-3">
                    <div className="font-medium text-lg text-red-600">
                        Hubo un problema al obtener el resumen por año
                    </div>
                </div>
            default:
                return <div />
        }
    }

    return (loading ? <div /> : <TablesContent />)

}

export const DataTab = function (props) {

    const { type, filters } = props

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const getName = function () {
        if (type === 'patients') {
            return 'pacientes'
        } else if (type === 'patient-consultations') {
            return 'pacientes y consultas'
        } else {
            return 'consultas'
        }
    }

    const download = function () {
        setLoading(true)
        setError('')
        if (type === 'patients') {
            api.reports.download_raw_patients().then(res => {
                setLoading(false)
                const url = window.URL.createObjectURL(new Blob([res]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Datos_Crudos_Pacientes.xlsx'); //or any other extension
                document.body.appendChild(link);
                link.click();
            }, err => {
                console.log('error al descargar la tabla de indicadores: ', err.response?.data)
                setError('Hubo un problema al descargar los datos de los pacientes, inténtalo más adelante')
                setLoading(false)
            })
        } else if (type === 'patient-consultations') {
            api.reports.download_raw_patient_consultations({ establishment: filters.establishment }).then(res => {
                setLoading(false)
                const url = window.URL.createObjectURL(new Blob([res]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Datos_Crudos_Pacientes_Consultas.xlsx'); //or any other extension
                document.body.appendChild(link);
                link.click();
            }, err => {
                console.log('error al descargar la tabla de indicadores: ', err.response?.data)
                setError('Hubo un problema al descargar los datos de los pacientes y consultas, inténtalo más adelante')
                setLoading(false)
            })
        } else { // consultations
            let newFilters = filters
            delete newFilters.establishment
            if (!newFilters.date_max) {
                delete newFilters.date_max
            }
            api.reports.download_raw_consultations(newFilters).then(res => {
                setLoading(false)
                const url = window.URL.createObjectURL(new Blob([res]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Datos_Crudos_Consultas.xlsx'); //or any other extension
                document.body.appendChild(link);
                link.click();
            }, err => {
                console.log('error al descargar la tabla de indicadores: ', err.response?.data)
                setError('Hubo un problema al descargar los datos de las consultas, inténtalo más adelante')
                setLoading(false)
            })
        }
    }

    return <div className="pt-2">
        <div className="mx-auto ml-2 px-4 mb-8 sm:px-6 md:px-8">
            <h1 className="text-2xl font-bold leading-9 text-gray-600">Datos crudos de {getName()}</h1>
        </div>
        <div style={{ height: 'calc(100vh - 280px)' }} className="flex flex-col justify-center items-center text-center">
            <div className="mx-auto my-auto text-center">
                <ClipboardListIcon className="h-12 w-12 mx-auto text-gray-400" aria-hidden="true" />
                <h3 className="mt-2 text-sm font-semibold text-gray-900">Descagar datos crudos</h3>
                <p className="mt-1 text-sm font-semibold text-gray-500">Hacé click en el botón para descargar datos crudos de {getName()}.</p>
                <div className="mt-6">
                    <button
                        onClick={() => download()}
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-blue-600 hover:bg-blue-700"
                    >
                        {loading ?
                            <div className="px-10">
                                <div className="w-5 h-5 border-b-2 border-white rounded-full animate-spin"></div>
                            </div> : 'Descargar datos'
                        }
                    </button>
                </div>
                {error && <div className="mt-6 text-sm text-red-600">
                    {error}
                </div>}
            </div>
        </div>
    </div>
}