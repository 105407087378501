import { Routes, Route, BrowserRouter as Router, Navigate } from 'react-router-dom';
import { useAuth } from './context/authContext';
import EstablishmentPage from './pages/establishments/EstablishmentPage';
import UserPage from './pages/users/UserPage';
import PatientPage from './pages/patients/PatientPage';
import LoginPage from './pages/LoginPage';
import DistrictPage from './pages/districts/DistrictPage';
import DistrictDetailPage from './pages/districts/DistrictDetailPage';
import PatientDetailPage from './pages/patients/PatientDetailPage';
import PatientProfilePage from './pages/patients/PatientProfilePage';
import PatientHistoryPage from './pages/patients/PatientHistoryPage';
import ConsultationDetailPage from './pages/patients/ConsultationDetailPage';
import ConsultationNewPage from './pages/patients/ConsultationNewPage';
import PatientNewPage from './pages/patients/PatientNewPage';
import ReportsPage from './pages/reports/ReportsPage';
import UserNewPage from './pages/users/UserNewPage';
import EstablishmentNewPage from './pages/establishments/EstablishmentNewPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const auth = useAuth();

  const authenticatedRoutes = (
    <Routes>
      <Route path="/" element={<Navigate to="/patients" />} />
      <Route exact path="/establishments" element={<EstablishmentPage />} />
      <Route exact path="/establishments/new" element={<EstablishmentNewPage />} />
      <Route path="/establishments/:idEstablishment" element={<EstablishmentNewPage />} />
      <Route exact path="/users" element={<UserPage />} />
      <Route exact path="/users/new" element={<UserNewPage />} />
      <Route path="/users/:idUser" element={<UserNewPage />} />
      <Route exact path="/districts" element={<DistrictPage />} />
      <Route exact path="/districts/new" element={<DistrictDetailPage />} />
      <Route path="/districts/:idDistrict" element={<DistrictDetailPage />} />
      <Route exact path="/patients" element={<PatientPage />} />
      <Route exact path="/patients/new" element={<PatientNewPage />} />
      <Route path="/patients/:idPatient" element={<PatientDetailPage />} />
      <Route exact path="/patients/:idPatient/history" element={<PatientHistoryPage />} />
      <Route path="/patients/:idPatient/history/:idConsultation" element={<ConsultationDetailPage />} />
      <Route path="/patients/:idPatient/new" element={<ConsultationNewPage />} />
      <Route path="/patients/:idPatient/profile" element={<PatientProfilePage />} />
      <Route path="/reports" element={<ReportsPage />} />
    </Routes>
  );

  return (
    <Router>
      {auth.isAuthenticated ? (
        authenticatedRoutes
      ) : (
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/reset-password/:uuid/:token" element={<ResetPasswordPage />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      )}
      <ToastContainer
        position="top-center"
        autoClose={7000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        toastClassName={'toast-bg-yellow'} // i had to use raw css bc i needed the !important (class in index.css)
        bodyClassName={'text-gray-700 font-medium toast-font-inter'}
      />
    </Router>
  );
}

export default App;
