import axios from "axios";
import QueryString from "qs";

const errorCodes = {
    badRequest: 'badRequest',
    unauthorized: 'unauthorized',
    forbidden: 'forbidden',
    notFound: 'notFound',
    serverError: 'serverError',
    unexpected: 'unexpected',
    invalidCredentials: 'invalidCredentials',
}

class API {
    constructor(collection) {
        this.collectionUrl = `${process.env.REACT_APP_API_BASE_URL}/${collection}`;
    }

    _handleError(error) {
        if (error.response?.status === 400)
            return Promise.reject({ code: errorCodes.badRequest, message: error.response.data })
        if (error.response?.status === 401)
            return Promise.reject(errorCodes.unauthorized)
        if (error.response?.status === 403)
            return Promise.reject(errorCodes.forbidden)
        if (error.response?.status === 404)
            return Promise.reject(errorCodes.notFound)
        if (500 <= error.response?.status <= 599)
            return Promise.reject(errorCodes.serverError)
        return Promise.reject(errorCodes.unexpected)
    }

    async getMulti(ordering = null, search = null, limit = null, offset = null, extra = {}) {
        const params = { limit, offset, ...extra }
        if (ordering)
            params.ordering = ordering;
        if (search && search.length > 0)
            params.search = search;
        try {
            const response = await axios.get(`${this.collectionUrl}/`, { params },);
            return Promise.resolve(response.data);
        } catch (error) {
            return this._handleError(error)
        }
    }

    async getOne(id) {
        try {
            console.log(`${this.collectionUrl}/${id}/`)
            const response = await axios.get(`${this.collectionUrl}/${id}/`);
            return Promise.resolve(response.data)
        } catch (e) {
            return this._handleError(e)
        }
    }

    async create(data) {
        try {
            const response = await axios.post(`${this.collectionUrl}/`, data);
            return Promise.resolve(response.data)
        } catch (e) {
            return this._handleError(e)
        }
    }

    async update(id, data, partial = false) {
        try {
            let response = null;
            if (partial)
                response = await axios.patch(`${this.collectionUrl}/${id}/`, data);
            else
                response = await axios.put(`${this.collectionUrl}/${id}/`, data);
            return Promise.resolve(response.data);
        } catch (e) {
            return this._handleError(e)
        }
    }

    async delete(id) {
        try {
            const response = await axios.delete(`${this.collectionUrl}/${id}/`)
            return response
        } catch (e) {
            return this._handleError(e)
        }
    }
}

class UserAPI extends API {
    async getToken(username, password) {
        try {
            const response = await axios.post(`${this.collectionUrl}/token/`, { username, password })
            return Promise.resolve(response.data)
        } catch (e) {
            return Promise.reject(errorCodes.invalidCredentials)
        }
    }

    async changePassword(newPass) {
        try {
            const response = await axios.post(`${this.collectionUrl}/me/change-password/`, { new: newPass });
            return Promise.resolve(response.data)
        } catch (e) {
            return this._handleError(e)
        }
    }

    async getMe() {
        try {
            const response = await axios.get(`${this.collectionUrl}/me/`)
            return Promise.resolve(response.data)
        } catch (e) {
            return this._handleError(e)
        }
    }
}

class PasswordAPI extends API {
    async sendEmail(email) {
        try {
            const response = await axios.post(`${this.collectionUrl}/`, { email: email });
            return Promise.resolve(response.data)
        } catch (e) {
            return this._handleError(e)
        }
    }

    async changePassword(password, uuid, token) {
        try {
            const response = await axios.post(`${this.collectionUrl}/${uuid}/${token}/`, { password: password })
        } catch (e) {
            return this._handleError(e)
        }
    }
}

class ReportsAPI extends API {
    async get(report, filters) {
        try {
            const response = await axios.get(`${this.collectionUrl}/${report}/`, {
                params: filters,
                paramsSerializer: params => QueryString.stringify(params, { arrayFormat: 'repeat' })
            })
            return Promise.resolve(response.data)
        } catch (e) {
            return Promise.reject(errorCodes.invalidCredentials)
        }
    }

    async download_indicators_table(filters) {
        try {
            const response = await axios.get(`${this.collectionUrl}/indicator-table/download/`, {
                params: filters,
                responseType: 'blob'
            })
            return Promise.resolve(response.data)
        } catch (e) {
            return Promise.reject(errorCodes.invalidCredentials)
        }
    }

    async download_summary_by_month(filters) {
        try {
            const response = await axios.get(`${this.collectionUrl}/indicator-table/summary-by-month/download`, {
                params: filters,
                paramsSerializer: params => QueryString.stringify(params, { arrayFormat: 'repeat' }),
                responseType: 'blob'
            })
            return Promise.resolve(response.data)
        } catch (e) {
            return Promise.reject(errorCodes.invalidCredentials)
        }
    }

    async download_summary_by_year() {
        try {
            const response = await axios.get(`${this.collectionUrl}/indicator-table/summary-by-year/download/`, {
                responseType: 'blob'
            })
            return Promise.resolve(response.data)
        } catch (e) {
            return Promise.reject(errorCodes.invalidCredentials)
        }
    }

    async download_raw_patients() {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/raw-data/download/patients`, {
                responseType: 'blob'
            })
            return Promise.resolve(response.data)
        } catch (e) {
            return Promise.reject(errorCodes.invalidCredentials)
        }
    }

    async download_raw_consultations(filters) {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/raw-data/download/consultations`, {
                params: filters,
                responseType: 'blob'
            })
            return Promise.resolve(response.data)
        } catch (e) {
            return Promise.reject(errorCodes.invalidCredentials)
        }
    }

    async download_raw_patient_consultations(filters) {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/raw-data/download/patient-consultations`, {
                params: filters,
                responseType: 'blob'
            })
            return Promise.resolve(response.data)
        } catch (e) {
            return Promise.reject(errorCodes.invalidCredentials)
        }
    }
}

class SyncAPI extends API {
    async getStatic() {
        try {
            const response = await axios.get(`${this.collectionUrl}/static-data`)
            return Promise.resolve(response.data)
        } catch (e) {
            return Promise.reject(errorCodes.invalidCredentials)
        }
    }

    async getPatientsConsultations() {
        try {
            const response = await axios.get(`${this.collectionUrl}/patients-consultations`)
            return Promise.resolve(response.data)
        } catch (e) {
            return Promise.reject(errorCodes.invalidCredentials)
        }
    }

    async updateOperation(data) {
        try {
            const response = await axios.post(`${this.collectionUrl}/patients-consultations`, data)
            return Promise.resolve(response.data)
        } catch (e) {
            return Promise.reject(errorCodes.invalidCredentials)
        }

    }
}

const api = {
    districts: new API('districts'),
    consultations: new API('consultations'),
    establishments: new API('establishments'),
    patients: new API('patients'),
    places: new API('places'),
    reports: new ReportsAPI('reports'),
    locations: new API('locations'),
    users: new UserAPI('users'),
    sync: new SyncAPI('sync'),
    reset_password: new PasswordAPI('reset-password')
}

export default api;
export { errorCodes, API, UserAPI };