import React, { useEffect } from "react";
import { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import {
    CalendarIcon,
    ChartBarIcon,
    LocationMarkerIcon,
    MenuAlt2Icon,
    OfficeBuildingIcon,
    PlusIcon,
    UserCircleIcon,
    UserGroupIcon,
} from '@heroicons/react/outline'
import api from "../../api";
import Sidebar from "../components/Sidebar";
import Breadcrumbs from "../components/Breadcrumbs";
import TextInput from "../components/TextInput";
import SelectInput from "../components/SelectInput";

const navigation = [
    { name: 'Pacientes', href: '/patients', icon: UserGroupIcon, current: false },
    { name: 'Reportes', href: '/reports', icon: ChartBarIcon, current: false },
    { name: 'Establecimientos', href: '/establishments', icon: OfficeBuildingIcon, current: false },
    { name: 'Usuarios', href: '/users', icon: UserCircleIcon, current: false },
    { name: 'Distritos', href: '/districts', icon: LocationMarkerIcon, current: true },
    // { name: 'Calendarios', href: '/calendars', icon: CalendarIcon, current: false },
]

const departments = [
    { name: "Central", id: 1 },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function DistrictDetailPage() {

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const { idDistrict } = useParams()
    const navigate = useNavigate();
    const [district, setDistrict] = useState({
        name: '',
        department: '',
        locations: [],
        NewLocation: '',
        tmp: ''
    });
    const [errors, setErrors] = useState({
        name: '',
        department: '',
        locations: '',
    });
    const handleChange = (field, value) => setDistrict((prev) => ({ ...prev, [field]: value }));
    const handleError = (field, value) => setErrors((prev) => ({ ...prev, [field]: value }));
    const handleAddLocation = () => {
        setDistrict(prevState => ({
            ...prevState,
            locations: [...prevState.locations, { name: prevState.NewLocation, id: (prevState.locations.length + 1), edit: false }]
        })).then(
            setDistrict(prevState => ({
                ...prevState, NewLocation: ''
            })))
    }
    const handleEditLocation = (location) => {
        setDistrict(prevState => ({
            ...prevState,
            tmp: prevState.locations.find(element => (element.name === location)).name,
            locations: prevState.locations.map(element => (element.name === location ? { ...element, edit: true } : element))
        }))
    }
    const handleRemoveLocation = (location) => {
        setDistrict(prevState => ({
            ...prevState,
            locations: prevState.locations.filter(item => item.name != location)
        }))
    }
    const submitEdit = (id) => {
        setDistrict(prevState => ({
            ...prevState,
            locations: prevState.locations.map(element => (element.id === id ? { ...element, edit: false, name: prevState.tmp } : element)),
            tmp: '',
        }))
    }
    const handleSubmit = (event) => {
        event.preventDefault()
        let error = false
        if (idDistrict) {
            if (!district.name) {
                handleError('name', 'Este campo es obligatorio')
                error = true
            } else {
                handleError('name', '')
            }
            if (!district.department) {
                handleError('department', 'Este campo es obligatorio')
                error = true
            } else {
                handleError('department', '')
            }
            if (error) {
                return
            }
            api.districts.update(idDistrict, district)
            navigate('/districts')
        } else {
            if (!district.name) {
                handleError('name', 'Este campo es obligatorio')
                error = true
            } else {
                handleError('name', '')
            }
            if (!district.department) {
                handleError('department', 'Este campo es obligatorio')
                error = true
            } else {
                handleError('department', '')
            }
            if (error) {
                return
            }
            api.districts.create(district)
            navigate('/districts')
        }
    }
    const [title, setTitle] = useState("")

    useEffect(() => {
        if (idDistrict) {
            api.districts.getOne(idDistrict).then((response) => {
                setDistrict({
                    name: response.name,
                    department: departments.find(element => element.id == response.department).id,
                    locations: response.locations.map((location) => ({ id: location.id, name: location.name, edit: false }))
                });
                setTitle(response.name)
                setLoading(false);
            }
            )
        } else {
            setLoading(false);
        }
    }, [])

    const pages = [
        { name: 'Distritos', href: '/districts', current: false },
        { name: idDistrict ? district.name : 'Nuevo distrito', href: "#", current: true },
    ]

    return (
        <div className="bg-gray-100 min-h-screen h-full">
            <Sidebar currentScreen={'Distritos'} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="md:pl-64 flex flex-col flex-1">
                <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-100">
                    <button
                        type="button"
                        className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                        onClick={() => setSidebarOpen(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div className="flex flex-col flex-1 ml-6 mt-6">
                    {loading ?
                        <div className="flex h-screen items-center justify-center ">
                            <div className="w-16 h-16 border-b-2 border-blue-900 rounded-full animate-spin"></div>
                        </div> :
                        <>
                            <form onSubmit={(event) => handleSubmit(event)} autoComplete="off">
                                <div className="flex justify-between">
                                    <Breadcrumbs pages={pages} />
                                    <button
                                        type="submit"
                                        className="mr-8 items-center px-4 py-2 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-blue-600 hover:bg-blue-700"
                                    >
                                        Guardar
                                    </button>
                                </div>
                                <h1 className="mt-6 ml-4 text-3xl leading-9 font-bold text-gray-900">{title ? title : "Nuevo distrito"}</h1>
                                <div className="flex flex-1 mt-4">
                                    <div className="max-w-7xl flex-col items-center px-4">
                                        <TextInput className="shadow-sm h-8 pl-2 w-full border border-gray-300 block sm:text-sm rounded-md" errorMessage={errors.name} handleChange={handleChange} value={district.name} name="name" label="Nombre" />
                                    </div>
                                    <div className="max-w-7xl flex-col items-center px-4">
                                        <SelectInput className="shadow-sm w-48 border border-gray-300 block sm:text-sm rounded-md" placeholder={idDistrict ? false : true} errorMessage={errors.department} handleChange={handleChange} defaultValue={district.department} name="department" label="Departamento" options={departments.map((element) => { return <option label={element.name} value={element.id} >{element.name}</option> })} />
                                    </div>
                                </div>
                                <main className="flex-1">
                                    <h3 className="mt-6 ml-4 font-medium text-gray-900">Localidades</h3>
                                    <ul role="list" className="ml-6 mt-4 w-1/2 divide-y space-y-2 divide-gray-200">
                                        {district.locations.map((location) => (
                                            <li key={location.id} className="py-4">
                                                <div className="flex">
                                                    {location.edit ?
                                                        <div className="flex">
                                                            <TextInput name="tmp" value={district.tmp} handleChange={handleChange} placeholder="Nombre" className="shadow-sm h-8 pl-2 w-40 border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm rounded-md" />
                                                            <button
                                                                type="submit"
                                                                onClick={() => submitEdit(location.id)}
                                                                className="inline-flex items-center ml-20 text-base font-medium rounded-md text-blue-600 hover:text-blue-800"
                                                            >
                                                                Guardar
                                                            </button>
                                                        </div>
                                                        :
                                                        <>
                                                            <p className="text-md w-1/2 font-medium text-gray-700">{location.name}</p>
                                                            <div>
                                                                <button
                                                                    type="button"
                                                                    onClick={() => handleEditLocation(location.name)}
                                                                    className="inline-flex items-center border border-transparent text-base font-medium rounded-md text-blue-600 hover:text-blue-800"
                                                                >
                                                                    Editar
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    onClick={() => handleRemoveLocation(location.name)}
                                                                    className="inline-flex items-center mx-6 border border-transparent text-base font-medium rounded-md text-gray-400 hover:text-gray-600"
                                                                >
                                                                    Eliminar
                                                                </button>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            </li>
                                        ))}
                                        <li key="last" className="py-4">
                                            <div className="flex">
                                                <TextInput value={district.NewLocation} name="NewLocation" handleChange={handleChange} placeholder="Nombre" className="shadow-sm h-8 pl-2 w-40 border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm rounded-md" />
                                                <button
                                                    type="button"
                                                    onClick={handleAddLocation}
                                                    className="inline-flex items-center ml-20 border border-transparent text-base font-medium rounded-md text-blue-600 hover:text-blue-800"
                                                >
                                                    Guardar
                                                </button>
                                            </div>
                                        </li>
                                    </ul>
                                </main>
                            </form>
                        </>
                    }
                </div>
            </div>
        </div>
    );
}
