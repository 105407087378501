import React, { useEffect } from "react";
import { useState } from 'react'
import {
    CalendarIcon,
    ChartBarIcon,
    LocationMarkerIcon,
    MenuAlt2Icon,
    OfficeBuildingIcon,
    UserCircleIcon,
    UserGroupIcon,
} from '@heroicons/react/outline'
import api from "../../api";
import Sidebar from "../components/Sidebar";
import Breadcrumbs from "../components/Breadcrumbs";
import TextInput from "../components/TextInput";
import SelectInput from "../components/SelectInput";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

const navigation = [
    { name: 'Pacientes', href: '/patients', icon: UserGroupIcon, current: false },
    { name: 'Reportes', href: '/reports', icon: ChartBarIcon, current: false },
    { name: 'Establecimientos', href: '/establishments', icon: OfficeBuildingIcon, current: true },
    { name: 'Usuarios', href: '/users', icon: UserCircleIcon, current: false },
    { name: 'Distritos', href: '/districts', icon: LocationMarkerIcon, current: false },
    // { name: 'Calendarios', href: '/calendars', icon: CalendarIcon, current: false },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function EstablishmentNewPage() {

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [districts, setDistricts] = useState([]);
    const { idEstablishment } = useParams();
    const navigate = useNavigate();
    const [establishment, setEstablishment] = useState({
        name: "",
        district: "",
        code: "",
        latitude: "",
        longitude: "",
    });
    const [errors, setErrors] = useState({
        name: "",
        district: "",
        latitude: "",
        longitude: "",
    });

    const pages = [
        { name: 'Establecimientos', href: '/establishments', current: false },
        { name: idEstablishment ? 'Detalle' : 'Nuevo establecimiento', href: `#`, current: true },
    ]

    const handleChange = (field, value) => setEstablishment((prev) => ({ ...prev, [field]: value }));
    const handleError = (field, value) => setErrors((prev) => ({ ...prev, [field]: value }));

    const handleSubmit = (event) => {
        event.preventDefault();
        let error = false
        if (idEstablishment) {
            if (!establishment.name) {
                handleError('name', 'Este campo es obligatorio')
                error = true
            } else {
                handleError('name', '')
            }
            if (!establishment.department) {
                handleError('department', 'Este campo es obligatorio')
                error = true
            } else {
                handleError('department', '')
            }
            if (!establishment.latitude) {
                handleError('latitude', 'Este campo es obligatorio')
                error = true
            } else {
                handleError('latitude', '')
            }
            if (!establishment.longiude) {
                handleError('longitude', 'Este campo es obligatorio')
            } else {
                handleError('longitude', '')
            }
            if (error) {
                return
            }
            api.establishments.update(idEstablishment, establishment)
            navigate('/establishments');
        } else {
            if (!establishment.name) {
                handleError('name', 'Este campo es obligatorio')
                error = true
            } else {
                handleError('name', '')
            }
            if (!establishment.district) {
                handleError('district', 'Este campo es obligatorio')
                error = true
            } else {
                handleError('district', '')
            }
            if (!establishment.latitude) {
                handleError('latitude', 'Este campo es obligatorio')
                error = true
            } else {
                handleError('latitude', '')
            }
            if (!establishment.longiude) {
                handleError('longitude', 'Este campo es obligatorio')
            } else {
                handleError('longitude', '')
            }
            if (error) {
                return
            }
            api.establishments.create(establishment);
            navigate('/establishments');
        }
    }

    useEffect(async () => {
        if (idEstablishment) {
            api.establishments.getOne(idEstablishment).then((response) => {
                setEstablishment({
                    name: response.name,
                    district: response.district,
                    latitude: response.latitude ? response.latitude : undefined,
                    longitude: response.longitude ? response.longitude : undefined
                })
            }).then(() =>
                api.districts.getMulti().then((response) => {
                    setDistricts(response.results.map(({ id, name }) => {
                        return (
                            < option label={name} value={id} selected={name == establishment.district ? true : false} >{name}</option>
                        )
                    })
                    );
                    setLoading(false);
                }))
        } else {
            api.establishments.getMulti().then((response) => {
                handleChange("code", response.count + 1);
            })
            api.districts.getMulti().then((response) => {
                setDistricts(response.results.map(({ id, name }) => {
                    return (
                        < option label={name} value={id} >{name}</option>
                    )
                })
                );
                setLoading(false)
            })
        }
    }, [])


    return (
        <div className="bg-gray-100 h-screen">
            <Sidebar currentScreen={'Establecimientos'} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="md:pl-64 flex flex-col flex-1">
                <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-100">
                    <button
                        type="button"
                        className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                        onClick={() => setSidebarOpen(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div className="flex flex-col flex-1 ml-6 mt-6">
                    {loading ?
                        <div className="flex h-screen items-center justify-center ">
                            <div className="w-16 h-16 border-b-2 border-blue-900 rounded-full animate-spin"></div>
                        </div>
                        :
                        <form onSubmit={handleSubmit}>
                            <div className="flex justify-between">
                                <Breadcrumbs pages={pages} />
                                <button
                                    type="submit"
                                    className="mr-8 items-center px-4 py-2 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-blue-600 hover:bg-blue-700"
                                >
                                    Guardar
                                </button>
                            </div>
                            <div className="flex flex-1 mt-12">
                                <div className="max-w-7xl flex-col w-screen items-center px-4">
                                    <div className="flex flex-wrap gap-x-8">
                                        <TextInput errorMessage={errors.name} handleChange={handleChange} value={establishment.name} name="name" label="Nombre" className="shadow-sm h-8 pl-2 w-48 border border-gray-300 block sm:text-sm rounded-md" />
                                        <SelectInput errorMessage={errors.district} placeholder={idEstablishment ? false : true} handleChange={handleChange} name="district" label="Distrito" className="shadow-sm h-9 w-48 border border-gray-300 block sm:text-sm rounded-md" options={districts} />
                                    </div>
                                    <div className="mt-6 flex flew-wrap gap-x-8" >
                                        <TextInput errorMessage={errors.latitude} handleChange={handleChange} value={establishment.latitude} name="latitude" label="Latitud" className="shadow-sm h-8 pl-2 w-48 border border-gray-300 block sm:text-sm rounded-md" />
                                        <TextInput errorMessage={errors.longitude} handleChange={handleChange} value={establishment.longitude} name="longitude" label="Longitud" className="shadow-sm h-8 pl-2 w-48 border border-gray-300 block sm:text-sm rounded-md" />
                                    </div>
                                </div>
                            </div>
                        </form>
                    }
                </div>
            </div>
        </div >
    );
}
