import React, { useEffect } from "react";
import { useState } from 'react'
import {
    CalendarIcon,
    ChartBarIcon,
    EyeIcon,
    LocationMarkerIcon,
    MenuAlt2Icon,
    OfficeBuildingIcon,
    UserCircleIcon,
    UserGroupIcon,
} from '@heroicons/react/outline'
import api from "../../api";
import { useParams, useNavigate } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import Breadcrumbs from "../components/Breadcrumbs";
import TextInput from "../components/TextInput";
import SelectInput from "../components/SelectInput";
import InlineCheckbox from "../components/InlineCheckbox";

const navigation = [
    { name: 'Pacientes', href: '/patients', icon: UserGroupIcon, current: false },
    { name: 'Reportes', href: '/reports', icon: ChartBarIcon, current: false },
    { name: 'Establecimientos', href: '/establishments', icon: OfficeBuildingIcon, current: false },
    { name: 'Usuarios', href: '/users', icon: UserCircleIcon, current: true },
    { name: 'Distritos', href: '/districts', icon: LocationMarkerIcon, current: false },
    // { name: 'Calendarios', href: '/calendars', icon: CalendarIcon, current: false },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function UserNewPage() {

    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [establishments, setEstablishments] = useState([])
    const navigate = useNavigate();
    const { idUser } = useParams()
    const [user, setUser] = useState({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        isStaff: false,
        profile: {
            govId: "",
            establishment: 0
        }
    })
    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        username: '',
        email: '',
        password: '',
        govId: '',
    });

    const handleChange = (field, value) => setUser((prev) => ({ ...prev, [field]: value }));
    const handleGovId = (field, value) => setUser((prev) => ({ ...prev, profile: { ...prev.profile, govId: value } }))
    const handleEstablishment = (field, value) => setUser((prev) => ({ ...prev, profile: { ...prev.profile, establishment: value } }))
    const handleError = (field, value) => setErrors((prev) => ({ ...prev, [field]: value }));

    const handleSubmit = (event) => {
        event.preventDefault()
        let error = false
        if (idUser) {
            api.users.update(idUser, user)
            navigate('/users')
        } else {
            if (!user.firstName) {
                handleError('firstName', 'Este campo es obligatorio')
                error = true
            } else {
                handleError('firstName', '')
            }
            if (!user.lastName) {
                handleError('lastName', 'Este campo es obligatorio')
                error = true
            } else {
                handleError('lastName', '')
            }
            if (!user.username) {
                handleError('username', 'Este campo es obligatorio')
                error = true
            } else {
                handleError('username', '')
            }
            if (!user.email) {
                handleError('email', 'Este campo es obligatorio')
                error = true
            } else {
                handleError('email', '')
            }
            if (!user.password) {
                handleError('password', 'Este campo es obligatorio')
                error = true
            } else {
                handleError('password', '')
            }
            if (!user.profile.govId) {
                handleError('govId', 'Este campo es obligatorio')
                error = true
            } else {
                handleError('govId', '')
            }
            if (user.isStaff) {
                delete user.profile.establishment
            }
            error = false
            api.users.create(user).catch(response => {
                for (var element in response.message) {
                    error = true
                    if (Object.prototype.hasOwnProperty.call(response.message, element)) {
                        if (response.message[element] != 'Este campo no puede estar en blanco.')
                            handleError(element, response.message[element])
                    }
                }
            }
            ).then(() => error ? null : navigate('/users'))
        }
    };

    useEffect(async () => {
        if (idUser) {
            await api.users.getOne(idUser).then((response) => {
                setUser({
                    firstName: response.firstName,
                    lastName: response.lastName,
                    username: response.username,
                    profile: response.profile,
                    email: response.email,
                    isActive: true,
                    isStaff: response.isStaff,
                    password: response.password,
                    establishment: response.establishment
                })
            })
            await api.establishments.getMulti().then((response) => {
                setEstablishments(response.results.map(({ id, name }) => {
                    return (
                        <option label={name} value={id} >{name}</option>
                    )
                })
                );
                setLoading(false)
            })
        } else {
            await api.establishments.getMulti().then((response) => {
                setEstablishments(response.results.map(({ id, name }) => {
                    return (
                        <option label={name} value={id} >{name}</option>
                    )
                })
                );
                setLoading(false)
            })
        }
    }, [])

    const pages = [
        { name: 'Usuarios', href: '/users', current: false },
        { name: idUser ? 'Detalle' : 'Nuevo usuario', href: `#`, current: true },
    ]

    return (
        <div className="bg-gray-100 h-screen">
            <Sidebar currentScreen={'Usuarios'} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="md:pl-64 flex flex-col flex-1">
                <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-100">
                    <button
                        type="button"
                        className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                        onClick={() => setSidebarOpen(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div className="flex flex-col flex-1 ml-6 mt-6">
                    {loading ?
                        <div className="flex h-screen items-center justify-center ">
                            <div className="w-16 h-16 border-b-2 border-blue-900 rounded-full animate-spin"></div>
                        </div>
                        :
                        <form onSubmit={(event) => handleSubmit(event)}>
                            <div className="flex justify-between">
                                <Breadcrumbs pages={pages} />
                                <button
                                    type="submit"
                                    className="mr-8 items-center px-4 py-2 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-blue-600 hover:bg-blue-700"
                                >
                                    Guardar
                                </button>
                            </div>
                            <div className="flex-col flex-1 mt-12 space-y-12 divide-y divide-gray-200">
                                <div className="max-w-7xl flex-col items-center px-4">
                                    <div className="flex flex-wrap gap-x-8">
                                        <TextInput errorMessage={errors.firstName} value={user.firstName} handleChange={handleChange} name="firstName" label="Nombre" />
                                        <TextInput errorMessage={errors.lastName} value={user.lastName} handleChange={handleChange} name="lastName" label="Apellido" />
                                        <TextInput errorMessage={errors.govId} value={user.profile?.govId} handleChange={handleGovId} name="govId" label="C.I." />
                                    </div>
                                    <div className="mt-6 flex gap-x-14" >
                                        <TextInput value={user.phone} handleChange={handleChange} name="phone" label="N° de teléfono" />
                                        <div className="mt-8">
                                            <InlineCheckbox checked={user.isStaff} handleChange={handleChange} name="isStaff" label="Administrador" />
                                        </div>
                                        <SelectInput value={user.profile?.establishment} placeholder={idUser ? false : true} disabled={user.isStaff ? true : false} className={user.isStaff ? "shadow-sm h-9 w-60 border bg-gray-200 border-gray-300 block sm:text-sm rounded-md" : "shadow-sm h-9 w-60 border border-gray-300 block sm:text-sm rounded-md"} handleChange={handleEstablishment} name="establishment" label="Establecimiento" options={establishments} />
                                    </div>
                                </div>
                                <div className="max-w-7xl items-center px-4">
                                    <div className="mt-6 flex flex-wrap gap-x-8">
                                        <TextInput errorMessage={errors.email} handleChange={handleChange} value={user.email} name="email" label="Correo electrónico" className="shadow-sm h-8 pl-2 w-48 border border-gray-300 block sm:text-sm rounded-md" />
                                        {/*<div className="mt-1">
                                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                                Contraseña
                                            </label>
                                            <div className="relative rounded-md shadow-sm">
                                                <input
                                                    type={visible ? "text" : "password"}
                                                    name="password"
                                                    onChange={(event) => handleChange("password", event.target.value)}
                                                    id="password"
                                                    className={errors.password ? "block w-full shadow-sm h-8 pl-2 mt-1 pr-10 border border-gray-300 sm:text-sm rounded-md" : "block w-full mt-1 h-8 pr-10 sm:text-sm border-gray-300 rounded-md"}
                                                />
                                                <button type="button" onClick={() => setVisible(!visible)} className="absolute inset-y-0 right-0 pr-3 flex items-center">
                                                    <EyeIcon className="h-5 w-5 text-gray-400 hover:text-blue-400" aria-hidden="true" />
                                                </button>
                                            </div>
                                            <p className="mt-2 text-sm text-red-600" id={`password-error`}>
                                                {errors.password}
                                            </p>
                                        </div>*/}
                                    </div>
                                </div>
                            </div>
                        </form>
                    }
                </div>
            </div>
        </div >
    );
}
