import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import App from './app';
import EstablishmentPage from './pages/establishments/EstablishmentPage';
import UserPage from './pages/users/UserPage';
import PatientPage from './pages/patients/PatientPage';
import LoginPage from './pages/LoginPage';
import DistrictPage from './pages/districts/DistrictPage';
import DistrictDetailPage from './pages/districts/DistrictDetailPage';
import PatientDetailPage from './pages/patients/PatientDetailPage';
import PatientProfilePage from './pages/patients/PatientProfilePage';
import PatientHistoryPage from './pages/patients/PatientHistoryPage';
import ConsultationDetailPage from './pages/patients/ConsultationDetailPage';
import ConsultationNewPage from './pages/patients/ConsultationNewPage';
import PatientNewPage from './pages/patients/PatientNewPage';
import ReportsPage from './pages/reports/ReportsPage';
import UserNewPage from './pages/users/UserNewPage';
import EstablishmentNewPage from './pages/establishments/EstablishmentNewPage';
import { initDB } from 'react-indexed-db';
import DataProvider from './data';
import { AuthProvider } from './context/authContext';

const DBConfig = {
  name: 'KoicaDB',
  version: 1,
  objectStoresMeta: [
    {
      store: 'place',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'name', keypath: 'name', options: { unique: false } }
      ]
    },
    {
      store: 'location',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'name', keypath: 'name', options: { unique: false } }
      ]
    },
    {
      store: 'establishment',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'code', keypath: 'code', options: { unique: true } },
        { name: 'department', keypath: 'department', options: { unique: false } },
        { name: 'name', keypath: 'name', options: { unique: false } },
        { name: 'district', keypath: 'district', options: { unique: false } },
        { name: 'longitude', keypath: 'longitude', options: { unique: false } },
        { name: 'latitude', keypath: 'latitude', options: { unique: false } }
      ]
    },
    {
      store: 'consultation',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'addSaltToFood', keypath: 'addSaltToFood', options: { unique: false } },
        { name: 'addSaltWhenCooking', keypath: 'addSaltWhenCooking', options: { unique: false } },
        { name: 'alcoholicBeveragesEducation', keypath: 'alcoholicBeveragesEducation', options: { unique: false } },
        { name: 'attentionDate', keypath: 'attentionDate', options: { unique: false } },
        { name: 'basalGlucose', keypath: 'basalGlucose', options: { unique: false } },
        { name: 'bloodGlucose', keypath: 'bloodGlucose', options: { unique: false } },
        { name: 'cigarettesPerDay', keypath: 'cigarettesPerDay', options: { unique: false } },
        { name: 'creatinine', keypath: 'creatinine', options: { unique: false } },
        { name: 'diabeticFoot', keypath: 'diabeticFoot', options: { unique: false } },
        { name: 'diabeticFootDerive', keypath: 'diabeticFootDerive', options: { unique: false } },
        { name: 'diabeticFootObs', keypath: 'diabeticFootObs', options: { unique: false } },
        { name: 'diabeticFootReferToLab', keypath: 'diabeticFootReferToLab', options: { unique: false } },
        { name: 'diastolic', keypath: 'diastolic', options: { unique: false } },
        { name: 'dmMedications', keypath: 'dmMedications', options: { unique: false } },
        { name: 'drinkAlcoholicBeverages', keypath: 'drinkAlcoholicBeverages', options: { unique: false } },
        { name: 'drinksPerDay', keypath: 'drinksPerDay', options: { unique: false } },
        { name: 'drinksPerWeek', keypath: 'drinksPerWeek', options: { unique: false } },
        { name: 'establishment', keypath: 'establishment', options: { unique: false } },
        { name: 'etcEducation', keypath: 'etcEducation', options: { unique: false } },
        { name: 'fruitServingsPerDay', keypath: 'fruitServingsPerDay', options: { unique: false } },
        { name: 'fundus', keypath: 'fundus', options: { unique: false } },
        { name: 'fundusDerive', keypath: 'fundusDerive', options: { unique: false } },
        { name: 'fundusObs', keypath: 'fundusObs', options: { unique: false } },
        { name: 'hasDmMedication', keypath: 'hasDmMedication', options: { unique: false } },
        { name: 'hasHtaMedication', keypath: 'hasHtaMedication', options: { unique: false } },
        { name: 'hba1c', keypath: 'hba1c', options: { unique: false } },
        { name: 'hdlCol', keypath: 'hdlCol', options: { unique: false } },
        { name: 'height', keypath: 'height', options: { unique: false } },
        { name: 'htaMedications', keypath: 'htaMedications', options: { unique: false } },
        { name: 'imc', keypath: 'imc', options: { unique: false } },
        { name: 'importanceDmMedication', keypath: 'importanceDmMedication', options: { unique: false } },
        { name: 'importanceHtaMedication', keypath: 'importanceHtaMedication', options: { unique: false } },
        { name: 'lastMeal', keypath: 'lastMeal', options: { unique: false } },
        { name: 'ldlCol', keypath: 'ldlCol', options: { unique: false } },
        { name: 'nextVisit', keypath: 'nextVisit', options: { unique: false } },
        { name: 'nutritionEducation', keypath: 'nutritionEducation', options: { unique: false } },
        { name: 'obsBloodGlucose', keypath: 'obsBloodGlucose', options: { unique: false } },
        { name: 'otherDmMedication', keypath: 'otherDmMedication', options: { unique: false } },
        { name: 'otherDmMedicationObs', keypath: 'otherDmMedicationObs', options: { unique: false } },
        { name: 'otherHtaMedication', keypath: 'otherHtaMedication', options: { unique: false } },
        { name: 'otherHtaMedicationObs', keypath: 'otherHtaMedicationObs', options: { unique: false } },
        { name: 'patient', keypath: 'patient', options: { unique: false } },
        { name: 'patientGov', keypath: 'patientGov', options: { unique: false } },
        { name: 'performPhysicalActivity', keypath: 'performPhysicalActivity', options: { unique: false } },
        { name: 'physicalActivityDuration', keypath: 'physicalActivityDuration', options: { unique: false } },
        { name: 'physicalActivityEducation', keypath: 'physicalActivityEducation', options: { unique: false } },
        { name: 'physicalActivityFrequency', keypath: 'physicalActivityFrequency', options: { unique: false } },
        { name: 'physicalActivityType', keypath: 'physicalActivityType', options: { unique: false } },
        { name: 'place', keypath: 'place', options: { unique: false } },
        { name: 'polidipsia', keypath: 'polidipsia', options: { unique: false } },
        { name: 'polifagia', keypath: 'polifagia', options: { unique: false } },
        { name: 'poliuria', keypath: 'poliuria', options: { unique: false } },
        { name: 'smoke', keypath: 'smoke', options: { unique: false } },
        { name: 'smokingEducation', keypath: 'smokingEducation', options: { unique: false } },
        { name: 'systolic', keypath: 'systolic', options: { unique: false } },
        { name: 'timeOfTheFirstCigarette', keypath: 'timeOfTheFirstCigarette', options: { unique: false } },
        { name: 'totalCholesterol', keypath: 'totalCholesterol', options: { unique: false } },
        { name: 'triglycerides', keypath: 'triglycerides', options: { unique: false } },
        { name: 'urineAnalysis', keypath: 'urineAnalysis', options: { unique: false } },
        { name: 'urineAnalysisObs', keypath: 'urineAnalysisObs', options: { unique: false } },
        { name: 'urineAnalysisReferToLab', keypath: 'urineAnalysisReferToLab', options: { unique: false } },
        { name: 'vape', keypath: 'vape', options: { unique: false } },
        { name: 'vegetableServingsPerDay', keypath: 'vegetableServingsPerDay', options: { unique: false } },
        { name: 'waist', keypath: 'waist', options: { unique: false } },
        { name: 'wantToQuitSmoking', keypath: 'wantToQuitSmoking', options: { unique: false } },
        { name: 'weight', keypath: 'weight', options: { unique: false } },
        { name: 'weightloss', keypath: 'weightloss', options: { unique: false } }
      ]
    },
    {
      store: 'patient',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'age', keypath: 'age', options: { unique: false } },
        { name: 'firstName', keypath: 'firstName', options: { unique: false } },
        { name: 'lastName', keypath: 'lastName', options: { unique: false } },
        { name: 'establishment', keypath: 'establishment', options: { unique: false } },
        { name: 'establishmentId', keypath: 'establishmentId', options: { unique: false } },
        { name: 'birthdate', keypath: 'birthdate', options: { unique: false } },
        { name: 'govId', keypath: 'govId', options: { unique: true } },
        { name: 'provisionalCode', keypath: 'provisionalCode', options: { unique: false } },
        { name: 'documentType', keypath: 'documentType', options: { unique: false } },
        { name: 'gender', keypath: 'gender', options: { unique: false } },
        { name: 'phone', keypath: 'phone', options: { unique: false } },
        { name: 'address', keypath: 'address', options: { unique: false } },
        { name: 'registrationDate', keypath: 'registrationDate', options: { unique: false } },
        { name: 'isDiabetic', keypath: 'isDiabetic', options: { unique: false } },
        { name: 'diabetesDiagnosisYear', keypath: 'diabetesDiagnosisYear', options: { unique: false } },
        { name: 'hasHta', keypath: 'hasHta', options: { unique: false } },
        { name: 'hasDiagnosisYear', keypath: 'hasDiagnosisYear', options: { unique: false } },
        { name: 'hasCancer', keypath: 'hasCancer', options: { unique: false } },
        { name: 'cancerDianosisYear', keypath: 'cancerDianosisYear', options: { unique: false } },
        { name: 'cancerType', keypath: 'cancerType', options: { unique: false } },
        { name: 'fatherDiabetes', keypath: 'fatherDiabetes', options: { unique: false } },
        { name: 'fatherHta', keypath: 'fatherHta', options: { unique: false } },
        { name: 'fatherCancer', keypath: 'fatherCancer', options: { unique: false } },
        { name: 'motherDiabetes', keypath: 'motherDiabetes', options: { unique: false } },
        { name: 'motherHta', keypath: 'motherHta', options: { unique: false } },
        { name: 'motherCancer', keypath: 'motherCancer', options: { unique: false } },
        { name: 'brotherHta', keypath: 'brotherHta', options: { unique: false } },
        { name: 'brotherCancer', keypath: 'brotherCancer', options: { unique: false } },
        { name: 'otherDiabetes', keypath: 'otherDiabetes', options: { unique: false } },
        { name: 'otherHta', keypath: 'otherHta', options: { unique: false } },
        { name: 'otherCancer', keypath: 'otherCancer', options: { unique: false } },
        { name: 'lastVisit', keypath: 'lastVisit', options: { unique: false } },
        { name: 'diabetesObs', keypath: 'diabetesObs', options: { unique: false } },
        { name: 'hasObs', keypath: 'htaObs', options: { unique: false } },
        { name: 'cancerObs', keypath: 'cancerObs', options: { unique: false } },
        { name: 'location', keypath: 'location', options: { unique: false } }
      ]
    }
  ]
};

initDB(DBConfig);

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <DataProvider>
        <App />
      </DataProvider>
    </AuthProvider>

  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorkerRegistration.register();

reportWebVitals();
