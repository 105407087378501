import React, { useEffect, Fragment, useContext } from "react";
import { useState } from 'react'
import { Link, useSearchParams } from "react-router-dom";
import {
    BanIcon,
    PlusIcon,
    ChevronDownIcon,
    XIcon
} from '@heroicons/react/outline'
import api from "../../api";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import Table from "../components/Table";
import InlineCheckbox from "../components/InlineCheckbox";
import SelectInput from "../components/SelectInput";
import DateInput from "../components/DateInput";
import RadioButtons from "../components/RadioButtons";
import Tag from "../components/Tag";
import { Dialog, Popover, Transition } from "@headlessui/react";
import { DataContext } from "../../data";

const columns = [
    { name: 'name', displayText: 'Nombre y Apellido', clickable: true },
    { name: 'age', displayText: 'Edad' },
    { name: 'dx', displayText: 'Diagnóstico' },
    { name: 'establishment', displayText: 'Establecimiento' },
    { name: 'lastVisit', displayText: 'Última visita' }
]

const ages = [
    10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
    20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
    30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
    40, 41, 42, 43, 44, 45, 46, 47, 48, 49,
    50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
    60, 61, 62, 63, 64, 65, 66, 67, 68, 69,
    70, 71, 72, 73, 74, 75, 76, 77, 78, 79,
    80, 81, 82, 83, 84, 85, 86, 87, 88, 89,
    90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
    101, 102, 103, 104, 105, 106, 107, 108, 109, 110
]

const imcs = [0, 16, 16.5, 17, 17.5, 18, 18.5, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 35, 40, 45, 50, 80]

const imcRanges = [
    { value: 'custom', label: 'Personalizado' },
    { value: 'bajopeso', label: 'Bajo Peso' },
    { value: 'normopeso', label: 'Normopeso' },
    { value: 'sobrepeso1', label: 'Sobrepeso grado 1' },
    { value: 'sobrepeso2', label: 'Sobrepeso grado 2' },
    { value: 'obesidad1', label: 'Obesidad tipo 1' },
    { value: 'obesidad2', label: 'Obesidad tipo 2' },
    { value: 'obesidad3', label: 'Obesidad tipo 3' },
    { value: 'obesidad4', label: 'Obesidad tipo 4' }
]

const sexOptions = [
    { id: "both", label: "Ambos", value: 2 },
    { id: "male", label: "Masculino", value: 0 },
    { id: "female", label: "Femenino", value: 1 }
]

const dxOptions = [
    { id: "both", label: "Todos", value: null },
    { id: "dm", label: "DM", value: 0 },
    { id: "hta", label: "HTA", value: 1 },
    { id: "dm+hta", label: "DM + HTA", value: 2 }
]

const EstablishmentPopover = function (props) {

    const [establishment, setEstablishment] = useState(props.establishment)

    return (
        <Popover className="relative">
            {({ open }) => (
                <>
                    <Popover.Button
                        className={classNames(
                            open ? 'text-gray-900' : 'text-gray-500',
                            'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                        )}
                    >
                        <span>USF</span>
                        <ChevronDownIcon
                            className={classNames(
                                open ? 'text-gray-600' : 'text-gray-400',
                                'ml-2 h-5 w-5 group-hover:text-gray-500'
                            )}
                            aria-hidden="true"
                        />
                    </Popover.Button>

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <Popover.Panel className="absolute -ml-4 mt-3 transform z-10 px-2 w-screen max-w-xs sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                    <SelectInput value={establishment} placeholder={true} name="establishment" handleChange={(field, value) => setEstablishment(value)} label="Establecimiento" options={props.establishments.map((element) => { return (<option value={element.id} label={element.name} >{element.name}</option>) })} className="focus:ring-blue-500 focus:border-blue-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md" />
                                </div>
                                <div className="px-5 py-5 bg-gray-50 space-y-6 justify-between sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                                    <Popover.Button
                                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm bg-gray-100 text-blue-600 hover:text-blue-800"
                                    >
                                        Cerrar
                                    </Popover.Button>
                                    <button onClick={() => props.handleChange(establishment)}>
                                        <Popover.Button
                                            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                        >
                                            Aceptar
                                        </Popover.Button>
                                    </button>
                                </div>
                            </div>
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    )
}

const FiltersModal = function (props) {

    const labelStyle = 'text-xs text-gray-500 font-medium';
    const buttonBaseStyle = 'inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm';
    const ageInputStyle = 'shadow-sm w-full border border-gray-300 block sm:text-sm rounded-md';
    const [fromDate, setFromDate] = useState(props.filterFromDate)
    const [toDate, setToDate] = useState(props.filterToDate)
    const [fromDateVisit, setFromDateVisit] = useState(props.filterFromDateVisit)
    const [toDateVisit, setToDateVisit] = useState(props.filterToDateVisit)
    const [selectedSex, setSelectedSex] = useState(props.filterSex)
    const [fromAge, setFromAge] = useState(props.filterMinAge)
    const [toAge, setToAge] = useState(props.filterMaxAge)
    const [fromImc, setFromImc] = useState(props.filterMinImc)
    const [toImc, setToImc] = useState(props.filterMaxImc)
    const [imcRange, setImcRange] = useState('custom')
    const [selectedDx, setSelectedDx] = useState(props.filterDxs)
    const [hasCancer, setHasCancer] = useState(props.filterHasCancer)
    const [familyDiabetes, setFamilyDiabetes] = useState(props.filterFamilyDiabetes)
    const [familyHta, setFamilyHta] = useState(props.filterFamilyHta)
    const [familyCancer, setFamilyCancer] = useState(props.filterFamilyCancer)
    let dateToday = new Date()
    dateToday = dateToday.toISOString().split('T')[0]

    const changeImcRange = function (rangeName) {
        switch (rangeName) {
            case 'bajopeso':
                setFromImc(0)
                setToImc(18.5)
                break;
            case 'normopeso':
                setFromImc(18.5)
                setToImc(25)
                break;
            case 'sobrepeso1':
                setFromImc(25)
                setToImc(27)
                break;
            case 'sobrepeso2':
                setFromImc(27)
                setToImc(30)
                break;
            case 'obesidad1':
                setFromImc(30)
                setToImc(35)
                break;
            case 'obesidad2':
                setFromImc(35)
                setToImc(40)
                break;
            case 'obesidad3':
                setFromImc(40)
                setToImc(50)
                break;
            case 'obesidad4':
                setFromImc(50)
                setToImc(80)
                break;
            default:
                console.log('custom')
        }
        setImcRange(rangeName)
    }

    return <Transition.Root show={props.openModal}>
        <Dialog as="div" className="fixed z-30 inset-0 overflow-y-auto" open={props.openModal} onClose={props.setOpenModal}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-2 md:inset-4 lg:inset-8 max-w-full flex">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-200"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="bg-white w-full flex flex-col flex-1 rounded-lg shadow-lg">
                            {/* Header */}
                            <div className="px-5 py-3 bg-gray-50 rounded-t-lg">
                                <div className="flex items-start justify-between space-x-3">
                                    <div className="text-lg font-medium text-blue-500">Filtros</div>
                                    <div className="h-7 flex items-center">
                                        <button
                                            type="button"
                                            className="text-blue-500 hover:text-blue-900"
                                            onClick={() => props.setOpenModal(false)}
                                        >
                                            <XIcon className="h-6 w-6" aria-hidden="true" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="flex-1 overflow-y-auto">
                                {/* Divider container */}
                                <div className="py-3 px-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-4 gap-y-10 overflow-y-auto">
                                    <div className="text-left">
                                        <div className="font-bold mb-2 text-gray-700">Fecha de registro</div>
                                        <DateInput handleChange={(field, value) => setFromDate(value)} value={fromDate} maxdate={toDate || dateToday} label={'Desde'} labelClassName={labelStyle} containerStyle={'max-w-xs mb-2'} />
                                        <DateInput handleChange={(field, value) => setToDate(value)} mindate={fromDate} maxdate={dateToday} value={toDate} label={'Hasta'} labelClassName={labelStyle} containerStyle={'max-w-xs'} />
                                    </div>
                                    <div className="text-left">
                                        <div className="font-bold mb-9 text-gray-700">Sexo</div>
                                        <RadioButtons
                                            optionsContainerStyle={'space-y-5'}
                                            handleChange={(label, value) => setSelectedSex(value)}
                                            name="sex"
                                            value={selectedSex}
                                            defaultValue={selectedSex}
                                            options={sexOptions}
                                        />
                                    </div>
                                    <div className="text-left">
                                        <div className="font-bold mb-9 text-gray-700">Diagnóstico</div>
                                        <div className="space-y-5">
                                            <SelectInput
                                                options={dxOptions.map(r => <option key={r.value} value={r.value}>{r.label}</option>)}
                                                className={ageInputStyle}
                                                handleChange={(name, value) => setSelectedDx(+value)}
                                                value={selectedDx}
                                                labelClassName={labelStyle}
                                                containerStyle={'w-1/2'}
                                            />
                                            <InlineCheckbox checked={hasCancer} handleChange={(field, value) => setHasCancer(value)} name="hasCancer" label="Cáncer" />
                                        </div>
                                    </div>
                                    {props.isConnected && <div className="text-left">
                                        <div className="font-bold mb-3 text-gray-700">Índice de masa corporal</div>
                                        <div className="flex space-x-2 max-w-xs mb-5">
                                            <SelectInput
                                                options={imcs.map(imc => <option key={imc} value={imc === 80 ? 51 : imc}>
                                                    {imc === 0 ? 'Menos de 16' : (imc === 80 ? 'Más de 50' : imc)}
                                                </option>)}
                                                className={ageInputStyle}
                                                handleChange={(name, value) => setFromImc(+value)}
                                                value={fromImc}
                                                label={'Desde'}
                                                labelClassName={labelStyle}
                                                containerStyle={'w-1/2'}
                                            />
                                            <SelectInput
                                                options={imcs.filter(imc => imc >= fromImc).map(imc => <option key={imc} value={imc === 0 ? 15 : imc}>
                                                    {imc === 0 ? 'Menos de 16' : (imc === 80 ? 'Más de 50' : imc)}
                                                </option>)}
                                                className={ageInputStyle}
                                                handleChange={(name, value) => setToImc(+value)}
                                                value={toImc}
                                                label={'Hasta'}
                                                labelClassName={labelStyle}
                                                containerStyle={'w-1/2'}
                                            />
                                        </div>
                                        {<SelectInput
                                            options={imcRanges.map(r => <option key={r.value} value={r.value}>{r.label}</option>)}
                                            className={ageInputStyle}
                                            handleChange={(name, value) => changeImcRange(value)}
                                            value={imcRange}
                                            containerStyle={'max-w-xs'}
                                        />}
                                    </div>}
                                    <div className="text-left">
                                        <div className="font-bold mb-2 text-gray-700">Fecha de última visita</div>
                                        <DateInput handleChange={(field, value) => setFromDateVisit(value)} value={fromDateVisit} maxdate={toDateVisit || dateToday} label={'Desde'} labelClassName={labelStyle} containerStyle={'max-w-xs mb-2'} />
                                        <DateInput handleChange={(field, value) => setToDateVisit(value)} mindate={fromDateVisit} maxdate={dateToday} value={toDateVisit} label={'Hasta'} labelClassName={labelStyle} containerStyle={'max-w-xs'} />
                                    </div>
                                    <div className="text-left">
                                        <div className="font-bold mb-2 text-gray-700">Edad</div>
                                        <div className="flex space-x-2 max-w-xs">
                                            <SelectInput
                                                options={ages.map(a => <option key={`age-${a}`} value={a}>{a} años</option>)}
                                                className={ageInputStyle}
                                                handleChange={(field, value) => setFromAge(+value)}
                                                value={fromAge}
                                                label={'Desde'}
                                                labelClassName={labelStyle}
                                                containerStyle={'w-1/2'}
                                            />
                                            <SelectInput
                                                options={ages.filter(a => a >= fromAge).map(a => <option key={`age-${a}`} value={a}>{a} años</option>)}
                                                className={ageInputStyle}
                                                handleChange={(field, value) => setToAge(+value)}
                                                value={toAge}
                                                label={'Hasta'}
                                                labelClassName={labelStyle}
                                                containerStyle={'w-1/2'}
                                            />
                                        </div>
                                    </div>
                                    <div className="text-left">
                                        <div className="font-bold mb-5 text-gray-700">Antecedentes familiares</div>
                                        <div className="space-y-5">
                                            <InlineCheckbox checked={familyDiabetes} handleChange={(field, value) => setFamilyDiabetes(value)} name="dm" label="Diabetes" />
                                            <InlineCheckbox checked={familyHta} handleChange={(field, value) => setFamilyHta(value)} name="hta" label="HTA" />
                                            <InlineCheckbox checked={familyCancer} handleChange={(field, value) => setFamilyCancer(value)} name="cancer" label="Cáncer" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Action buttons */}
                            <div className="flex-shrink-0 px-4 py-3 border-t border-gray-200">
                                <div className="space-x-3 flex justify-end">
                                    {/*<button
                                        type="button"
                                        onClick={() => {
                                            setFromAge(10);
                                            setToAge(99);
                                            setFromDate('2018-01-01')
                                            setToDate(dateToday)
                                            setSelectedSex('')
                                            setSelectedDx('')
                                            setHasCancer(false)
                                            setFamilyDiabetes(false)
                                            setFamilyHta(false)
                                            setFamilyCancer(false)
                                            setImcRange('custom')
                                            setFromImc(0)
                                            setToImc(80)
                                        }}
                                        className={`${buttonBaseStyle} text-gray-700 bg-white border-gray-300 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700`}
                                    >
                                        Borrar filtros
                                    </button>*/}
                                    <button
                                        type="button"
                                        onClick={() => {
                                            props.setAllFilters(
                                                {
                                                    age_min: fromAge,
                                                    age_max: toAge,
                                                    gender: selectedSex,
                                                    has_cancer: hasCancer,
                                                    dx: selectedDx,
                                                    family_diabetes: familyDiabetes,
                                                    family_hta: familyHta,
                                                    family_cancer: familyCancer,
                                                    imc_min: fromImc,
                                                    imc_max: toImc,
                                                    registration_date_min: fromDate,
                                                    registration_date_max: toDate,
                                                    last_visit_date_max: toDateVisit,
                                                    las_visit_date_min: fromDateVisit
                                                })
                                            props.setOpenModal(false);
                                        }}
                                        className={`${buttonBaseStyle} text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
                                    >
                                        Aplicar filtro
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
    </Transition.Root>
}


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function PatientPage() {

    const [gettingUserEstablishment, setGettingUserEstablishment] = useState(true)
    const [patientsError, setPatientsError] = useState('')

    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [thePatients, setThePatients] = useState([])
    const [theEstablishments, setTheEstablishments] = useState([])
    const [search, setSearch] = useState("")
    const [loading, setLoading] = useState(true)
    const [total, setTotal] = useState(0)
    const [searchParams, setSearchParams] = useSearchParams()
    let dateToday = new Date()
    dateToday = dateToday.toISOString().split('T')[0]
    const [openModal, setOpenModal] = useState(false)
    const [filterFromDate, setFilterFromDate] = useState(searchParams.get('registration_date_min') ? searchParams.get('registration_date_min') : '')
    const [filterToDate, setFilterToDate] = useState(searchParams.get('registration_date_max') ? searchParams.get('registration_date_max') : dateToday)
    const [filterFromDateVisit, setFilterFromDateVisit] = useState(searchParams.get('las_visit_date_min') ? searchParams.get('las_visit_date_min') : '')
    const [filterToDateVisit, setFilterToDateVisit] = useState(searchParams.get('last_visit_date_max') ? searchParams.get('last_visit_date_max') : '')
    const [filterSex, setFilterSex] = useState(searchParams.get('gender') ? searchParams.get('gender') : 2)
    const [filterMinImc, setFilterMinImc] = useState(searchParams.get('imc_min') ? searchParams.get('imc_min') : 0)
    const [filterMaxImc, setFilterMaxImc] = useState(searchParams.get('imc_max') ? searchParams.get('imc_max') : 80)
    const [filterMinAge, setFilterMinAge] = useState(searchParams.get('age_min') ? searchParams.get('age_min') : 10)
    const [filterMaxAge, setFilterMaxAge] = useState(searchParams.get('age_max') ? searchParams.get('age_max') : 110)
    const [filterEstablishment, setFilterEstablishment] = useState(searchParams.get('establishment') ? searchParams.get('establishment') : '')
    const [filterHasCancer, setFilterHasCancer] = useState(searchParams.get('has_cancer') ? searchParams.get('has_cancer') : false)
    const [filterDxs, setFilterDxs] = useState(searchParams.get('dx') ? searchParams.get('dx') : '')
    const [filterFamilyDiabetes, setFilterFamilyDiabetes] = useState(searchParams.get('family_diabetes') ? searchParams.get('family_diabetes') : false)
    const [filterFamilyHta, setFilterFamilyHta] = useState(searchParams.get('family_hta') ? searchParams.get('family_hta') : false)
    const [filterFamilyCancer, setFilterFamilyCancer] = useState(searchParams.get('family_cancer') ? searchParams.get('family_cancer') : false)
    const { isConnected, loadingEstablishments, establishments, filterPatients, loadingPatients } = useContext(DataContext)

    // getting initial data
    useEffect(() => {
        if (isConnected) {
            api.users.getMe().then((response) => {
                localStorage.setItem("fullName", response.firstName + " " + response.lastName)
                response.profile?.establishment ? localStorage.setItem("establishment", response.profile.establishment) : localStorage.setItem("establishment", '')
                if (response.profile?.establishment && filterEstablishment === '') {
                    setFilterEstablishment(response.profile?.establishment);
                    setEstablishment(response.profile?.establishment);
                }
                setGettingUserEstablishment(false)
            }, err => {
                console.log('error al traer usuario: ', err)
                setGettingUserEstablishment(false)
            })
            api.establishments.getMulti().then((response) => {
                setTheEstablishments([{ id: 0, name: 'Todos' }, ...response.results]);
            }, err => {
                console.log('Error al traer los establecimiento: ', err.response?.data)
            })
        }
    }, [])

    // getting establishment if not connection
    useEffect(() => {
        if (!loadingEstablishments && !isConnected) {
            const est = localStorage.getItem('establishment')
            if (est && filterEstablishment === '') {
                setFilterEstablishment(est);
                setEstablishment(est);
            }
            setGettingUserEstablishment(false)
            setTheEstablishments([{ id: 0, name: 'Todos' }, ...establishments])
        }
    }, [loadingEstablishments, establishments, isConnected])

    const searchPatient = (filters) => {
        setLoading(true)
        setPatientsError('')
        if (isConnected) { // searching with api if there is connection
            api.patients.getMulti(null, search, null, null, filters).then((response) => {
                setTotal(response.count)
                setThePatients(response.results.map(({ firstName, lastName, establishment, age, id, isDiabetic, hasHta, hasCancer, lastVisit }) => {
                    return ({
                        name: firstName + " " + lastName, age: age, establishment: establishment, id: id, lastVisit: lastVisit, dx: [isDiabetic ? <span className="ml-4 inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800">
                            DM
                        </span>
                            : null, hasHta ? <span className="ml-4 inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-yellow-100 text-yellow-800">
                                HTA
                            </span>
                            : null, hasCancer ? <span className="ml-4 inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-pink-100 text-pink-800">
                                Cáncer
                            </span>
                            : null],
                    })
                })
                );
                setLoading(false)
            }, err => {
                console.log('Error al traer/filtrar pacientes: ', err.response?.data)
                setPatientsError('Hubo un error al traer los filtros, inténtalo más tarde')
                setLoading(false)
            })
        } else if (!loadingPatients) { // not internet connection, searching in local bd
            let offlineFilters = { // misstyped in backend
                ...filters,
                last_visit_date_min: filters.las_visit_date_min
            }
            filterPatients(function (response) { // on success4
                setTotal(response.count)
                setThePatients(response.results.map(({ firstName, lastName, establishment, age, id, isDiabetic, lastVisit, hasHta, hasCancer }) => {
                    return ({
                        name: firstName + " " + lastName, age: age, establishment: establishment, lastVisit: lastVisit, id: id, dx: [isDiabetic ? <span className="ml-4 inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800">
                            DM
                        </span>
                            : null, hasHta ? <span className="ml-4 inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-yellow-100 text-yellow-800">
                                HTA
                            </span>
                            : null, hasCancer ? <span className="ml-4 inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-pink-100 text-pink-800">
                                Cancer
                            </span>
                            : null],
                    })
                })
                );
                setLoading(false)
            }, function (error) { // on error
                console.log('Error al obtener los pacientes filtrados: ', error)
                setPatientsError('Hubo un error al traer los filtros, inténtalo más tarde')
                setLoading(false)
            }, null, search, null, null, offlineFilters)
        }
    }

    const setEstablishment = function (establishment) {
        let currentParams = Object.fromEntries([...searchParams])
        if (+establishment === 0) {
            delete currentParams.establishment
        } else {
            currentParams = { ...currentParams, establishment: establishment }
        }
        setSearchParams(currentParams)
    }

    const setAllFilters = function (filters) {
        const currentParams = Object.fromEntries([...searchParams])
        if (!filters.has_cancer) {
            filters.has_cancer = ''
        }
        if (!filters.family_diabetes) {
            filters.family_diabetes = ''
        }
        if (!filters.family_hta) {
            filters.family_hta = ''
        }
        if (!filters.family_cancer) {
            filters.family_cancer = ''
        }
        console.log(filters)
        console.log({ ...currentParams, ...filters })
        setSearchParams({ ...currentParams, ...filters })
    }

    const setSpecificFilter = function (filters) { // filters just have 1 or 2 attributes
        const currentParams = Object.fromEntries([...searchParams])
        console.log(filters)
        console.log({ ...currentParams, ...filters })
        setSearchParams({ ...currentParams, ...filters })
    }

    const getAndFilterPatients = function () {
        const minDate = searchParams.get('registration_date_min')
        const maxDate = searchParams.get('registration_date_max')
        const minDateVisit = searchParams.get('las_visit_date_min')
        const maxDateVisit = searchParams.get('last_visit_date_max')
        const minAge = searchParams.get('age_min')
        const maxAge = searchParams.get('age_max')
        const minImc = searchParams.get('imc_min')
        const maxImc = searchParams.get('imc_max')
        const sex = searchParams.get('gender')
        const establishment = searchParams.get('establishment')
        const dxs = searchParams.get('dx')
        const hasCancer = searchParams.get('has_cancer')
        const familyDiabetes = searchParams.get('family_diabetes')
        const familyHta = searchParams.get('family_hta')
        const familyCancer = searchParams.get('family_cancer')

        let filters = {}

        if (minDate) {
            setFilterFromDate(minDate)
            filters = { ...filters, registration_date_min: minDate }
        } else {
            setFilterFromDate(null)
            delete filters.registration_date_min
        }
        if (maxDate) {
            setFilterToDate(maxDate)
            filters = { ...filters, registration_date_max: maxDate }
        } else {
            setFilterToDate(dateToday)
            delete filters.registration_date_max
        }
        if (minDateVisit) {
            setFilterFromDateVisit(minDateVisit)
            filters = { ...filters, las_visit_date_min: minDateVisit }
        } else {
            setFilterFromDateVisit(null)
            delete filters.las_visit_date_min
        }
        if (maxDateVisit) {
            setFilterToDateVisit(maxDateVisit)
            filters = { ...filters, last_visit_date_max: maxDateVisit }
        } else {
            setFilterToDateVisit(dateToday)
            delete filters.last_visit_date_max
        }
        if (minAge) {
            setFilterMinAge(minAge)
            filters = { ...filters, age_min: minAge }
        }
        if (minAge == 10) {
            delete filters.age_min
        }
        if (maxAge) {
            setFilterMaxAge(maxAge)
            filters = { ...filters, age_max: maxAge }
        }
        if (maxAge == 110) {
            delete filters.age_max
        }
        if (minImc) {
            setFilterMinImc(minImc)
            filters = { ...filters, imc_min: minImc }
        }
        if (maxImc) {
            setFilterMaxImc(maxImc)
            filters = { ...filters, imc_max: maxImc }
        }
        if (minImc == '0' && maxImc == '80') {
            delete filters.imc_min
            delete filters.imc_max
            setFilterMinImc('')
            setFilterMaxImc('')
        }
        if (sex) {
            setFilterSex(sex)
            filters = { ...filters, gender: sex != 2 ? sex : '' }
        }
        if (establishment && establishment !== '0') {

            setFilterEstablishment(establishment)
            filters = { ...filters, establishment: establishment }

        } else {
            setFilterEstablishment('')
            delete filters.establishment
        }
        if (dxs) {
            if (isNaN(dxs)) {
                delete filters.dx
                setFilterDxs('')
            } else {
                setFilterDxs(dxs)
                filters = { ...filters, dx: dxs }
            }

        }
        if (hasCancer) {
            setFilterHasCancer(hasCancer)
            filters = { ...filters, has_cancer: hasCancer }
        } else {
            setFilterHasCancer(false)
        }
        if (familyDiabetes) {
            setFilterFamilyDiabetes(familyDiabetes)
            filters = { ...filters, family_diabetes: familyDiabetes }
        } else {
            setFilterFamilyDiabetes(false)
        }
        if (familyHta) {
            setFilterFamilyHta(familyHta)
            filters = { ...filters, family_hta: familyHta }
        } else {
            setFilterFamilyHta(false)
        }
        if (familyCancer) {
            setFilterFamilyCancer(familyCancer)
            filters = { ...filters, family_cancer: familyCancer }
        } else {
            setFilterFamilyCancer(false)
        }
        searchPatient(filters)
    }

    // getting patients from api every time filters changes
    useEffect(() => {
        if (isConnected && !gettingUserEstablishment) {
            getAndFilterPatients()
        }
    }, [searchParams, gettingUserEstablishment])

    // getting patients from db every time filters changes, if patients are loaded
    useEffect(() => {
        if (!isConnected && !loadingPatients && !gettingUserEstablishment) {
            getAndFilterPatients()
        }
    }, [searchParams, loadingPatients, gettingUserEstablishment])

    return (
        <div className="bg-gray-100 h-screen">
            <Sidebar currentScreen={'Pacientes'} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="md:pl-64 flex flex-col h-screen flex-1">

                <FiltersModal
                    isConnected={isConnected}
                    openModal={openModal}
                    filterFromDate={filterFromDate}
                    filterToDate={filterToDate}
                    filterFromDateVisit={filterFromDateVisit}
                    filterToDateVisit={filterToDateVisit}
                    filterSex={filterSex}
                    filterMinImc={filterMinImc}
                    filterMaxImc={filterMaxImc}
                    filterMinAge={filterMinAge}
                    filterMaxAge={filterMaxAge}
                    filterDxs={filterDxs}
                    filterHasCancer={filterHasCancer}
                    filterFamilyDiabetes={filterFamilyDiabetes}
                    filterFamilyHta={filterFamilyHta}
                    filterFamilyCancer={filterFamilyCancer}
                    setOpenModal={setOpenModal}
                    setAllFilters={setAllFilters}
                />
                {loading ?
                    <div className="flex h-full items-center justify-center ">
                        <div className="w-16 h-16 border-b-2 border-blue-900 rounded-full animate-spin"></div>
                    </div>
                    :
                    <>
                        <div className="bg-gray-100 z-10">
                            <Header filters={setOpenModal} Popover={<EstablishmentPopover establishment={filterEstablishment} establishments={theEstablishments} handleChange={setEstablishment} />} value={search} setSearch={setSearch} search={getAndFilterPatients} setSidebarOpen={setSidebarOpen} searchPlaceholder="Buscar por nombre o número de documento." buttonText={thePatients.length ? "Nuevo paciente" : null} href="/patients/new" />
                            <div className="flex flex-wrap w-auto gap-x-3 gap-y-4 px-7 py-4">
                                {filterEstablishment && theEstablishments.length > 0 ? <Tag text={theEstablishments[parseInt(filterEstablishment)].name} handleClose={() => setEstablishment('')} /> : null}
                                {(filterSex && filterSex != 2) ? <Tag text={`Sexo: ${sexOptions.find((element) => element.value == filterSex).label}`} handleClose={() => setSpecificFilter({ gender: 2 })} /> : null}
                                {(filterMinAge && filterMinAge != 10) ? <Tag text={`Edad Mínima: ${filterMinAge}`} handleClose={() => setSpecificFilter({ age_min: 10 })} /> : null}
                                {(filterMaxAge && filterMaxAge != 110) ? <Tag text={`Edad Máxima: ${filterMaxAge}`} handleClose={() => setSpecificFilter({ age_max: 110 })} /> : null}
                                {(filterMinImc && filterMinImc != 0) ? <Tag text={`IMC Mínimo: ${filterMinImc}`} handleClose={() => setSpecificFilter({ imc_min: 0 })} /> : null}
                                {(filterMaxImc && filterMaxImc != 80) ? <Tag text={`IMC Máximo: ${filterMaxImc}`} handleClose={() => setSpecificFilter({ imc_max: 80 })} /> : null}
                                {filterDxs !== '' && +filterDxs === 0 ? <Tag text="DM" handleClose={() => setSpecificFilter({ dx: NaN })} /> : null}
                                {filterDxs == 1 ? <Tag text="HTA" handleClose={() => setSpecificFilter({ dx: NaN })} /> : null}
                                {filterDxs == 2 ? <Tag text="DM + HTA" handleClose={() => setSpecificFilter({ dx: NaN })} /> : null}
                                {filterHasCancer === 'true' ? <Tag text="Cáncer" handleClose={() => setSpecificFilter({ has_cancer: '' })} /> : null}
                                {filterFamilyDiabetes ? <Tag text="Familiares con DM" handleClose={() => setSpecificFilter({ family_diabetes: '' })} /> : null}
                                {filterFamilyHta ? <Tag text="Familiares con HTA" handleClose={() => setSpecificFilter({ family_hta: '' })} /> : null}
                                {filterFamilyCancer ? <Tag text="Familiares con Cáncer" handleClose={() => setSpecificFilter({ family_cancer: '' })} /> : null}
                                {filterFromDate ? <Tag text={`Fecha de registro desde: ${filterFromDate}`} handleClose={() => setSpecificFilter({ registration_date_min: '' })} /> : null}
                                {(filterToDate && filterToDate != dateToday) ? <Tag text={`Fecha de registro hasta: ${filterToDate}`} handleClose={() => setSpecificFilter({ registration_date_max: '' })} /> : null}
                                {filterFromDateVisit ? <Tag text={`Última visita desde: ${filterFromDateVisit}`} handleClose={() => setSpecificFilter({ las_visit_date_min: '' })} /> : null}
                                {(filterToDateVisit && filterToDateVisit != dateToday) ? <Tag text={`Última visita hasta: ${filterToDateVisit}`} handleClose={() => setSpecificFilter({ last_visit_date_max: '' })} /> : null}
                            </div>
                            {patientsError && <div className="mb-3 mx-10 text-sm text-red-600">
                                {patientsError}
                            </div>}
                        </div>

                        <main className="flex-1 bg-gray-100 overflow-y-auto">
                            {thePatients.length ?
                                <div className="pt-4 pb-10">
                                    <div className="max-w-7xl mx-auto ml-2 px-4 mb-4 sm:px-6 md:px-8">
                                        <h1 className="text-2xl font-bold leading-9 text-gray-600">Total de pacientes registrados: {total}</h1>
                                    </div>
                                    <div className="px-4 sm:px-6 md:px-8">
                                        <Table module="patients" actiontext="Ver" columns={columns} rows={thePatients} />
                                    </div>
                                </div>
                                :
                                <div className="flex h-full">
                                    <div className="mx-auto my-auto text-center">
                                        <BanIcon className="h-12 w-12 mx-auto text-gray-400" aria-hidden="true" />
                                        <h3 className="mt-2 text-sm font-semibold text-gray-900">No se encontraron resultados</h3>
                                        <p className="mt-1 text-sm font-semibold text-gray-500">Probá con otros datos o registrá un nuevo paciente.</p>
                                        <div className="mt-6">
                                            <Link
                                                to="/patients/new"
                                                type="button"
                                                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-blue-600 hover:bg-blue-700"
                                            >
                                                <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                                Nuevo paciente
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            }
                        </main>
                    </>
                }
            </div>
        </div >
    );
}
