import React, { useState, createContext, useEffect, useContext } from 'react';
import { useIndexedDB } from 'react-indexed-db';
import api from './api';
import { useAuth } from './context/authContext';
import { toast } from 'react-toastify';

const DataContext = createContext()

let syncProgress = 0 // amount of tables syncronized, there are 5 tables in total

function DataProvider(props) {

    // auth
    const { isAuthenticated } = useAuth()

    // data (all objects)
    const [places, setPlaces] = useState([])
    const [loadingPlaces, setLoadingPlaces] = useState(true)

    const [locations, setLocations] = useState([])
    const [loadingLocations, setLoadingLocations] = useState(true)

    const [establishments, setEstablishments] = useState([])
    const [loadingEstablishments, setLoadingEstablishments] = useState(true)

    const [patients, setPatients] = useState([])
    const [loadingPatients, setLoadingPatients] = useState(true)

    const [consultations, setConsultations] = useState([])
    const [loadingConsultations, setLoadingConsultations] = useState(true)

    // internet connection status
    const [isConnected, setIsConnected] = useState(navigator.onLine)

    // databases
    const placeTable = useIndexedDB('place');
    const locationTable = useIndexedDB('location');
    const establishmentTable = useIndexedDB('establishment');
    const patientTable = useIndexedDB('patient');
    const consultationTable = useIndexedDB('consultation');

    // array of pending operations for api to make when connection has recovered
    const [pendingOperations, setPendingOperations] = useState([])

    const addToPendingOperations = function (data, table, operation) {
        let pendingArray = pendingOperations.slice(0) // copying
        pendingArray.push({
            table: table,
            method: operation,
            body: data
        })
        setPendingOperations(pendingArray)
        // updating local storage
        localStorage.setItem('koica_pending_operations', JSON.stringify(pendingArray))
    }

    // updating local db
    const updateDB = function (db, rows) {
        db.clear().then(() => {
            let operationsCounter = 0
            for (let row of rows) {
                db.add(row).then(() => {
                    operationsCounter = operationsCounter + 1
                    if (operationsCounter === rows.length) {
                        syncProgress = syncProgress + 1
                        console.log(`table sync complete ${syncProgress}/5`)
                        if (syncProgress === 5) {
                            // toast for sync complete
                            toast('Sincronización de datos finalizada de forma exitosa.')
                        }
                    }
                })
            }
        })
    }

    // getting data from local db
    const getFromDB = function (db, setTableData, setLoadingTableData) {
        db.getAll().then(tableData => {
            setTableData(tableData)
            setLoadingTableData(false)
            console.log('data from db: ', tableData)
        })
    }

    // this should be used ONLY on offline mode
    const getPatientById = function (patientId) {
        return patients.find(p => +p.id === +patientId)
    }

    const addPatient = function (data, successFunction, errorFunction) {
        if (isConnected) { // connected
            api.patients.create(data).then(res => {
                patientTable.add(res).then(event => {
                    let newPatients = patients.slice(0)
                    newPatients.push(res)
                    setPatients(newPatients)
                    successFunction(res.id)
                }, err => {
                    console.log('error al guardar el paciente en la bd local: ', err)
                    successFunction(res.id) // success on saving patient on api but error on local db
                })
            }, err => {
                let errorMsg = ''
                if (err.message) {
                    for (let e in err.message) {
                        errorMsg = `${errorMsg} ${err.message[e]}`
                    }
                } else {
                    errorMsg = JSON.stringify(err)
                }
                errorFunction(`Error al registrar paciente: ${errorMsg}`)
            })
        } else { // not connection
            patientTable.add(data).then(event => {
                // add data to pending operations for api
                let rawPatient = {
                    ...data,
                    establishment: data.establishmentId
                }
                delete rawPatient.establishmentId
                addToPendingOperations(rawPatient, 'patient', 'create')

                // saving patient on db
                let newPatients = patients.slice(0)
                newPatients.push({
                    id: event,
                    ...data
                })
                setPatients(newPatients)
                successFunction(event)
            }, err => {
                errorFunction(`Error al registrar paciente en la bd local: ${JSON.stringify(err)}`)
            })
        }
    }

    const editPatient = function (id, data, successFunction, errorFunction) {
        let newPatients = patients
        if (isConnected) { // connected
            api.patients.update(+id, data).then(res => { // editing on api
                patientTable.update(res).then(event => { // editing on db
                    const indx = newPatients?.findIndex(p => +p.id === +id)
                    if (indx >= 0) {
                        // updating data in memory db
                        newPatients[indx] = res
                        setPatients(newPatients)
                        successFunction()
                    } else {
                        console.log('error al guardar los cambios del paciente en la bd local')
                        successFunction()
                    }
                }, err => {
                    console.log('error al guardar los cambios del paciente en la bd local: ', err)
                    successFunction()
                })
            }, err => {
                let errorMsg = ''
                if (err.message) {
                    for (let e in err.message) {
                        errorMsg = `${errorMsg} ${err.message[e]}`
                    }
                } else {
                    errorMsg = JSON.stringify(err)
                }
                errorFunction(`Error al editar paciente:  ${errorMsg}`)
            })
        } else { // not connection
            // getting whole current patient element
            const currentPatient = newPatients.find(p => +p.id === +id)
            const newCurrentPatient = {
                ...currentPatient,
                ...data
            }
            patientTable.update(newCurrentPatient).then(event => {
                // add data to pending operations for api
                addToPendingOperations({
                    id: +id,
                    ...data
                }, 'patient', 'edit')

                // editing patient on db
                const indx = newPatients.findIndex(p => +p.id === +id)
                if (indx >= 0) {
                    newPatients[indx] = newCurrentPatient
                    setPatients(newPatients)
                    successFunction()
                } else {
                    errorFunction('error al guardar los cambios del paciente en la bd')
                }
            }, err => {
                errorFunction(`Error al guardar los cambios del paciente en la bd local: ${JSON.stringify(err)}`)
            })
        }
    }

    const deletePatient = function (id, setter, successFunction, errorFunction) {

    }

    // (it can be use online as well, but i rather use it just for offline)
    const filterPatients = function (successFunction, errorFunction, ordering, search, limit, offset, extra) {
        // getting filtered data from in memory data (state variables)
        const getFilteredPatientsFromData = function () {
            let filteredData = patients
            // filtering by name search
            if (search && search.length > 0) {
                const searchByWords = search.toLowerCase().split(' ').filter(w => w !== '')
                filteredData = filteredData.filter(d => {
                    const fullNameAndCi = `${d.firstName} ${d.lastName} ${d.govId}`.toLowerCase()
                    let founded = true
                    for (let w of searchByWords) {
                        if (fullNameAndCi.indexOf(w) === -1) { // word not found, break cycle
                            founded = false
                            break;
                        }
                    }
                    return founded
                })
            }
            // filters
            const usf = extra.establishment
            const maxDate = extra.registration_date_max
            const minDate = extra.registration_date_min
            const maxDateLastVisit = extra.last_visit_date_max
            const minDateLastVisit = extra.last_visit_date_min
            const maxAge = extra.age_max
            const minAge = extra.age_min
            const gender = extra.gender
            const hasCancer = extra.has_cancer === 'true' || extra.has_cancer === true
            const familyCancer = extra.family_cancer === 'true' || extra.family_cancer === true
            const familyHta = extra.family_hta === 'true' || extra.family_hta === true
            const familyDiabetes = extra.family_diabetes === 'true' || extra.family_diabetes === true
            const dx = extra.dx

            // filtering by establishment
            if (usf) {
                filteredData = filteredData.filter(d => +d.establishmentId === +usf)
            }
            // filtering by registration max date
            if (maxDate) {
                filteredData = filteredData.filter(d => d.registrationDate <= maxDate)
            }
            // filtering by registration min date
            if (minDate) {
                filteredData = filteredData.filter(d => d.registrationDate >= minDate)
            }
            // filtering by last visit max date
            if (maxDateLastVisit) {
                filteredData = filteredData.filter(d => d.lastVisit <= maxDateLastVisit)
            }
            // filtering by last visit min date
            if (minDateLastVisit) {
                filteredData = filteredData.filter(d => d.lastVisit >= minDateLastVisit)
            }
            // filtering by max age
            if (maxAge) {
                filteredData = filteredData.filter(d => +d.age <= +maxAge)
            }
            // filtering by min age
            if (minAge) {
                filteredData = filteredData.filter(d => +d.age >= +minAge)
            }
            // filtering by gender
            if (gender !== null && gender !== '' && (+gender === 0 || +gender === 1)) {
                filteredData = filteredData.filter(d => +d.gender === +gender)
            }
            // filtering by has cancer
            if (hasCancer) {
                filteredData = filteredData.filter(d => d.hasCancer === hasCancer)
            }
            // filtering by family has cancer
            if (familyCancer) {
                filteredData = filteredData.filter(d => (d.motherCancer || d.fatherCancer || d.brotherCancer || d.otherCancer) === familyCancer)
            }
            // filtering by family has diabetes
            if (familyDiabetes) {
                filteredData = filteredData.filter(d => (d.motherDiabetes || d.fatherDiabetes || d.brotherDiabetes || d.otherDiabetes) === familyDiabetes)
            }
            // filtering by family has hta
            if (familyHta) {
                filteredData = filteredData.filter(d => (d.motheHta || d.fatherHta || d.brotherHta || d.otherHta) === familyHta)
            }
            // filtering by dx
            if (dx) {
                const getDx = function (d) {
                    if (+dx === 0) {
                        return d.isDiabetic
                    } else if (+dx === 1) {
                        return d.hasHta
                    } else if (+dx === 2) {
                        return d.isDiabetic && d.hasHta
                    }
                }
                filteredData = filteredData.filter(d => !!getDx(d))
            }
            successFunction({
                count: filteredData.length,
                results: filteredData.slice(0, 200) // getting first 50 elements
            })
        }

        if (isConnected) {
            api.patients.getMulti(ordering, search, limit, offset, extra).then((response) => {
                successFunction(response)
            }, err => {
                errorFunction(err)
                getFilteredPatientsFromData();
            })
        } else {
            getFilteredPatientsFromData();
        }
    }

    const addConsultation = function (data, successFunction, errorFunction) {
        if (isConnected) {
            api.consultations.create(data).then(res => {
                consultationTable.add(res).then(event => {
                    let newConsultations = consultations.slice(0)
                    newConsultations.push(res)
                    setConsultations(newConsultations)
                    successFunction()
                }, err => {
                    console.log('error al crear la consulta en la bd local: ', err)
                    successFunction()
                })
            }, err => {
                let errorMsg = ''
                if (err.message) {
                    for (let e in err.message) {
                        errorMsg = `${errorMsg} ${err.message[e]}`
                    }
                } else {
                    errorMsg = JSON.stringify(err)
                }
                errorFunction(`Error al crear consulta: ${errorMsg}`)
            })
        } else { // not connection
            const dataToSave = {
                ...data,
                patient: {
                    id: +data.patient
                }
            }
            consultationTable.add(dataToSave).then(event => {
                addToPendingOperations(data, 'consultation', 'create')
                let newConsultations = consultations.slice(0)
                newConsultations.push({
                    id: event,
                    ...dataToSave,
                })
                setConsultations(newConsultations)
                successFunction()
            }, err => {
                errorFunction(`Error al crear la consulta en la bd local: ${JSON.stringify(err)}`)
            })
        }
    }

    // this should be used ONLY on offline mode
    const getConsultationsById = function (consultationId) {
        return consultations.find(d => +d.id === +consultationId)
    }

    // this should be used ONLY on offline mode
    const getConsultationsByPatient = function (patientId) {
        return consultations.filter(d => +d.patient.id === +patientId)
    }

    const manualDataSync = function () { // this should run only when there is connection
        if (isAuthenticated && isConnected) { // this is defensive programming
            // updating pending operations to api
            syncProgress = 0
            let failedOperations = []
            for (let pending of pendingOperations) {
                api.sync.updateOperation(pending).then(res => {
                    console.log('sincronizacion exitosa: ', res)
                }, err => {
                    failedOperations.push(pending)
                    console.log('error en una sincronizacion: ', err)
                })
            }
            setPendingOperations(failedOperations)
            localStorage.setItem('koica_pending_operations', JSON.stringify(failedOperations))
            // getting patients and consultations
            toast('Iniciando la sincronización de datos. Esto podría tardar unos segundos')
            api.sync.getPatientsConsultations().then(response => {
                setPatients(response.patients)
                let consultations = []
                for (let cons in response.consultations) {
                    for (let con of response.consultations[cons]) {
                        consultations.push({
                            patientGov: cons,
                            ...con
                        })
                    }
                }
                setConsultations(consultations)
                setLoadingPatients(false)
                setLoadingConsultations(false)
                // updating db
                updateDB(patientTable, response.patients)
                updateDB(consultationTable, consultations)
            }, err => {
                console.log('Error al traer los pacientes y las consultas de la api, buscando de la BD: ', err)
                toast(
                    'Ocurrió un problema al sincronizar los datos, el modo Offline podría no funcionar correctamente',
                    { autoClose: 9000 }
                )
            })

            // getting establishments, places and locations
            api.sync.getStatic().then(response => {
                setPlaces(response.places)
                setLocations(response.locations)
                setEstablishments(response.establishments)
                setLoadingPlaces(false)
                setLoadingLocations(false)
                setLoadingEstablishments(false)
                // updating db
                updateDB(placeTable, response.places)
                updateDB(locationTable, response.locations)
                updateDB(establishmentTable, response.establishments)
            }, err => {
                console.log('Error al traer los lugares, localidades y establecimientos de la api, buscando de la BD: ', err)
            })
        }
    }

    useEffect(() => {
        if (isAuthenticated) {
            // getting pending operations
            const pendings = localStorage.getItem('koica_pending_operations')
            let pendingOperationsAux = []
            if (pendings) {
                pendingOperationsAux = JSON.parse(pendings)
                setPendingOperations(pendingOperationsAux)
            }

            if (isConnected) { // internet connection, getting data from api
                syncProgress = 0
                // updating pending operations to api
                let failedOperations = []
                console.log('pendientes: ', pendingOperationsAux)
                for (let pending of pendingOperationsAux) {
                    api.sync.updateOperation(pending).then(res => {
                        console.log('sincronizacion exitosa: ', res)
                    }, err => {
                        failedOperations.push(pending)
                        console.log('error en una sincronizacion: ', err)
                    })
                }
                setPendingOperations(failedOperations)
                localStorage.setItem('koica_pending_operations', JSON.stringify(failedOperations))
                // getting patients and consultations
                toast('Iniciando la sincronización de datos. Esto podría tardar unos segundos')
                api.sync.getPatientsConsultations().then(response => {
                    setPatients(response.patients)
                    let consultations = []
                    for (let cons in response.consultations) {
                        for (let con of response.consultations[cons]) {
                            consultations.push({
                                patientGov: cons,
                                ...con
                            })
                        }
                    }
                    setConsultations(consultations)
                    setLoadingPatients(false)
                    setLoadingConsultations(false)
                    // updating db
                    updateDB(patientTable, response.patients)
                    updateDB(consultationTable, consultations)
                }, err => {
                    console.log('Error al traer los pacientes y las consultas de la api, buscando de la BD: ', err)
                    toast(
                        'Ocurrió un problema al sincronizar los datos, el modo Offline podría no funcionar correctamente',
                        { autoClose: 9000 }
                    )
                    getFromDB(patientTable, setPatients, setLoadingPatients)
                    getFromDB(consultationTable, setConsultations, setLoadingConsultations)
                })

                // getting establishments, places and locations
                api.sync.getStatic().then(response => {
                    setPlaces(response.places)
                    setLocations(response.locations)
                    setEstablishments(response.establishments)
                    setLoadingPlaces(false)
                    setLoadingLocations(false)
                    setLoadingEstablishments(false)
                    // updating db
                    updateDB(placeTable, response.places)
                    updateDB(locationTable, response.locations)
                    updateDB(establishmentTable, response.establishments)
                }, err => {
                    console.log('Error al traer los lugares, localidades y establecimientos de la api, buscando de la BD: ', err)
                    getFromDB(placeTable, setPlaces, setLoadingPlaces)
                    getFromDB(locationTable, setLocations, setLoadingLocations)
                    getFromDB(establishmentTable, setEstablishments, setLoadingEstablishments)
                })
            } else { // not internet connection, getting data from db
                toast('Modo Offline activado, no se pudo establecer conexión con internet, se están utilizando los datos de la última sincronización')
                getFromDB(placeTable, setPlaces, setLoadingPlaces)
                getFromDB(locationTable, setLocations, setLoadingLocations)
                getFromDB(establishmentTable, setEstablishments, setLoadingEstablishments)
                getFromDB(patientTable, setPatients, setLoadingPatients)
                getFromDB(consultationTable, setConsultations, setLoadingConsultations)
            }
        }
    }, [isConnected, isAuthenticated])

    const value = {
        isConnected,
        places,
        locations,
        establishments,
        patients,
        consultations,
        loadingPlaces,
        loadingLocations,
        loadingEstablishments,
        loadingPatients,
        loadingConsultations,
        getPatientById,
        addPatient,
        editPatient,
        deletePatient,
        filterPatients,
        addConsultation,
        getConsultationsById,
        getConsultationsByPatient,
        manualDataSync
    }

    return <DataContext.Provider value={value}>
        {props.children}
    </DataContext.Provider>
}

export default DataProvider;
export { DataContext };