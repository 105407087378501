import React, { useContext, useEffect, useState } from "react";
import {
    CalendarIcon,
    ChartBarIcon,
    LocationMarkerIcon,
    MenuAlt2Icon,
    OfficeBuildingIcon,
    UserCircleIcon,
    UserGroupIcon,
} from '@heroicons/react/outline'
import api from "../../api";
import { useNavigate } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import Breadcrumbs from "../components/Breadcrumbs";
import Steps from "../components/Steps";
import FormNewPatient from "../components/FormNewPatient";
import { getSeriesPropsFromChildren } from "react-vis/dist/utils/series-utils";
import { DataContext } from '../../data';

const pages = [
    { name: 'Pacientes', href: '/patients', current: false },
    { name: 'Registro del paciente', href: `#`, current: true },
]

const options = [
    <option value={1}>Cédula de identidad</option>,
    <option value={2}>Pasaporte</option>,
    <option value={0}>No tiene</option>
]

const radio = [
    { id: "male", default: true, label: "Masculino", value: 0 },
    { id: "female", default: false, label: "Femenino", value: 1 }
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function PatientNewPage() {

    const { isConnected, addPatient, locations, loadingLocations, establishments, loadingEstablishments } = useContext(DataContext)

    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [submitError, setSubmitError] = useState('')
    const [theLocations, setTheLocations] = useState([])
    const [theEstablishments, setTheEstablishments] = useState([])
    const [tab, setTab] = useState('DATOS PERSONALES')
    const navigate = useNavigate();
    const [patient, setPatient] = useState({
        documentType: "1",
        govId: "",
        provisionalCode: "",
        firstName: "",
        lastName: "",
        age: "-",
        gender: "0",
        birthdate: new Date().toISOString().substring(0, 10),
        address: "",
        neighbourhood: 0,
        establishment: localStorage.getItem('establishment'),
        phone: "",
        registrationDate: new Date().toISOString().substring(0, 10),
        isDiabetic: false,
        hasHta: false,
        hasCancer: false,
        cancerType: "",
        fatherDiabetes: false,
        fatherHta: false,
        fatherCancer: false,
        motherDiabetes: false,
        motherHta: false,
        motherCancer: false,
        brotherDiabetes: false,
        brotherHta: false,
        brotherCancer: false,
        location: null
    })
    const [errors, setErrors] = useState({
        firstName: "",
        lastName: "",
        govId: "",
        birthdate: "",
        neighbourhood: "",
        diabetes: "",
        hta: "",
        cancer: "",
        cancerType: "",
        medicalBackground: "",
        establishment: ""
    });

    const [steps, setStep] = useState(
        [
            { id: '01', name: 'DATOS PERSONALES', status: 'current' },
            { id: '02', name: 'ANTECEDENTES', status: 'upcoming' },
        ]
    )

    useEffect(() => {
        if (tab == 'DATOS PERSONALES') {
            setStep([
                { id: '01', name: 'DATOS PERSONALES', status: 'current' },
                { id: '02', name: 'ANTECEDENTES', status: 'upcoming' },
            ])
        } else {
            setStep([
                { id: '01', name: 'DATOS PERSONALES', status: 'complete' },
                { id: '02', name: 'ANTECEDENTES', status: 'current' },
            ])
        }
    }, [tab])

    const handleChange = (field, value) => setPatient((prev) => ({ ...prev, [field]: value }));
    const handleError = (field, value) => setErrors((prev) => ({ ...prev, [field]: value }))

    // getting locations and establishments on mount if there is connection
    useEffect(() => {
        if (isConnected) {
            api.locations.getMulti().then((response) => {
                setTheLocations(response.results.map(({ id, name }) => {
                    return (
                        <option label={name} value={id}>{name}</option>
                    )
                }));
            }, err => {
                console.log('Error al traer los establecimientos: ', err.response?.data ? err.response.data : err)
                handleError('establishment', 'Error al traer los establecimientos')
            }).then(
                api.establishments.getMulti().then((response) => {
                    setTheEstablishments(response.results.map(({ id, name }) => {
                        return (
                            <option label={name} value={id} />
                        )
                    }))
                    setLoading(false);
                }, err => {
                    console.log('Error al traer lass compañías: ', err.response?.data ? err.response.data : err)
                    handleError('neighbourhood', 'Error al traer las compañías')
                    setLoading(false);
                })
            )
        }
    }, [])

    // getting locations and establishments from db if there is not connection (when data are loaded)
    useEffect(() => {
        if (!isConnected && !loadingLocations && !loadingEstablishments) {
            setTheLocations(locations.map(({ id, name }) => { // locations from db
                return (
                    <option label={name} value={id} />
                )
            }));
            setTheEstablishments(establishments.map(({ id, name }) => { // establishments from db
                return (
                    <option label={name} value={id} />
                )
            }))
            setLoading(false);
        }
    }, [loadingLocations, loadingEstablishments])

    useEffect(() => {
        let firstNameInitials = patient.firstName.split(' ').map(word => word[0]).join('');
        let lastNameInitials = patient.lastName.split(' ').map(word => word[0]).join('');
        let birthdateString = patient.birthdate.split('-').join('');
        handleChange('provisionalCode', firstNameInitials + lastNameInitials + birthdateString);
    }, [patient.firstName, patient.lastName, patient.birthdate])

    const handleSubmit = (event) => {
        event.preventDefault()
        handleError('establishment', '')
        handleError('neighbourhood', '')
        let error = false
        if (!patient.isDiabetic && !patient.hasHta && !patient.hasCancer) {
            handleError('medicalBackground', 'Debe seleccionar por lo menos uno')
            error = true
        } else {
            handleError('medicalBackground', '')
        }
        if (!error) {
            setLoadingSubmit(true)
            setSubmitError('')
            let patientData = {
                ...patient,
                location: patient.neighbourhood
            }
            if (!isConnected) {
                let estName = establishments.find(e => +e.id === +patientData.establishment)?.name
                patientData = {
                    ...patientData,
                    establishmentId: +patientData.establishment,
                    establishment: estName
                }
            }
            addPatient(patientData, function (idNewPatient) {
                setLoadingSubmit(false)
                navigate(`/patients/${idNewPatient}`)
            }, function (err) {
                setLoadingSubmit(false)
                setSubmitError(err)
                console.log(err)
            })
        }
    }

    return (
        <div className="bg-gray-100 h-screen">
            <Sidebar currentScreen={'Pacientes'} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="md:pl-64 flex flex-col flex-1">
                <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-100">
                    <button
                        type="button"
                        className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                        onClick={() => setSidebarOpen(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                {loading ?
                    <div className="flex h-screen items-center justify-center ">
                        <div className="w-16 h-16 border-b-2 border-blue-900 rounded-full animate-spin"></div>
                    </div>
                    :
                    <>
                        <div className="ml-6 mt-6">
                            <Breadcrumbs pages={pages} />
                        </div>
                        <main className="flex-1">
                            <div className="py-6">
                                <div className="max-w-7xl w-full lg:w-2/3 px-4 sm:px-6 md:px-8">
                                    <Steps setTab={setTab} steps={steps} />
                                </div>
                                <div className="max-w-7xl mt-4 px-4 sm:px-6 md:px-8">
                                    <div className="bg-white w-full overflow-hidden shadow rounded-lg">
                                        <FormNewPatient
                                            handleChange={handleChange}
                                            patient={patient}
                                            locations={theLocations}
                                            establishments={theEstablishments}
                                            options={options}
                                            radio={radio}
                                            errors={errors}
                                            loadingSubmit={loadingSubmit}
                                            handleSubmit={handleSubmit}
                                            handleError={handleError}
                                            submitError={submitError}
                                            tab={tab}
                                            setTab={setTab}
                                        />
                                    </div>
                                </div>
                            </div>
                        </main>
                    </>
                }
            </div >
        </div >
    );
}
