import React from "react";
import { ExclamationCircleIcon } from "@heroicons/react/solid";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function TextInput(props) {

    return (
        <div className={props.containerClassName}>
            <label htmlFor={props.name} className="text-sm font-medium text-gray-700">
                {props.label}
            </label>
            <div className="mt-1 relative rounded-md flex flex-wrap">
                <input
                    onChange={(event) => props.Idx != null ? props.handleChange(props.name, event.target.value, props.Idx) : props.handleChange(props.name, event.target.value)}
                    type={props.type}
                    name={props.name}
                    id={props.name}
                    defaultValue={props.value}
                    placeholder={props.placeholder}
                    className={props.className ? props.className : "shadow-sm h-8 pl-2 w-full border border-gray-300 block sm:text-sm rounded-md"}
                    disabled={props.disabled}
                    required={props.required}
                    onKeyPress={props.onKeyPress}
                    maxLength={props.maxLength}
                    aria-describedby={`${props.name}-description`}
                />
                {props.unit ? <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    {props.errorMessage && props.errorOffset ?
                        <span className={classNames("text-gray-500 sm:text-sm", props.errorOffset)} id="price-currency">
                            {props.unit}
                        </span>
                        :
                        <span className={classNames("text-gray-500 sm:text-sm", props.offset)} id="price-currency">
                            {props.unit}
                        </span>
                    }
                </div> : null}
                {props.errorMessage ?
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                    </div>
                    : null}
            </div>
            {!!props.description && <p className="mt-2 text-xs text-gray-500" id={`${props.name}-description`}>
                {props.description}
            </p>}
            {!!props.errorMessage && <p className="mt-2 text-sm text-red-600" id={`${props.name}-error`}>
                {props.errorMessage}
            </p>}
        </div >
    )

}