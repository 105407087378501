import React, { useEffect } from "react";
import { useState } from 'react';
import { Link } from "react-router-dom";
import {
    CalendarIcon,
    ChartBarIcon,
    OfficeBuildingIcon,
    UserCircleIcon,
    UserGroupIcon,
    PlusIcon,
    LocationMarkerIcon,
} from '@heroicons/react/outline'
import { BanIcon } from "@heroicons/react/solid";
import api from "../../api";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import Table from "../components/Table";

const navigation = [
    { name: 'Pacientes', href: '/patients', icon: UserGroupIcon, current: false },
    { name: 'Reportes', href: '/reports', icon: ChartBarIcon, current: false },
    { name: 'Establecimientos', href: '/establishments', icon: OfficeBuildingIcon, current: false },
    { name: 'Usuarios', href: '/users', icon: UserCircleIcon, current: false },
    { name: 'Distritos', href: '/districts', icon: LocationMarkerIcon, current: true },
    // { name: 'Calendarios', href: '/calendars', icon: CalendarIcon, current: false },
]

const columns = [
    { name: 'name', displayText: 'Nombre del distrito', clickable: true },
    { name: 'department', displayText: 'Departamento' },
    { name: 'locations_qty', displayText: 'Cantidad de localidades' }
]

const departments = [
    { name: 'Central', idDepartamento: 1 }
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function DistrictPage() {

    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [districts, setDistricts] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        api.districts.getMulti().then((response) => {
            setDistricts(response.results.map(({ name, id, department, locations }) => {
                return ({
                    name: name, id: id, department: departments.find(element => element.department = department).name, locations_qty: locations.length
                })
            })
            )
            setLoading(false)
        })
    }, [])

    return (
        <div>
            <Sidebar currentScreen={'Distritos'} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="md:pl-64 flex flex-col h-screen flex-1">
                <Header setSidebarOpen={setSidebarOpen} buttonText={districts.length ? "Nuevo distrito" : null} href="/districts/new" />
                <main className="flex-1 bg-gray-100">
                    {loading ?
                        <div class="flex h-full items-center justify-center ">
                            <div class="w-16 h-16 border-b-2 border-blue-900 rounded-full animate-spin"></div>
                        </div> :
                        districts.length ?
                            <div className="py-6">
                                <div className="px-4 sm:px-6 md:px-8">
                                    <Table firstColumnBold={true} module="districts" actiontext="Editar" columns={columns} rows={districts} />
                                </div>
                            </div>
                            :
                            <div className="flex h-full">
                                <div className="mx-auto my-auto text-center">
                                    <BanIcon className="h-12 w-12 mx-auto text-gray-400" aria-hidden="true" />
                                    <h3 className="mt-2 text-sm font-semibold text-gray-900">No se encontraron resultados</h3>
                                    <p className="mt-1 text-sm font-semibold text-gray-500">Probá con otros datos o ingresá un nuevo distrito.</p>
                                    <div className="mt-6">
                                        <Link
                                            to="/districts/new"
                                            type="button"
                                            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-blue-600 hover:bg-blue-700"
                                        >
                                            <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                            Nuevo distrito
                                        </Link>
                                    </div>
                                </div>
                            </div>
                    }
                </main>
            </div>
        </div >
    );
}
