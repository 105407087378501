import React, { useEffect, useState } from "react";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function SelectInput(props) {

    return (
        <div className={props.containerStyle ? props.containerStyle : ""}>
            {props.label && <label htmlFor={props.name} className={props.labelClassName ? props.labelClassName : "text-sm font-medium text-gray-700"}>
                {props.label}
            </label>}
            <div className="mt-1">
                <select
                    value={props.value}
                    onChange={(event) => props.Idx != null ? props.handleChange(props.name, event.target.value, props.Idx) : props.handleChange(props.name, event.target.value)}
                    type="text"
                    name={props.name}
                    id={props.name}
                    disabled={props.disabled}
                    className={props.className ? props.className : "shadow-sm w-40 border border-gray-300 block sm:text-sm rounded-md"}
                >
                    {props.placeholder ? <option hidden selected value> Seleccione una opción </option> : null}
                    {props.options}
                </select>
            </div>
            {props.errorMessage && <p className="mt-2 text-sm text-red-600" id={`${props.name}-error`}>
                {props.errorMessage}
            </p>}
        </div>
    )

}