import React, { useEffect } from "react";
import { CheckIcon } from "@heroicons/react/outline";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Steps(props) {

    const goBack = () => {
        props.setTab('DATOS PERSONALES')
    }

    return (
        <div>
            <nav className="mx-auto max-w-7xl" aria-label="Progress">
                <ol
                    role="list"
                    className="rounded-md overflow-hidden sm:border-t sm:border-b sm:flex sm:border-l sm:border-r sm:border-gray-200 sm:rounded-none"
                >
                    {props.steps.map((step, stepIdx) => (
                        <li key={step.id} className="relative overflow-hidden sm:flex-1">
                            <div
                                className={classNames(
                                    stepIdx === 0 ? 'border-b-0 rounded-t-md' : '',
                                    stepIdx === props.steps.length - 1 ? 'border-t-0 rounded-b-md' : '',
                                    'border border-gray-200 overflow-hidden sm:border-0'
                                )}
                            >
                                {step.status === 'complete' ? (
                                    <button type="button" onClick={() => goBack()} className="group">
                                        <span
                                            className="absolute top-0 left-0 w-1 h-full bg-transparent group-hover:bg-gray-200 md:w-full md:h-1 md:bottom-0 md:top-auto"
                                            aria-hidden="true"
                                        />
                                        <span
                                            className={classNames(
                                                stepIdx !== 0 ? 'md:pl-9' : '',
                                                'px-6 py-5 flex items-start text-sm font-medium'
                                            )}
                                        >
                                            <span className="flex-shrink-0">
                                                <span className="w-10 h-10 flex items-center justify-center bg-blue-600 rounded-full">
                                                    <CheckIcon className="w-6 h-6 text-white" aria-hidden="true" />
                                                </span>
                                            </span>
                                            <span className="ml-4 min-w-0 flex flex-col">
                                                {step.description ?
                                                    <>
                                                        <span className="text-xs font-semibold tracking-wide uppercase">{step.name}</span>
                                                        <span className="text-sm font-medium text-gray-500">{step.description}</span>
                                                    </>
                                                    :
                                                    <span className="text-xs mt-4 font-semibold tracking-wide uppercase">{step.name}</span>
                                                }
                                            </span>
                                        </span>
                                    </button>
                                ) : step.status === 'current' ? (
                                    <button aria-current="step">
                                        <span
                                            className="absolute top-0 left-0 w-1 h-full bg-blue-600 md:w-full md:h-1 md:bottom-0 md:top-auto"
                                            aria-hidden="true"
                                        />
                                        <span
                                            className={classNames(
                                                stepIdx !== 0 ? 'md:pl-9' : '',
                                                'px-6 py-5 flex items-start text-sm font-medium'
                                            )}
                                        >
                                            <span className="flex-shrink-0">
                                                <span className="w-10 h-10 flex items-center justify-center border-2 border-blue-600 rounded-full">
                                                    <span className="text-blue-600">{step.id}</span>
                                                </span>
                                            </span>
                                            <span className="mt-0.5 ml-4 min-w-0 flex flex-col">
                                                {step.description ?
                                                    <>
                                                        <span className="text-xs font-semibold text-blue-600 tracking-wide uppercase">
                                                            {step.name}
                                                        </span>
                                                        <span className="text-sm font-medium text-gray-500">{step.description}</span>
                                                    </>
                                                    :
                                                    <span className="text-xs font-semibold mt-2 text-blue-600 tracking-wide uppercase">{step.name}</span>
                                                }
                                            </span>
                                        </span>
                                    </button>
                                ) : (
                                    <button type="button" className="group">
                                        <span
                                            className="absolute top-0 left-0 w-1 h-full bg-transparent group-hover:bg-gray-200 md:w-full md:h-1 md:bottom-0 md:top-auto"
                                            aria-hidden="true"
                                        />
                                        <span
                                            className={classNames(
                                                stepIdx !== 0 ? 'md:pl-9' : '',
                                                'px-6 py-5 flex items-start text-sm font-medium'
                                            )}
                                        >
                                            <span className="flex-shrink-0">
                                                <span className="w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full">
                                                    <span className="text-gray-500">{step.id}</span>
                                                </span>
                                            </span>
                                            <span className="mt-0.5 ml-4 min-w-0 flex flex-col">
                                                {step.description ?
                                                    <>
                                                        <span className="text-xs font-semibold text-gray-500 tracking-wide uppercase">{step.name}</span>
                                                        <span className="text-sm font-medium text-gray-500">{step.description}</span>
                                                    </> :
                                                    <span className="text-xs mt-2 font-semibold text-gray-500 tracking-wide uppercase">{step.name}</span>
                                                }
                                            </span>
                                        </span>
                                    </button>
                                )}

                                {stepIdx !== 0 ? (
                                    <>
                                        {/* Separator */}
                                        <div className="hidden absolute top-0 left-0 w-3 inset-0 sm:block" aria-hidden="true">
                                            <svg
                                                className="h-full w-full text-gray-300"
                                                viewBox="0 0 12 82"
                                                fill="none"
                                                preserveAspectRatio="none"
                                            >
                                                <path d="M0.5 0V31L10.5 41L0.5 51V82" stroke="currentcolor" vectorEffect="non-scaling-stroke" />
                                            </svg>
                                        </div>
                                    </>
                                ) : null}
                            </div>
                        </li>
                    ))}
                </ol>
            </nav>
        </div>
    )
}