import React from "react";
import { ChevronRightIcon } from '@heroicons/react/outline'
import { Link } from "react-router-dom";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Breadcrumbs(props) {

    return (
        <nav className="flex" aria-label="Breadcrumb">
            <ol className="flex items-center">
                {props.pages.map((page) => (
                    <li key={page.name}>
                        <div className="flex items-center">
                            <Link
                                to={page.href}
                                className={classNames(
                                    page.current
                                        ? 'text-blue-600 hover:text-blue-800'
                                        : 'text-gray-500 hover:text-gray-700',
                                    "text-sm font-medium mx-2 xs:mx-4"
                                )}
                                aria-current={page.current ? 'page' : undefined}
                            >
                                {page.name}
                            </Link>
                            {!page.current ? <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" /> : null}
                        </div>
                    </li>
                ))}
            </ol>
        </nav>
    )

}