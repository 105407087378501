import React, { useEffect, useState, Fragment } from "react";
import { useSearchParams } from "react-router-dom";
import {
    TrashIcon,
    PlusIcon,
    MenuAlt2Icon
} from '@heroicons/react/outline'
import api from "../../api";
import Sidebar from '../components/Sidebar';
import DateInput from "../components/DateInput";
import SelectInput from "../components/SelectInput";
import Tag from "../components/Tag";
import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { GraphsTab, TableTab, DataTab } from './Tabs'

let isStaff = JSON.parse(localStorage.getItem('userData'))?.isStaff

let defaultEstablishment = localStorage.getItem('establishment') ? +localStorage.getItem('establishment') : 1

const tabs = isStaff ? [
    { name: 'Gráficos', id: 'graphs' },
    { name: 'Tabla de indicadores', id: 'table' },
    { name: 'Datos crudos', id: 'data' }
] : [
    { name: 'Gráficos', id: 'graphs' },
    { name: 'Tabla de indicadores', id: 'table' }
]

const graphsGroups = [
    { name: 'Estadísticas de Consultas', id: 'visits' },
    { name: 'Estadísticas de Educación', id: 'education' },
    { name: 'Estadísticas de Medición', id: 'measurements' },
    { name: 'Estadísticas de Demografía', id: 'demographic' },
]

const tables = isStaff ? [
    { name: 'Tabla de indicadores', id: 'indicators' },
    { name: 'Resumen por mes', id: 'month' },
    { name: 'Resumen por año', id: 'year' },
] : [
    { name: 'Resumen por mes', id: 'month' }
]

const typesOfRawData = [
    { name: 'Datos de pacientes', id: 'patients' },
    { name: 'Datos de consultas', id: 'consultations' },
    { name: 'Datos de pacientes y consultas', id: 'patient-consultations' },
]


let establishmentOptions = [
    { id: 0, name: 'Cargando Establecimientos...' }
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const labelStyle = 'text-xs text-gray-500 font-medium';
const buttonBaseStyle = 'inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm';
const ageInputStyle = 'shadow-sm w-full border border-gray-300 block sm:text-sm rounded-md';

let dateToday = new Date()
dateToday = dateToday.toISOString().split('T')[0]

export default function ReportsPage(props) {

    const [gettingInitialEstablishment, setGettingInitialEstablishment] = useState(true)

    const [searchParams, setSearchParams] = useSearchParams()

    // all establishment
    const [establishments, setEstablishments] = useState([])

    // graphs selected group
    const [selectedGroup, setSelectedGroup] = useState(graphsGroups[0].id)

    // selected table
    const [selectedTable, setSelectedTable] = useState(tables[0].id) // indicators table or summary by month if user is not staff

    // selected type of raw data
    const [selectedRawData, setSelectedRawData] = useState(typesOfRawData[0].id)

    // to open sidebar in responsive
    const [sidebarOpen, setSidebarOpen] = useState(false)
    // changing tab
    const [currentTab, setCurrentTab] = useState(searchParams.get('tab') ? searchParams.get('tab') : tabs[0].id)
    // opening filters modal
    // const [openModal, setOpenModal] = useState(false)

    // report data from api, and the loading status of it
    const [tableData, setTableData] = useState(null)
    const [graphsData, setGraphsData] = useState(null)
    const [loadingData, setLoadingData] = useState(true)
    const [loadingDownload, setLoadingDownload] = useState(false)

    // filters
    const [filterFromDate, setFilterFromDate] = useState(searchParams.get('date_min') ? searchParams.get('date_min') : '2022-01-01')
    const [filterToDate, setFilterToDate] = useState(searchParams.get('date_max') ? searchParams.get('date_max') : dateToday)
    const [filterDxs, setFilterDxs] = useState(searchParams.get('dx') ? searchParams.get('dx') : '')
    const [filterMxs, setFilterMxs] = useState(searchParams.get('mx') ? searchParams.get('mx') : '')
    const [filterPatientType, setFilterPatientType] = useState(searchParams.get('patient_type') ? searchParams.get('patient_type') : '')
    const [filterEstablishment, setFilterEstablishment] = useState(searchParams.get('establishment') ? searchParams.getAll('establishment') : [])
    const [filterUniqueEstablishment, setFilterUniqueEstablishment] = useState(searchParams.get('unique_usf') ? searchParams.get('unique_usf') : defaultEstablishment)
    // filters done //

    // updating search params and then component state
    const setTab = function (tab) {
        let currentParams = Object.fromEntries([...searchParams])
        // adding establishment to fix error: establishment from currentparams were last filterestablishment instead of whole array
        if (filterEstablishment.length > 1) {
            currentParams.establishment = filterEstablishment
        }
        setSearchParams({ ...currentParams, tab: tab })
    }

    // url params setters //
    const setDates = function (minDate, maxDate) {
        let currentParams = Object.fromEntries([...searchParams])
        // adding establishment to fix error: establishment from currentparams were last filterestablishment instead of whole array
        if (filterEstablishment.length > 1) {
            currentParams.establishment = filterEstablishment
        }
        setSearchParams({ ...currentParams, date_min: minDate, date_max: maxDate })
    }

    const setEstablishment = function (establishment) {
        let currentParams = Object.fromEntries([...searchParams])
        if (+establishment === 0) {
            delete currentParams.establishment
        } else {
            currentParams = { ...currentParams, establishment: establishment }
        }
        setSearchParams(currentParams)
    }

    const setUniqueEstablishment = function (unqueEstablishment) {
        const currentParams = Object.fromEntries([...searchParams])
        let newFilters = currentParams
        if (unqueEstablishment && unqueEstablishment > 0) {
            newFilters = {
                ...newFilters,
                unique_usf: unqueEstablishment
            }
        } else {
            delete newFilters.unique_usf
        }
        // adding establishment to fix error: establishment from currentparams were last filterestablishment instead of whole array
        if (filterEstablishment.length > 1) {
            newFilters.establishment = filterEstablishment
        }
        setSearchParams(newFilters)
    }

    const setDxs = function (dxs) {
        const currentParams = Object.fromEntries([...searchParams])
        let newFilters = currentParams
        if (dxs && dxs !== '') {
            newFilters = {
                ...newFilters,
                dx: dxs
            }
        } else {
            delete newFilters.dx
        }
        // adding establishment to fix error: establishment from currentparams were last filterestablishment instead of whole array
        if (filterEstablishment.length > 1) {
            newFilters.establishment = filterEstablishment
        }
        setSearchParams(newFilters)
    }

    const setMxs = function (mxs) {
        const currentParams = Object.fromEntries([...searchParams])
        let newFilters = currentParams
        if (mxs && mxs !== '') {
            newFilters = {
                ...newFilters,
                mx: mxs
            }
        } else {
            delete newFilters.mx
        }
        // adding establishment to fix error: establishment from currentparams were last filterestablishment instead of whole array
        if (filterEstablishment.length > 1) {
            newFilters.establishment = filterEstablishment
        }
        setSearchParams(newFilters)
    }

    const setPatientType = function (patientType) {
        const currentParams = Object.fromEntries([...searchParams])
        let newFilters = currentParams
        if (patientType && patientType !== '') {
            newFilters = {
                ...newFilters,
                patient_type: patientType
            }
        } else {
            delete newFilters.patient_type
        }
        // adding establishment to fix error: establishment from currentparams were last filterestablishment instead of whole array
        if (filterEstablishment.length > 1) {
            newFilters.establishment = filterEstablishment
        }
        setSearchParams(newFilters)
    }

    const updateReportData = function (filters, tab) {
        setLoadingData(true)
        let reducedFilters = {}
        if (tab === tabs[1].id) { // current tab is indicators table
            // reducing filter to used ones on tables
            reducedFilters = { // required filters
                establishment: filters.unique_usf,
                date_min: filters.date_min
            }
            // optional filter
            if (filters.date_max) {
                reducedFilters.date_max = filters.date_max
            }

            let tableDataTemp = {}
            const tableGroupsAvailable = isStaff ? [
                ['indicator-table', 'indicators'],
                ['indicator-table/summary-by-month', 'month'],
                ['indicator-table/summary-by-year', 'year']
            ] : [
                ['indicator-table/summary-by-month', 'month']
            ]
            const updateData = function () {
                setTableData(tableDataTemp)
                console.log(tableDataTemp)
                setLoadingData(false)
            }
            for (let tableGroup of tableGroupsAvailable) {
                if (tableGroup[1] === selectedTable) {
                    api.reports.get(tableGroup[0], reducedFilters).then(res => {
                        tableDataTemp = {
                            ...tableDataTemp,
                            [tableGroup[1]]: res
                        }
                        updateData()
                    }, err => {
                        updateData()
                    })
                }
            }
        } else if (tab === tabs[0].id) { // current tab is graphs
            // reducing filter to used ones on tables
            reducedFilters = filters
            // removing only not used filter on graphs
            delete reducedFilters.unique_usf

            let graphsDataTemp = {}
            const graphsGroupsAvailable = ['visits', 'education', 'measurements', 'demographic']
            let gGroupsGotten = 0 // counter for updating data
            let amountOfGGroups = graphsGroupsAvailable.length // triger for counter to update data (number of type of graphs data)
            const updateData = function () {
                gGroupsGotten = gGroupsGotten + 1
                if (gGroupsGotten === amountOfGGroups) { // all data set
                    setGraphsData(graphsDataTemp)
                    console.log(graphsDataTemp)
                    setLoadingData(false)
                }
            }
            for (let graphsGroup of graphsGroupsAvailable) {
                api.reports.get(graphsGroup, reducedFilters).then(res => {
                    graphsDataTemp = {
                        ...graphsDataTemp,
                        [graphsGroup]: res
                    }
                    updateData()
                }, err => {
                    updateData()
                })
            }
        } else {
            setLoadingData(false)
        }
    }

    useEffect(() => {
        api.users.getMe().then((response) => {
            isStaff = response.isStaff
            if (response.profile?.establishment && response.profile.establishment !== defaultEstablishment) {
                localStorage.setItem('establishment', response.profile.establishment)
                defaultEstablishment = +response.profile.establishment
            }
            // filtering by default establishment if there is not establishment in url
            if (filterEstablishment.length === 0 && !isStaff) {
                setEstablishment(defaultEstablishment)
            }
            setGettingInitialEstablishment(false)
            // setUniqueEstablishment(defaultEstablishment)
        }, err => {
            console.log('error al traer datos del usuario: ', err)
            // filtering by default establishment if there is not establishment in url
            if (filterEstablishment.length === 0 && !isStaff) {
                setEstablishment(defaultEstablishment)
            }
            setGettingInitialEstablishment(false)
            // setUniqueEstablishment(defaultEstablishment)
        })
        // getting establishments
        api.establishments.getMulti().then(res => {
            establishmentOptions = res.results.map(ests => {
                return { id: ests.id, name: ests.name }
            })
            setEstablishments(establishmentOptions.slice(0))
            // adding element to the start of array
            establishmentOptions.unshift({ id: 0, name: 'Todos los establecimientos' })
        })
    }, [])

    useEffect(() => {
        if (!gettingInitialEstablishment) {
            const tab = searchParams.get('tab')
            // filters
            const minDate = searchParams.get('date_min')
            const maxDate = searchParams.get('date_max')
            const establishment = searchParams.getAll('establishment')
            const uniqueUsf = searchParams.get('unique_usf')
            const dxs = searchParams.get('dx')
            const mxs = searchParams.get('mx')
            const patientType = searchParams.get('patient_type')

            // this field is required
            let filters = { date_min: filterFromDate }
            // if tab is not valid, dont update component status
            if (!!tab && tab !== currentTab) {
                setCurrentTab(tab)
            }

            if (minDate) {
                setFilterFromDate(minDate)
                filters = { ...filters, date_min: minDate }
            }
            if (maxDate) {
                setFilterToDate(maxDate)
                filters = { ...filters, date_max: maxDate }
            }
            if (isStaff && establishment) {
                if (establishment.length === 0) {
                    delete filters.establishment
                    setFilterEstablishment([])
                } else {
                    setFilterEstablishment(establishment)
                    filters = { ...filters, establishment: establishment }
                }
            } else if (!isStaff) {
                setFilterEstablishment([defaultEstablishment])
                filters = { ...filters, establishment: [defaultEstablishment] }
            }

            if (isStaff && uniqueUsf && !isNaN(uniqueUsf) && +uniqueUsf > 0) {
                setFilterUniqueEstablishment(+uniqueUsf)
                filters = { ...filters, unique_usf: +uniqueUsf }
            } else {
                filters = { ...filters, unique_usf: defaultEstablishment }
                setFilterUniqueEstablishment(defaultEstablishment)
            }

            if (dxs && !isNaN(dxs)) {
                setFilterDxs(dxs)
                filters = { ...filters, dx: dxs }
            } else {
                setFilterDxs('')
            }
            if (mxs && !isNaN(mxs)) {
                setFilterMxs(mxs)
                filters = { ...filters, measurement: mxs }
            } else {
                setFilterMxs('')
            }
            if (patientType && !isNaN(patientType)) {
                setFilterPatientType(patientType)
                filters = { ...filters, patient_type: patientType }
            } else {
                setFilterPatientType('')
            }
            updateReportData(filters, tab ? tab : currentTab)
        }

    }, [searchParams, gettingInitialEstablishment, selectedTable])

    const download_indicators_table = function () {
        let filters = { date_min: filterFromDate }
        if (filterToDate) {
            filters.date_max = filterToDate
        }
        api.reports.download_indicators_table(filters).then(res => {
            setLoadingDownload(false)
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Tabla_Indicadores.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();
        }, err => {
            console.log('error al descargar la tabla de indicadores: ', err.response?.data)
            setLoadingDownload(false)
        })
    }

    const download_monthly_summary = function () {
        let filters = { establishment: filterUniqueEstablishment }
        api.reports.download_summary_by_month(filters).then(res => {
            setLoadingDownload(false)
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Resumen_por_mes.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();
        }, err => {
            console.log('error al descargar el resumen por mes: ', err.response?.data)
            setLoadingDownload(false)
        })
    }

    const download_yearly_summary = function () {
        api.reports.download_summary_by_year().then(res => {
            setLoadingDownload(false)
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Resumen_por_anho.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();
        }, err => {
            console.log('error al descargar el resumen por año: ', err.response?.data)
            setLoadingDownload(false)
        })
    }

    const downloadCurrent = function (currentTable) {
        setLoadingDownload(true)
        if (currentTable === 'indicators') {
            download_indicators_table()
        } else if (currentTable === 'month') {
            download_monthly_summary()
        } else if (currentTable === 'year') {
            download_yearly_summary()
        } else {
            setLoadingDownload(false)
            console.log('error al recibir el tipo de tabla seleccionado')
        }
    }

    const Tab = function ({ text, current, onClick }) {
        const currentClass = current ? "bg-blue-200 text-blue-500 px-3 py-1 md:py-2 rounded-md text-sm font-medium" : "text-gray-600 hover:bg-gray-200 px-3 py-1 md:py-2 rounded-md text-sm font-medium"
        return <button onClick={onClick} className={currentClass}>{text}</button>
    }

    const DropdownPop = function ({ text, PanelComponent, functionProps }) {

        const Panel = function (props) {
            return <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
            >
                <Popover.Panel className="absolute z-20 -ml-4 mt-3 transform lg:max-w-2xl lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative grid gap-6 bg-white p-6">
                            <PanelComponent props={props} />
                        </div>
                    </div>
                </Popover.Panel>
            </Transition>
        }

        return <Popover className="relative">
            {({ open, close }) => (
                <>
                    <Popover.Button
                        className={classNames(
                            open ? 'text-gray-900' : 'text-gray-700',
                            'my-1 group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                        )}
                    >
                        <span>{text}</span>
                        <ChevronDownIcon
                            className={classNames(
                                open ? 'text-gray-600' : 'text-gray-400',
                                'ml-2 h-5 w-5 group-hover:text-gray-500'
                            )}
                            aria-hidden="true"
                        />
                    </Popover.Button>
                    <Panel close={close} functionProps={functionProps} />
                </>
            )}
        </Popover>
    }

    const DatePopUp = function ({ props }) {

        const [fromDate, setFromDate] = useState(filterFromDate)
        const [toDate, setToDate] = useState(filterToDate)

        const close = props.close

        let today = new Date()
        today = today.toISOString().split('T')[0]

        return <div>
            <DateInput handleChange={(field, value) => setFromDate(value)} value={fromDate} label={'Desde'} labelClassName={labelStyle} containerStyle={'w-60 mb-3'} />
            <DateInput handleChange={(field, value) => setToDate(value)} min={fromDate} value={toDate} label={'Hasta'} labelClassName={labelStyle} containerStyle={'w-60 mb-9'} />
            <div className="flex w-full justify-between">
                <button
                    onClick={() => { setDates('2022-01-01', today); close() }}
                    type="button"
                    className={`${buttonBaseStyle} text-gray-700 bg-white border-gray-300 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700`}
                >
                    Borrar filtros
                </button>
                <button
                    onClick={() => { setDates(fromDate, toDate); close() }}
                    type="button"
                    className={`${buttonBaseStyle} text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                >
                    Aplicar filtro
                </button>
            </div>
        </div>
    }

    const EstablishmentPopUp = function ({ props }) {

        const [input, setInput] = useState(0)
        const [selectedEstablishments, setSelectedEstablishments] = useState(filterEstablishment)
        const [arrayUSFs, setArrayUSFs] = useState([])

        const close = props.close

        const addEstablishment = function (establishmentId) {
            if (establishmentId === 0) {
                setSelectedEstablishments([])
            } else if (!selectedEstablishments.find(e => +e === +establishmentId)) {
                let auxSelectedEstablishments = selectedEstablishments.slice(0)
                auxSelectedEstablishments.push(establishmentId)
                setSelectedEstablishments(auxSelectedEstablishments)
            }
        }

        const removeEstablishment = function (establishmentId) {
            let indx = selectedEstablishments.findIndex(e => +e === +establishmentId)
            let auxSelectedEstablishments = selectedEstablishments.slice(0)
            auxSelectedEstablishments.splice(indx, 1)
            setSelectedEstablishments(auxSelectedEstablishments)
        }

        useEffect(() => {
            let auxArray = []
            for (let est of selectedEstablishments) {
                auxArray.push(establishmentOptions.find(e => +e.id === +est))
            }
            setArrayUSFs(auxArray)
        }, [selectedEstablishments])

        return <div>
            <div className="flex items-end mb-4">
                <SelectInput
                    options={establishmentOptions.map(e => <option key={e.id} value={e.id}>{e.name}</option>)}
                    className={ageInputStyle}
                    handleChange={(field, value) => setInput(value)}
                    value={input}
                    label={'Establecimiento'}
                    labelClassName={labelStyle}
                    containerStyle={'w-60 mr-4'}
                />
                <button
                    onClick={() => { addEstablishment(+input) }}
                    type="button"
                    className={`mb-px p-2 rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                >
                    <PlusIcon className="h-5 w-5" aria-hidden="true" />
                </button>
            </div>

            <div className="w-full mb-8">
                {arrayUSFs.map(usf => <div className="py-3 text-gray-700 text-xs flex justify-between items-center border-b border-gray-300">
                    <div>{usf?.name}</div>
                    <button
                        type="button"
                        className="text-red-600 hover:text-red-900"
                        onClick={() => removeEstablishment(usf.id)}
                    >
                        <TrashIcon className="h-4 w-4" aria-hidden="true" />
                    </button>
                </div>)}
                {!!arrayUSFs && arrayUSFs.length === 0 && <div className="text-gray-500 text-xs mt-8 mx-4">
                    Agregá establecimientos a filtrar...
                </div>}
            </div>

            <div className="flex w-full justify-between">
                <button
                    onClick={() => { setEstablishment([]); close() }}
                    type="button"
                    className={`${buttonBaseStyle} text-gray-700 bg-white border-gray-300 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700`}
                >
                    Borrar filtros
                </button>
                <button
                    onClick={() => { setEstablishment(selectedEstablishments); close() }}
                    type="button"
                    className={`${buttonBaseStyle} text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                >
                    Aplicar filtro
                </button>
            </div>
        </div>
    }

    const UniqueEstablishmentPopUp = function ({ props }) {

        const usfOptions = establishments.slice(0)

        const [selectedUsf, setSelectedUsf] = useState(filterUniqueEstablishment)

        const close = props.close

        return <div>
            <div className="mb-8 space-y-5">
                <SelectInput
                    options={usfOptions.map(e => <option key={e.id} value={e.id}>{e.name}</option>)}
                    className={ageInputStyle}
                    handleChange={(field, value) => setSelectedUsf(value)}
                    value={selectedUsf}
                    label={'Establecimiento'}
                    labelClassName={labelStyle}
                    containerStyle={'w-60'}
                />
            </div>

            <div className="flex flex-wrap justify-between w-30 sm:w-60 space-y-2 sm:space-y-0">
                <button
                    onClick={() => { setUniqueEstablishment(defaultEstablishment); close() }}
                    type="button"
                    className={`${buttonBaseStyle} text-gray-700 bg-white border-gray-300 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700`}
                >
                    Por defecto
                </button>
                <button
                    onClick={() => { setUniqueEstablishment(selectedUsf); close() }}
                    type="button"
                    className={`${buttonBaseStyle} text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                >
                    Aplicar filtro
                </button>
            </div>
        </div>
    }

    const DxPopUp = function ({ props }) {

        const dxOptions = [
            {
                val: '',
                label: 'Cualquier diagnóstico'
            },
            {
                val: 0,
                label: 'Diabetes'
            },
            {
                val: 1,
                label: 'HTA'
            },
            {
                val: 2,
                label: 'Diabetes + HTA'
            }
        ]

        const [selectedDx, setSelectedDx] = useState(filterDxs)

        const close = props.close

        return <div>
            <div className="mb-8 space-y-5">
                <SelectInput
                    options={dxOptions.map(e => <option key={e.val} value={e.val}>{e.label}</option>)}
                    className={ageInputStyle}
                    handleChange={(field, value) => setSelectedDx(value)}
                    value={selectedDx}
                    label={'Diagnóstico'}
                    labelClassName={labelStyle}
                    containerStyle={'w-60'}
                />
            </div>

            <div className="flex flex-wrap justify-between w-30 sm:w-60 space-y-2 sm:space-y-0">
                <button
                    onClick={() => { setDxs(''); close() }}
                    type="button"
                    className={`${buttonBaseStyle} text-gray-700 bg-white border-gray-300 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700`}
                >
                    Borrar filtros
                </button>
                <button
                    onClick={() => { setDxs(selectedDx); close() }}
                    type="button"
                    className={`${buttonBaseStyle} text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                >
                    Aplicar filtro
                </button>
            </div>
        </div>
    }

    // mx means measurements
    const MxPopUp = function ({ props }) {

        const mxOptions = [
            {
                val: '',
                label: 'Todas'
            },
            {
                val: 0,
                label: 'Presión'
            },
            {
                val: 1,
                label: 'Glucosa'
            },
            {
                val: 2,
                label: 'Hba1C'
            },
            {
                val: 3,
                label: 'Colesterol'
            },
            {
                val: 4,
                label: 'Creatinina'
            },
            {
                val: 5,
                label: 'Triglicéridos'
            }
        ]

        const [selectedMx, setSelectedMx] = useState(filterMxs)

        const close = props.close

        return <div>
            <div className="mb-8 space-y-5">
                <SelectInput
                    options={mxOptions.map(e => <option key={e.val} value={e.val}>{e.label}</option>)}
                    className={ageInputStyle}
                    handleChange={(field, value) => setSelectedMx(value)}
                    value={selectedMx}
                    label={'Tipo de medida'}
                    labelClassName={labelStyle}
                    containerStyle={'w-60'}
                />
            </div>

            <div className="flex flex-wrap justify-between w-30 sm:w-60 space-y-2 sm:space-y-0">
                <button
                    onClick={() => { setMxs(''); close() }}
                    type="button"
                    className={`${buttonBaseStyle} text-gray-700 bg-white border-gray-300 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700`}
                >
                    Borrar filtros
                </button>
                <button
                    onClick={() => { setMxs(selectedMx); close() }}
                    type="button"
                    className={`${buttonBaseStyle} text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                >
                    Aplicar filtro
                </button>
            </div>
        </div>
    }

    const PatientPopUp = function ({ props }) {

        const patientTypeOptions = [
            {
                val: '',
                label: 'Cualquiera'
            },
            {
                val: 0,
                label: 'Nuevos pacientes'
            },
            {
                val: 1,
                label: 'Total visitantes'
            }
        ]

        const [selectedPatient, setSelectedPatient] = useState(filterPatientType)

        const close = props.close

        return <div>
            <div className="mb-8 space-y-5">
                <SelectInput
                    options={patientTypeOptions.map(e => <option key={e.val} value={e.val}>{e.label}</option>)}
                    className={ageInputStyle}
                    handleChange={(field, value) => setSelectedPatient(value)}
                    value={selectedPatient}
                    label={'Tipo de paciente'}
                    labelClassName={labelStyle}
                    containerStyle={'w-60'}
                />
            </div>

            <div className="flex flex-wrap justify-between w-30 sm:w-60 space-y-2 sm:space-y-0">
                <button
                    onClick={() => { setPatientType(''); close() }}
                    type="button"
                    className={`${buttonBaseStyle} text-gray-700 bg-white border-gray-300 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700`}
                >
                    Borrar filtros
                </button>
                <button
                    onClick={() => { setPatientType(selectedPatient); close() }}
                    type="button"
                    className={`${buttonBaseStyle} text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                >
                    Aplicar filtro
                </button>
            </div>
        </div>
    }

    const TabContent = function () {
        if (currentTab === tabs[0].id) { // graphs
            return <GraphsTab graphsData={graphsData} groups={graphsGroups} selectedGroup={selectedGroup} />
        } else if (currentTab === tabs[1].id) { // table
            return <TableTab tableData={tableData} tables={tables} isStaff={isStaff} selectedTable={selectedTable} establishments={establishments} />
        } else if (currentTab === tabs[2].id) { // data
            return <DataTab type={selectedRawData} filters={{ date_min: filterFromDate, date_max: filterToDate, establishment: filterUniqueEstablishment }} />
        } else {
            return <div className="p-5 flex flex-wrap gap-y-14">
                Error
            </div>
        }
    }

    return (
        <div>
            <Sidebar currentScreen={'Reportes'} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="md:pl-64 flex flex-col h-screen flex-1">
                {/* filters modal (i have to call it on a function instead of using tag <FiltersModal /> bc animation bugs*/}
                {/*FiltersModal()*/}

                <main className="flex-1 bg-gray-100">
                    {loadingData ?
                        <div style={{ height: '100vh' }} className="flex flex-1 items-center justify-center">
                            <div className="w-16 h-16 border-b-2 border-blue-900 rounded-full animate-spin"></div>
                        </div> :
                        <>
                            <div className="bg-gray-100 z-40 sticky top-0">
                                <div className="pl-5 md:pl-10 flex py-3 md:py-4 items-center bg-gray-100">
                                    <button
                                        type="button"
                                        className="pr-3 border-r border-gray-200 text-gray-800 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden mr-6"
                                        onClick={() => setSidebarOpen(true)}
                                    >
                                        <span className="sr-only">Open sidebar</span>
                                        <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                    <div className="flex flex-wrap space-x-4 md:space-x-8 space-y-1.5">
                                        {tabs.map(t => <Tab text={t.name} current={currentTab === t.id} onClick={() => setTab(t.id)} />)}
                                    </div>
                                </div>
                                <div>
                                    <div className="bg-white px-8 flex flex-wrap py-2 items-baseline ">
                                        {currentTab === 'data' && <div className="pr-6 pb-1 border-r border-gray-200 mr-6">
                                            <SelectInput
                                                options={typesOfRawData.map(rd => <option key={`data-${rd.id}`} value={rd.id}>{rd.name}</option>)}
                                                className={'bg-blue-100 rounded-lg py-1.5 px-5 text-gray-700 font-bold border-none w-80'}
                                                handleChange={(field, value) => setSelectedRawData(value)}
                                                value={selectedRawData}
                                            />
                                        </div>}
                                        {isStaff && currentTab === 'table' && <div className="pr-6 pb-1 border-r border-gray-200 mr-6">
                                            <SelectInput
                                                options={tables.map(g => <option key={`table-${g.id}`} value={g.id}>{g.name}</option>)}
                                                className={'bg-blue-100 rounded-lg py-1.5 px-5 text-gray-700 font-bold border-none w-55'}
                                                handleChange={(field, value) => setSelectedTable(value)}
                                                value={selectedTable}
                                            />
                                        </div>}
                                        <Popover.Group as="nav" className="flex flex-1 flex-wrap space-x-10">
                                            {(currentTab === 'graphs' || (currentTab === 'table' && selectedTable === 'indicators') || (currentTab === 'data' && selectedRawData === 'consultations')) && <DropdownPop text={'Fecha'} PanelComponent={DatePopUp} />}
                                            {isStaff && currentTab === 'graphs' && <DropdownPop text={'Establecimiento'} PanelComponent={EstablishmentPopUp} />}
                                            {isStaff && ((currentTab === 'table' && selectedTable === 'month') || (currentTab === 'data' && selectedRawData === 'patient-consultations')) && <DropdownPop text={'Establecimiento'} PanelComponent={UniqueEstablishmentPopUp} />}
                                            {currentTab === 'graphs' && selectedGroup !== 'measurements' && <DropdownPop text={'Dx'} PanelComponent={DxPopUp} />}
                                            {currentTab === 'graphs' && selectedGroup === 'measurements' && <DropdownPop text={'Medida'} PanelComponent={MxPopUp} />}
                                            {currentTab === 'graphs' && selectedGroup === 'demographic' && <DropdownPop text={'Tipo de paciente'} PanelComponent={PatientPopUp} />}
                                        </Popover.Group>
                                        {currentTab === 'table' && <button
                                            type="button"
                                            className="ml-3 my-1 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            onClick={() => downloadCurrent(selectedTable)}
                                        >
                                            {loadingDownload ?
                                                <div className="px-10">
                                                    <div className="w-5 h-5 border-b-2 border-white rounded-full animate-spin"></div>
                                                </div> :
                                                'Descargar datos'
                                            }
                                        </button>}
                                        {currentTab === 'graphs' && <SelectInput
                                            options={graphsGroups.map(g => <option key={`graphs-${g.id}`} value={g.id}>{g.name}</option>)}
                                            className={'bg-blue-100 rounded-lg py-1.5 px-5 text-gray-700 font-bold border-none w-68'}
                                            handleChange={(field, value) => setSelectedGroup(value)}
                                            value={selectedGroup}
                                        />}
                                    </div>
                                    {<div className="flex flex-wrap w-auto gap-x-3 gap-y-3 px-7 py-4">
                                        {(currentTab === 'graphs' || (selectedTable === 'indicators' && currentTab === 'table') || (selectedRawData === 'consultations' && currentTab === 'data')) && filterFromDate ? <Tag text={`Fecha de registro desde: ${filterFromDate}`} handleClose={filterFromDate !== '2022-01-01' ? () => setDates('2022-01-01', filterToDate) : null} /> : null}
                                        {(currentTab === 'graphs' || (selectedTable === 'indicators' && currentTab === 'table') || (selectedRawData === 'consultations' && currentTab === 'data')) && (filterToDate && filterToDate !== dateToday) && <Tag text={`Fecha de registro hasta: ${filterToDate}`} handleClose={() => setDates(filterFromDate, dateToday)} />}
                                        {currentTab === 'graphs' && establishmentOptions.length > 1 && !!filterEstablishment && filterEstablishment.map(e => <Tag text={establishmentOptions[+e]?.name} handleClose={isStaff ? () => setEstablishment(filterEstablishment.filter(es => +es !== +e)) : null} />)}
                                        {((currentTab === 'table' && selectedTable === 'month') || (currentTab === 'data' && selectedRawData === 'patient-consultations')) && establishmentOptions.length > 1 && <Tag text={establishmentOptions[+filterUniqueEstablishment]?.name} />}
                                        {currentTab === 'graphs' && selectedGroup !== 'measurements' && filterDxs && +filterDxs === 0 ? <Tag text="DM" handleClose={() => setDxs('')} /> : null}
                                        {currentTab === 'graphs' && selectedGroup !== 'measurements' && +filterDxs === 1 ? <Tag text="HTA" handleClose={() => setDxs('')} /> : null}
                                        {currentTab === 'graphs' && selectedGroup !== 'measurements' && +filterDxs === 2 ? <Tag text="DM + HTA" handleClose={() => setDxs('')} /> : null}
                                        {currentTab === 'graphs' && selectedGroup === 'measurements' && filterMxs && +filterMxs === 0 ? <Tag text="Presión" handleClose={() => setMxs('')} /> : null}
                                        {currentTab === 'graphs' && selectedGroup === 'measurements' && +filterMxs === 1 ? <Tag text="Glucosa" handleClose={() => setMxs('')} /> : null}
                                        {currentTab === 'graphs' && selectedGroup === 'measurements' && +filterMxs === 2 ? <Tag text="Hba1C" handleClose={() => setMxs('')} /> : null}
                                        {currentTab === 'graphs' && selectedGroup === 'measurements' && +filterMxs === 3 ? <Tag text="Colesterol" handleClose={() => setMxs('')} /> : null}
                                        {currentTab === 'graphs' && selectedGroup === 'measurements' && +filterMxs === 4 ? <Tag text="Creatinina" handleClose={() => setMxs('')} /> : null}
                                        {currentTab === 'graphs' && selectedGroup === 'measurements' && +filterMxs === 5 ? <Tag text="Triglicéridos" handleClose={() => setMxs('')} /> : null}
                                        {currentTab === 'graphs' && selectedGroup === 'demographic' && filterPatientType && +filterPatientType === 0 ? <Tag text="Nuevos pacientes" handleClose={() => setPatientType('')} /> : null}
                                        {currentTab === 'graphs' && selectedGroup === 'demographic' && +filterPatientType === 1 ? <Tag text="Total visitantes" handleClose={() => setPatientType('')} /> : null}
                                    </div>}
                                </div>
                            </div>
                            <TabContent />
                        </>
                    }
                </main>
            </div>
        </div >
    );
}
