import React, { useContext, useEffect } from "react";
import { Fragment, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react'
import { Link } from "react-router-dom";
import {
    CalendarIcon,
    ChartBarIcon,
    MenuAlt2Icon,
    OfficeBuildingIcon,
    UserCircleIcon,
    UserGroupIcon,
    CheckCircleIcon,
    UserIcon,
    ClockIcon,
    BeakerIcon,
    AcademicCapIcon,
    LocationMarkerIcon as LightLocationMarkerIcon
} from '@heroicons/react/outline'
import { SearchIcon, LocationMarkerIcon, CakeIcon, OfficeBuildingIcon as OfficeBuildingIconSolid } from '@heroicons/react/solid'
import api from "../../api";
import Sidebar from "../components/Sidebar";
import Breadcrumbs from "../components/Breadcrumbs";
import { DataContext } from "../../data";

const navigation = [
    { name: 'Pacientes', href: '/patients', icon: UserGroupIcon, current: true },
    { name: 'Reportes', href: '/reports', icon: ChartBarIcon, current: false },
    { name: 'Establecimientos', href: '/establishments', icon: OfficeBuildingIcon, current: false },
    { name: 'Usuarios', href: '/users', icon: UserCircleIcon, current: false },
    { name: 'Distritos', href: '/districts', icon: LightLocationMarkerIcon, current: false },
    // { name: 'Calendarios', href: '/calendars', icon: CalendarIcon, current: false },
]

const actions = [
    {
        name: 'Nueva consulta',
        background: "bg-green-100",
        href: "/new",
        icon: <CheckCircleIcon className="h-6 w-6 text-green-600" />,
    },
    {
        name: 'Datos del paciente',
        background: "bg-purple-200",
        href: "/profile",
        icon: <UserIcon className="h-6 w-6 text-purple-700" />
    },
    {
        name: 'Historial de consultas',
        background: "bg-blue-200",
        href: "/history",
        icon: <ClockIcon className="h-6 w-6 text-blue-700" />
    },
    /*{
        name: 'Historial de laboratorio',
        background: "bg-green-100",
        href: "/lab-history",
        icon: <BeakerIcon className="h-6 w-6 text-green-600" />
    },
    {
        name: 'Resumen de educación',
        background: "bg-purple-200",
        href: "/education",
        icon: <AcademicCapIcon className="h-6 w-6 text-purple-700" />
    }*/
]

const pages = [
    { name: 'Pacientes', href: '/patients', current: false },
    { name: 'Detalle  del paciente', href: `#`, current: true },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function PatientDetailPage() {

    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [patient, setPatient] = useState()
    const [loading, setLoading] = useState(true)
    const { idPatient } = useParams()

    const { isConnected, patients, loadingPatients, getPatientById } = useContext(DataContext)

    useEffect(() => {
        if (isConnected) {
            api.patients.getOne(idPatient).then((response) => {
                setPatient({
                    name: response.firstName + " " + response.lastName,
                    document: response.govId,
                    location: response.address,
                    last_visit: response.lastVisit,
                    isDiabetic: response.isDiabetic,
                    hasHta: response.hasHta,
                    hasCancer: response.hasCancer,
                    age: response.age,
                    establishment: response.establishment
                });
                setLoading(false);
            })
        }
    }, [])

    useEffect(() => {
        if (!isConnected && !loadingPatients) {
            let response = getPatientById(idPatient)
            setPatient({
                name: response.firstName + " " + response.lastName,
                document: response.govId,
                location: response.address,
                last_visit: response.lastVisit,
                isDiabetic: response.isDiabetic,
                hasHta: response.hasHta,
                hasCancer: response.hasCancer,
                age: response.age,
                establishment: response.establishment
            });
            setLoading(false);
        }
    }, [loadingPatients, patients])

    return (
        <div className="bg-gray-50 h-screen">
            <Sidebar currentScreen={'Pacientes'} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            {loading ?
                <div className="flex h-full items-center justify-center ">
                    <div className="w-16 h-16 border-b-2 border-blue-900 rounded-full animate-spin"></div>
                </div> :
                <div className="md:pl-64 flex flex-col bg-gray-100 flex-1">
                    <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-100">
                        <button
                            type="button"
                            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                            onClick={() => setSidebarOpen(true)}
                        >
                            <span className="sr-only">Open sidebar</span>
                            <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>

                    <div className="flex flex-col flex-1 pb-4 ml-6 mt-6">
                        <Breadcrumbs pages={pages} />
                        <div className="ml-4">
                            <h1 className="mt-6 text-3xl font-bold leading-9 text-gray-900">{patient?.name}</h1>
                            <div className="flex flex-wrap items-center mt-2 gap-y-1">
                                <div>
                                    <span className="text-sm leading-5 font-medium text-gray-500">C.I. {patient?.document}</span>
                                </div>
                                <div className="ml-6">
                                    <OfficeBuildingIconSolid className="inline-block h-5 w-5 text-gray-500" />
                                    <span className="text-sm leading-5 ml-2 font-medium text-gray-500">
                                        {patient?.establishment}
                                    </span>
                                </div>
                                {patient.location ?
                                    <div className="ml-6">
                                        <LocationMarkerIcon className="inline-block h-5 w-5 text-gray-500" />
                                        <span className="text-sm leading-5 ml-2 font-medium text-gray-500">
                                            {patient.location}
                                        </span>
                                    </div>
                                    : null
                                }
                                <div className="ml-6">
                                    <CakeIcon className="inline-block h-5 w-5 text-gray-500" />
                                    <span className="text-sm leading-5 ml-2 font-medium text-gray-500">
                                        {patient?.age} años
                                    </span>
                                </div>
                                <div className="ml-4">
                                    <span className="text-sm leading-5font-medium text-gray-500">| </span>
                                    <span className="text-sm leading-5 ml-4 font-medium] text-gray-500">Dx: </span>

                                    {patient?.isDiabetic ? <span className="ml-4 inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800">
                                        DM
                                    </span>
                                        : null}
                                    {patient?.hasHta ? <span className="ml-4 inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-yellow-100 text-yellow-800">
                                        HTA
                                    </span>
                                        : null}
                                    {patient?.hasCancer ? <span className="ml-4 inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-pink-100 text-pink-800">
                                        Cancer
                                    </span>
                                        : null}
                                </div>
                                <div className="ml-4">
                                    <span className="text-sm leading-5font-medium text-gray-500">| </span>
                                    <span className="text-sm leading-5 ml-4 font-medium text-gray-500">Última visita: {patient?.last_visit ? patient.last_visit : '--'} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <main className="flex-1 w-full bg-gray-50">
                        <div className="max-w-7xl mt-16 px-4 sm:px-6 md:px-8">
                            <div className="py-4 w-3/4">
                                <div className="grid grid-cols-1 justify-center items-center gap-4 sm:grid-cols-2">
                                    {actions.map((action) => (
                                        <div
                                            key={action.name}
                                            className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center gap-x-3 hover:border-gray-400"
                                        >
                                            <div className={`flex-shrink-0 rounded p-2 ${action.background}`}>
                                                {action.icon}
                                            </div>
                                            <div className="flex-1 min-w-0">
                                                <Link to={`/patients/${idPatient}${action.href}`} className="focus:outline-none">
                                                    <span className="absolute inset-0" aria-hidden="true" />
                                                    <p className="text-sm font-medium text-gray-900">{action.name}</p>
                                                </Link>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            }
        </div >
    );
}
