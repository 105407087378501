import React from "react";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function InlineCheckbox(props) {

    return (
        <div className={props.containerClassName ? props.containerClassName : "relative flex pr-2 items-start"}>
            <div className="flex items-center h-5">
                <input
                    defaultChecked={props.checked}
                    onChange={(event) => props.handleChange(props.name, event.target.checked)}
                    id={props.name}
                    aria-describedby={`${props.name}-description`}
                    name={props.name}
                    type="checkbox"
                    value={props.value}
                    className={props.className ? props.className : "focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"}
                />
            </div>
            <div className="ml-3 text-sm">
                <label htmlFor={props.name} className={props.labelClassName ? props.labelClassName : "font-medium text-gray-700"}>
                    {props.label}
                </label>
                {props.description ? <p id={`${props.name}-description`} className="text-gray-500">
                    {props.description}
                </p>
                    : null}
            </div>
        </div>
    )

}