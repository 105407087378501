import {
    CalendarIcon,
    ChartBarIcon,
    OfficeBuildingIcon,
    UserCircleIcon,
    UserGroupIcon,
    LocationMarkerIcon as LightLocationMarkerIcon
} from '@heroicons/react/outline'

export const OnlineNavigation = function (current) {
    return [
        { name: 'Pacientes', href: '/patients', icon: UserGroupIcon, current: current === 'Pacientes' },
        { name: 'Reportes', href: '/reports', icon: ChartBarIcon, current: current === 'Reportes' },
        { name: 'Establecimientos', href: '/establishments', icon: OfficeBuildingIcon, current: current === 'Establecimientos' },
        { name: 'Usuarios', href: '/users', icon: UserCircleIcon, current: current === 'Usuarios' },
        { name: 'Distritos', href: '/districts', icon: LightLocationMarkerIcon, current: current === 'Distritos' },
    ]
}

export const OnlineNoStaffNavigation = function (current) {
    return [
        { name: 'Pacientes', href: '/patients', icon: UserGroupIcon, current: current === 'Pacientes' },
        { name: 'Reportes', href: '/reports', icon: ChartBarIcon, current: current === 'Reportes' }
    ]
}

export const OfflineNavigation = function (current) {
    return [
        { name: 'Pacientes', href: '/patients', icon: UserGroupIcon, current: current === 'Pacientes' }
    ]
}