import React, { useContext, useEffect, useState } from "react";
import {
    ChartBarIcon,
    LocationMarkerIcon,
    MenuAlt2Icon,
    OfficeBuildingIcon,
    UserCircleIcon,
    UserGroupIcon,
    CalendarIcon
} from '@heroicons/react/outline'
import api from "../../api";
import Sidebar from "../components/Sidebar"
import Breadcrumbs from "../components/Breadcrumbs";
import { useNavigate, useParams } from "react-router-dom";
import FormNewConsultation from "../components/FormNewConsultation";
import DateInput from "../components/DateInput";
import SelectInput from "../components/SelectInput";
import { DataContext } from '../../data';


let tabs = [
    { name: 'Determinaciones', current: true },
    { name: 'Control anual', current: false },
    { name: 'Medicamentos', current: false },
    { name: 'Laboratorio', current: false },
    { name: 'Hábitos', current: false },
    { name: 'Educación', current: false },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function ConsultationNewPage() {

    const { isConnected, establishments, loadingEstablishments, places, loadingPlaces, getPatientById, loadingPatients, addConsultation } = useContext(DataContext)

    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [submitError, setSubmitError] = useState('')
    const { idPatient } = useParams()
    const [tab, setTab] = useState('Determinaciones')
    const [patient, setPatient] = useState([])
    const [establishment, setEstablishment] = useState(localStorage.getItem('establishment'))
    const [theEstablishments, setTheEstablishments] = useState([])
    const [thePlaces, setThePlaces] = useState([])
    const navigate = useNavigate();
    const [consultation, setConsultation] = useState({
        patient: idPatient,
        attentionDate: new Date().toISOString().substring(0, 10),
        place: 1,
        establishment: localStorage.getItem('establishment') ? localStorage.getItem('establishment') : 1,
        weight: '',
        height: '',
        waist: '',
        systolic: '',
        diastolic: '',
        bloodGlucose: '',
        lastMeal: "",
        poliuria: false,
        polidipsia: false,
        polifagia: false,
        weightloss: false,
        obsBloodGlucose: "",
        diabeticFoot: "0",
        diabeticFootObs: "",
        diabeticFootDerive: false,
        diabeticFootReferToLab: false,
        fundus: "0",
        fundusObs: "",
        fundusDerive: false,
        urineAnalysis: "0",
        urineAnalysisObs: "",
        urineAnalysisReferToLab: "",
        nextVisit: new Date().toISOString().substring(0, 10),
        basalGlucose: '',
        totalCholesterol: '',
        hba1c: '',
        ldlCol: '',
        hdlCol: '',
        triglycerides: '',
        creatinine: '',
        addSaltWhenCooking: false,
        addSaltToFood: false,
        fruitServingsPerDay: "",
        vegetableServingsPerDay: "",
        performPhysicalActivity: false,
        physicalActivityType: "",
        physicalActivityDuration: "",
        physicalActivityFrequency: "",
        smoke: false,
        cigarettesPerDay: "",
        timeOfTheFirstCigarette: 0,
        vape: false,
        wantToQuitSmoking: false,
        drinkAlcoholicBeverages: false,
        drinksPerDay: "",
        drinksPerWeek: "",
        hasDmMedication: false,
        dmMedications: [],
        hasHtaMedication: false,
        htaMedications: [],
        otherDmMedication: "",
        otherDmMedicationObs: "",
        otherHtaMedication: "",
        otherHtaMedicationObs: "",
        nutritionEducation: false,
        physicalActivityEducation: false,
        smokingEducation: false,
        alcoholicBeveragesEducation: false,
        importanceHtaMedication: false,
        importanceDmMedication: false,
        etcEducation: ""
    })
    const [errors, setErrors] = useState({
        attentionDate: "",
        weight: "",
        height: "",
        waist: "",
        systolic: "",
        diastolic: "",
        locations: ""

    });
    const handleChange = (field, value) => setConsultation((prev) => ({ ...prev, [field]: value }));
    const handleError = (field, value) => setErrors((prev) => ({ ...prev, [field]: value }))
    const addDmMedication = () => setConsultation((prev) => ({ ...prev, dmMedications: [...prev.dmMedications, { medicine: '0', otherMedicine: '', indications: '' }] }))
    const addHtaMedication = () => setConsultation((prev) => ({ ...prev, htaMedications: [...prev.htaMedications, { medicine: '0', otherMedicine: '', indications: '' }] }))
    const removeDmMedication = (indx) => {
        let auxMeds = consultation.dmMedications.slice(0)
        auxMeds.splice(indx, 1)
        setConsultation({ ...consultation, dmMedications: [] })
        // FORCING re render of INPUTS, this was the only solution that i found
        setTimeout(function () {
            setConsultation({ ...consultation, dmMedications: auxMeds })
        }, 50)
    }
    const removeHtaMedication = (indx) => {
        let auxMeds = consultation.htaMedications.slice(0)
        auxMeds.splice(indx, 1)
        setConsultation({ ...consultation, htaMedications: [] })
        // FORCING re render of INPUTS, this was the only solution that i found
        setTimeout(function () {
            setConsultation({ ...consultation, htaMedications: auxMeds })
        }, 50)
    }

    const handleDmMedication = (field, value, Idx) => {
        let obj = consultation.dmMedications
        console.log(Idx)
        obj[Idx][field] = value
        setConsultation((prev) => ({ ...prev, dmMedications: obj }))
    }

    const handleHtaMedication = (field, value, Idx) => {
        let obj = consultation.htaMedications
        obj[Idx][field] = value
        setConsultation((prev) => ({ ...prev, htaMedications: obj }))
    }

    useEffect(() => {
        if (isConnected) {
            api.patients.getOne(idPatient).then((response) => {
                setPatient({
                    ...response,
                    name: response.firstName + " " + response.lastName,
                });
            }, err => {
                console.log('Error al traer pacientes: ', err)
                handleError('locations', 'Hubo un error al obtener los lugares de consulta')
            }).then(
                api.establishments.getMulti().then((response) => {
                    setTheEstablishments(response.results)
                }, err => {
                    console.log('Error al traer pacientesss: ', err)
                })
            ).then(
                api.places.getMulti().then((response) => {
                    setThePlaces(response.results.map(({ id, name }) => {
                        return (
                            <option label={name} value={id} >{name}</option>
                        )
                    }
                    ))
                    setLoading(false);
                }, err => {
                    console.log('Error al traer pacientes: ', err)
                    setLoading(false);
                })
            )
        }
    }, [])

    useEffect(() => {
        if (!isConnected && !loadingPlaces && !loadingPatients && !loadingEstablishments) {
            const currentPatient = getPatientById(idPatient)
            setPatient({
                ...currentPatient,
                name: currentPatient.firstName + " " + currentPatient.lastName,
            });
            setTheEstablishments(establishments)
            setThePlaces(places.map(({ id, name }) => {
                return (
                    <option label={name} value={id} >{name}</option>
                )
            }))
            setLoading(false)
        }
    }, [loadingPlaces, loadingPatients, loadingEstablishments])

    useEffect(() => {
        if (consultation.weight && consultation.height) {
            handleChange('imc', Math.round((consultation.weight / ((consultation.height / 100) * (consultation.height / 100))) * 100) / 100)
        } else {
            handleChange('imc', '-')
        }
    }, [consultation.weight, consultation.height])

    useEffect(() => {
        tabs[tabs.findIndex((tab) => tab.current)].current = false
        tabs[tabs.findIndex((tab) => tab.name === 'Determinaciones')].current = true
    }, [])

    const pages = [
        { name: 'Pacientes', href: '/patients', current: false },
        { name: 'Detalle del paciente', href: `/patients/${idPatient}`, current: false },
        { name: `Nueva consulta - ${patient.name}`, href: '#', current: true },
    ]

    const changeTab = ((value) => {
        let error = false
        if (tabs[tabs.findIndex((tab) => tab.current)].name == 'Determinaciones') {
            if (consultation.attentionDate === '') {
                handleError('attentionDate', 'Este campo es obligatorio')
                error = true
            }
            if (consultation.height === '' && consultation.place !== '3') {
                handleError('height', 'Este campo es obligatorio')
                error = true
            } else {
                handleError('height', '')
            }
            if (consultation.weight === '' && consultation.place !== '3') {
                handleError('weight', 'Este campo es obligatorio')
                error = true
            } else {
                handleError('weight', '')
            }
            if (consultation.systolic === '') {
                handleError('systolic', 'Este campo es obligatorio')
                error = true
            } else {
                handleError('systolic', '')
            }
            if (consultation.diastolic === '') {
                handleError('diastolic', 'Este campo es obligatorio')
                error = true
            } else {
                handleError('diastolic', '')
            }
            if (!error) {
                tabs[tabs.findIndex((tab) => tab.current)].current = false
                tabs[tabs.findIndex((tab) => tab.name === value)].current = true
                setTab(tabs[tabs.findIndex((tab) => tab.current)].name)
            }
        } else {
            tabs[tabs.findIndex((tab) => tab.current)].current = false
            tabs[tabs.findIndex((tab) => tab.name === value)].current = true
            setTab(tabs[tabs.findIndex((tab) => tab.current)].name)
        }
    })

    const handleSubmit = (event) => {
        event.preventDefault()
        let error = false
        if (consultation.attentionDate === '') {
            handleError('attentionDate', 'Este campo es obligatorio')
            error = true
        }
        if (consultation.attentionDate === '') {
            handleError('attentionDate', 'Este campo es obligatorio')
            error = true
        }
        if (consultation.height === '' && consultation.place !== '3') {
            handleError('height', 'Este campo es obligatorio')
            error = true
        } else {
            handleError('height', '')
        }
        if (consultation.weight === '' && consultation.place !== '3') {
            handleError('weight', 'Este campo es obligatorio')
            error = true
        } else {
            handleError('weight', '')
        }
        if (consultation.systolic === '') {
            handleError('systolic', 'Este campo es obligatorio')
            error = true
        } else {
            handleError('systolic', '')
        }
        if (consultation.diastolic === '') {
            handleError('diastolic', 'Este campo es obligatorio')
            error = true
        } else {
            handleError('diastolic', '')
        }
        let dm = consultation.dmMedications
        while (dm.findIndex((element) => element.medicine === '') !== -1) {
            dm[dm.findIndex((element) => element.medicine === '')].medicine = null
        }
        handleChange('dmMedications', dm)
        let hta = consultation.htaMedications
        while (hta.findIndex((element) => element.medicine === '') !== -1) {
            hta[hta.findIndex((element) => element.medicine === '')].medicine = null
        }
        handleChange('htaMedications', hta)
        console.log('error: ', error)
        if (!error) {
            setLoadingSubmit(true)
            setSubmitError('')
            addConsultation({
                ...consultation,
                height: consultation.height === '' ? 0 : consultation.height,
                weight: consultation.weight === '' ? 0 : consultation.weight,
                waist: consultation.waist === '' ? 0 : consultation.waist,
                systolic: consultation.systolic === '' ? 0 : consultation.systolic,
                diastolic: consultation.diastolic === '' ? 0 : consultation.diastolic,
                bloodGlucose: consultation.bloodGlucose === '' ? 0 : consultation.bloodGlucose,
                basalGlucose: consultation.basalGlucose === '' ? 0 : consultation.basalGlucose,
                totalCholesterol: consultation.totalCholesterol === '' ? 0 : consultation.totalCholesterol,
                hba1c: consultation.hba1c === '' ? 0 : consultation.hba1c,
                ldlCol: consultation.ldlCol === '' ? 0 : consultation.ldlCol,
                hdlCol: consultation.hdlCol === '' ? 0 : consultation.hdlCol,
                triglycerides: consultation.triglycerides === '' ? 0 : consultation.triglycerides,
                creatinine: consultation.creatinine === '' ? 0 : consultation.creatinine,
            }, function () {
                setLoadingSubmit(false)
                navigate(`/patients/${idPatient}`)
            }, function (err) {
                setLoadingSubmit(false)
                setSubmitError(err)
                console.log(err)
            })
        }
    }

    return (
        <div className="bg-gray-100 min-h-screen">
            <Sidebar currentScreen={'Pacientes'} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="md:pl-64 flex flex-col flex-1">
                <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-100">
                    <button
                        type="button"
                        className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                        onClick={() => setSidebarOpen(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                {loading ?
                    <div className="flex h-screen mt-auto items-center justify-center ">
                        <div className="w-16 h-16 border-b-2 border-blue-900 rounded-full animate-spin"></div>
                    </div> :
                    <>
                        <div className="flex flex-wrap sm:flex-nowrap justify-between flex-1 sm:ml-6 mt-6 gap-y-4">
                            <Breadcrumbs pages={pages} />
                            <button
                                type="button"
                                onClick={(event) => handleSubmit(event)}
                                className="mr-8 ml-4 sm:ml-0 items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                {loadingSubmit ?
                                    <div className="w-5 h-5 mx-5 border-b-2 border-white rounded-full animate-spin"></div> :
                                    'Finalizar'
                                }
                            </button>
                        </div>
                        {submitError && <div className="px-12 mt-4 text-sm text-red-600 text-right">
                            {submitError}
                        </div>}
                        <main className="flex flex-1 flex-col lg:flex-row">
                            <div className="flex py-5 lg:py-16">
                                <div className="max-w-7xl flex flex-row gap-x-5 gap-y-2 flex-wrap lg:flex-col lg:flex-nowrap items-start mx-auto px-4 sm:px-6 md:px-8">
                                    <DateInput errorMessage={errors.attentionDate} className="h-10 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" defaultValue={new Date().toISOString().substring(0, 10)} label="Fecha de consulta" handleChange={handleChange} name="attentionDate" icon={true} />
                                    <SelectInput errorMessage={errors.locations} className="shadow-sm h-10 w-48 mb-2 border border-gray-300 block sm:text-sm rounded-md" handleChange={handleChange} label="Lugar de consulta" name="place" options={thePlaces} />
                                    {/* <SelectInput value={consultation.establishment} containerStyle="mt-2" className="shadow-sm h-10 w-48 border border-gray-300 block sm:text-sm rounded-md" handleChange={handleChange} label="Establecimiento" name="establishment" options={theEstablishments.map((element) => { return (<option label={element.name} value={element.id} />) })} /> */}
                                    <div>
                                        <label className="text-sm font-medium text-gray-700">Establecimiento</label>
                                        <p className="mt-2 text-sm font-medium text-gray-900">
                                            {theEstablishments[theEstablishments.findIndex(e => +e.id === +establishment)]?.name}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-8 w-full">
                                <div className="sm:hidden mx-4 mb-4">
                                    <label htmlFor="tabs" className="sr-only">
                                        Seleccione una página
                                    </label>
                                    {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                                    <select
                                        onChange={(event) => changeTab(event.target.value)}
                                        id="tabs"
                                        name="tabs"
                                        className="block w-full focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                                        defaultValue={tabs.find((tab) => tab.current).name}
                                    >
                                        {tabs.map((tab) => (
                                            <option key={tab.name}>{tab.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="hidden sm:block mx-4 mb-4">
                                    <nav className="flex flex-wrap gap-x-4 gap-y-2" aria-label="Tabs">
                                        {tabs.map((tab) => (
                                            <button
                                                type="button"
                                                key={tab.name}
                                                className={classNames(
                                                    tab.current ? 'bg-blue-100 text-blue-700' : 'text-gray-500 hover:text-gray-700',
                                                    'px-3 py-2 font-medium text-sm rounded-md'
                                                )}
                                                onClick={() => changeTab(tab.name)}
                                                aria-current={tab.current ? 'page' : undefined}
                                            >
                                                {tab.name}
                                            </button>
                                        ))}
                                    </nav>
                                </div>
                                <div className="mx-4 lg:ml-0">
                                    <div style={{ maxWidth: 850 }} className="bg-white my-2 max-w-screen shadow border flex-1 border-gray-300 rounded-lg divide-y divide-gray-800">
                                        <form>
                                            <FormNewConsultation
                                                handleSubmit={handleSubmit}
                                                handleChange={handleChange}
                                                loadingSubmit={loadingSubmit}
                                                consultation={consultation}
                                                changeTab={changeTab}
                                                tab={tab}
                                                addDmMedication={addDmMedication}
                                                addHtaMedication={addHtaMedication}
                                                removeDmMedication={removeDmMedication}
                                                removeHtaMedication={removeHtaMedication}
                                                handleDmMedication={handleDmMedication}
                                                handleHtaMedication={handleHtaMedication}
                                                handleError={handleError}
                                                errors={errors}
                                            />
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </>}
            </div>
        </div >
    );
}
