import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from 'react-router-dom'
import KoicaLogo from '../assets/images/koica-logo-blue.png';
import MinisterioLogo from '../assets/images/logo_Ministerio-de-Salud.png';
import VigilanciaLogo from '../assets/images/logo_Direccion-de-Vigilancia.png';
import YonseiLogo from '../assets/images/logo_Yonsei.png';
import SaludParaTodosLogo from '../assets/images/logo_Salud-Para-Todos.png';
import { useAuth } from '../context/authContext'
import Modal from './components/Modal';
import { Dialog } from "@headlessui/react";
import api from "../api";

export default function LoginPage() {

    const [password, setPassword] = useState('')
    const [password2, setPassword2] = useState('')
    const [errormessage, setErrorMessage] = useState('')
    const { uuid } = useParams()
    const { token } = useParams()

    const navigate = useNavigate();
    const auth = useAuth();

    const handleSubmit = async (event) => {
        event.preventDefault()
        await api.reset_password.changePassword(password, uuid, token)
            .then((response) => {
                navigate('/login');
            })
            .catch((e) => {
                setErrorMessage(e.detail)
                return Promise.reject(e.detail)
            });
    }

    return (
        <div className="min-h-screen h-full flex flex-col justify-center py-12 sm:px-6 bg-gray-50 lg:px-8">
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <img
                    className="h-auto w-48 mr-auto ml-36"
                    src={KoicaLogo}
                    alt="Koica"
                />
                <h2 className="mt-12 text-center text-3xl font-bold leading-9 text-blue-600">Control DM / HTA</h2>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <form className="space-y-6" onSubmit={(event) => handleSubmit(event)}>
                        <div>
                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                Nueva contraseña
                            </label>
                            <div className="mt-1">
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    onChange={(event) => setPassword(event.target.value)}
                                    required
                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="password2" className="block text-sm font-medium text-gray-700">
                                Verificar contraseña
                            </label>
                            <div className="mt-1">
                                <input
                                    id="password2"
                                    name="password2"
                                    type="password"
                                    onChange={(event) => setPassword2(event.target.value)}
                                    required
                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>
                            {errormessage === "" ?
                                null
                                : <div className="bg-red-100 mt-4 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                                    <span className="block sm:inline">Las contraseñas deben ser iguales</span>
                                </div>
                            }
                        </div>

                        <div>
                            <button
                                type="submit"
                                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-800"
                            >
                                Aceptar
                            </button>
                        </div>
                    </form>
                </div>
            </div >
            <div className="mt-12 flex w-screen max-w-md">
                <img
                    className="h-auto w-auto"
                    src={MinisterioLogo}
                    alt="Ministerio"
                />
                <img
                    className="h-auto w-auto"
                    src={VigilanciaLogo}
                    alt="Vigilancia"
                />
                <img
                    className="h-auto w-auto"
                    src={YonseiLogo}
                    alt="Yonsei"
                />
                <img
                    className="h-auto w-auto"
                    src={SaludParaTodosLogo}
                    alt="SaludParaTodos"
                />
            </div>
        </div >
    );
}