import React, { useEffect, useState } from "react";
import InlineCheckbox from "./InlineCheckbox";
import SelectInput from "./SelectInput";
import TextInput from "./TextInput";
import RadioButtons from "./RadioButtons";
import DateInput from "./DateInput";

const MedicalHistory = function (props) {

    const goBack = () => {
        props.setTab('DATOS PERSONALES')
    }

    return (
        <form onSubmit={(event) => props.handleSubmit(event)}>
            <div className="flex-col space-y-4 py-5 sm:p-6">
                <div>
                    <label className="font-medium text-gray-700">Antecedente personal</label>
                </div>
                <div className="flex flex-wrap px-4 gap-y-4 gap-x-32">
                    <InlineCheckbox checked={props.patient.isDiabetic} handleChange={props.handleChange} name="isDiabetic" label="Diabetes" />
                    <InlineCheckbox checked={props.patient.hasHta} handleChange={props.handleChange} name="hasHta" label="Conocido HTA" />
                    <InlineCheckbox checked={props.patient.hasCancer} handleChange={props.handleChange} name="hasCancer" label="Cáncer" />

                </div>
                <div>
                    <span className="mt-2 ml-2 text-sm text-red-600">
                        {props.errors.medicalBackground}
                    </span>
                </div>
                <div className="flex flex-wrap px-4 gap-y-4 gap-x-16">
                    <TextInput onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} errorMessage={props.errors.diabetes} disabled={props.patient.isDiabetic ? false : true} value={props.patient.diabetesDiagnosisYear} name="diabetesDiagnosisYear" handleChange={props.handleChange} label="Año de diagnóstico" placeholder="Opcional" className={props.patient.isDiabetic ? "shadow-sm h-8 pl-2 w-50 border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm rounded-md" : "shadow-sm h-8 pl-2 w-50 border bg-gray-200 border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm rounded-md"} />
                    <TextInput onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} errorMessage={props.errors.hta} disabled={props.patient.hasHta ? false : true} value={props.patient.htaDiagnosisYear} name="htaDiagnosisYear" handleChange={props.handleChange} label="Año de diagnóstico" placeholder="Opcional" className={props.patient.hasHta ? "shadow-sm h-8 pl-2 w-50 border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm rounded-md" : "shadow-sm h-8 pl-2 w-50 border bg-gray-200 border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm rounded-md"} />
                    <TextInput onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} errorMessage={props.errors.cancer} disabled={props.patient.hasCancer ? false : true} value={props.patient.cancerDiagnosisYear} name="cancerDiagnosisYear" handleChange={props.handleChange} label="Año de diagnóstico" placeholder="Opcional" className={props.patient.hasCancer ? "shadow-sm h-8 pl-2 w-50 border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm rounded-md" : "shadow-sm h-8 pl-2 w-50 border bg-gray-200 border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm rounded-md"} />
                    <TextInput errorMessage={props.errors.cancerType} disabled={props.patient.hasCancer ? false : true} value={props.patient.cancerType} name="cancerType" handleChange={props.handleChange} label="Tipo de cáncer" placeholder="Opcional" className={props.patient.hasCancer ? "shadow-sm h-8 pl-2 w-50 border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm rounded-md" : "shadow-sm h-8 pl-2 w-50 border bg-gray-200 border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm rounded-md"} />
                </div>
                <div className="mt-10">
                    <label className="font-medium text-gray-700">Antecedente familiar (opcional)</label>
                </div>
                <div className="flex font-medium text-gray-700">
                    <label className="w-1/6 invisible">Esquina</label>
                    <div className="space-x-16">
                        <label>Diabetes</label>
                        <label>HTA</label>
                        <label>Cáncer</label>
                    </div>
                </div>
                <div className="flex font-medium text-gray-700">
                    <label className="w-1/6">Padre</label>
                    <div className="flex gap-x-24">
                        <InlineCheckbox checked={props.patient.fatherDiabetes} handleChange={props.handleChange} name="fatherDiabetes" />
                        <InlineCheckbox checked={props.patient.fatherHta} handleChange={props.handleChange} name="fatherHta" />
                        <InlineCheckbox checked={props.patient.fatherCancer} handleChange={props.handleChange} name="fatherCancer" className="-mx-5 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                    </div>
                </div>
                <div className="flex font-medium text-gray-700">
                    <label className="w-1/6">Madre</label>
                    <div className="flex gap-x-24">
                        <InlineCheckbox checked={props.patient.motherDiabetes} handleChange={props.handleChange} name="motherDiabetes" />
                        <InlineCheckbox checked={props.patient.motherHta} handleChange={props.handleChange} name="motherHta" />
                        <InlineCheckbox checked={props.patient.motherCancer} handleChange={props.handleChange} name="motherCancer" className="-mx-5 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                    </div>
                </div>
                <div className="flex font-medium text-gray-700">
                    <label className="w-1/6">Hermanos</label>
                    <div className="flex gap-x-24">
                        <InlineCheckbox checked={props.patient.brotherDiabetes} handleChange={props.handleChange} name="brotherDiabetes" />
                        <InlineCheckbox checked={props.patient.brotherHta} handleChange={props.handleChange} name="brotherHta" />
                        <InlineCheckbox checked={props.patient.brotherCancer} handleChange={props.handleChange} name="brotherCancer" className="-mx-5 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                    </div>
                </div>
                <div className="flex font-medium text-gray-700">
                    <label className="w-1/6">Otros</label>
                    <div className="flex gap-x-24">
                        <InlineCheckbox checked={props.patient.otherDiabetes} handleChange={props.handleChange} name="otherDiabetes" />
                        <InlineCheckbox checked={props.patient.otherHta} handleChange={props.handleChange} name="otherHta" />
                        <InlineCheckbox checked={props.patient.otherCancer} handleChange={props.handleChange} name="otherCancer" className="-mx-5 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                    </div>
                </div>
            </div>
            <div className="flex-col-reverse bg-gray-100" >
                <div className="flex p-4 justify-between items-start">
                    <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm bg-white text-blue-600 hover:text-blue-800"
                        onClick={() => goBack()}
                    >
                        Anterior
                    </button>
                    <div className="flex flex-col items-end">
                        <div>
                            <button
                                type="submit"
                                className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                {props.loadingSubmit ?
                                    <div className="w-5 h-5 mx-14 border-b-2 border-white rounded-full animate-spin"></div> :
                                    'Finalizar y guardar'
                                }
                            </button>
                        </div>

                        {props.submitError && <div className="mt-2 ml-2 text-sm text-red-600">
                            {props.submitError}
                        </div>}
                    </div>

                </div>
            </div>
        </form>
    )
}

const PersonalData = function (props) {

    const today = new Date();
    const minBirthdate = new Date(today.setFullYear(today.getFullYear() - 120));
    const [documentInput, setDocumentInput] = useState(props.patient.documentType == '0' ? props.patient.provisionalCode : props.patient.govId);
    const [establishment, setEstablishment] = useState(localStorage.getItem('establishment'))

    const goForward = (event) => {
        event.preventDefault()
        let error = false
        if (!props.patient.firstName) {
            props.handleError('firstName', 'Este campo es obligatorio')
            error = true
        } else {
            props.handleError('firstName', '')
        }
        if (!props.patient.lastName) {
            props.handleError('lastName', 'Este campo es obligatorio')
            error = true
        } else {
            props.handleError('lastName', '')
        }
        if (props.patient.documentType != 0 && !props.patient.govId) {
            props.handleError('govId', 'Este campo es obligatorio')
            error = true
        } else {
            props.handleError('govId', '')
        }
        if (!props.patient.neighbourhood) {
            props.handleError('neighbourhood', 'Este campo es obligatorio')
            error = true
        } else {
            props.handleError('neighbourhood', '')
        }
        if (!props.patient.establishment) {
            props.handleError('establishment', 'Este campo es obligatorio')
            error = true
        } else {
            props.handleError('establishment', '')
        }
        if (!error) {
            props.setTab('ANTECEDENTES')
        }
    }

    return (
        <form onSubmit={(event) => goForward(event)}>
            <div className="flex-col py-5 sm:p-6">
                <div className="flex flex-wrap px-4 gap-x-12 gap-y-5">
                    <TextInput errorMessage={props.errors.firstName} value={props.patient.firstName} handleChange={props.handleChange} name="firstName" label="Nombre" className="shadow-sm h-8 pl-2 w-50 border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm rounded-md" />
                    <TextInput errorMessage={props.errors.lastName} value={props.patient.lastName} handleChange={props.handleChange} name="lastName" label="Apellido" className="shadow-sm h-8 pl-2 w-50 border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm rounded-md" />
                    <SelectInput handleChange={props.handleChange} name="documentType" label="Tipo de documento" options={props.options} className="shadow-sm w-50 py-1.5 border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm rounded-md" />
                    <TextInput errorMessage={props.errors.govId} value={props.patient.documentType == '0' ? props.patient.provisionalCode : props.patient.govId} handleChange={props.handleChange} name={props.patient.documentType == '0' ? 'provisionalCode' : 'govId'} label="N° de identificación" disabled={props.patient.documentType == '0' ? true : false} className={props.patient.documentType == '0' ? "shadow-sm h-8 pl-2 w-50 border border-gray-300 bg-gray-200 block sm:text-sm rounded-md" : "shadow-sm h-8 pl-2 w-50 border border-gray-300 block sm:text-sm rounded-md"} />
                </div>
                <div className="flex flex-wrap gap-x-12 gap-y-5 mt-8 px-4">
                    <RadioButtons defaultValue={props.patient.gender} value={props.patient.gender} handleChange={props.handleChange} name="gender" label="Sexo" options={props.radio} />
                    <DateInput mindate={minBirthdate.toISOString().substring(0, 10)} maxdate={new Date().toISOString().substring(0, 10)} errorMessage={props.errors.birthdate} defaultValue={props.patient.birthdate ? props.patient.birthdate : new Date().toISOString().substring(0, 10)} handleChange={props.handleChange} name="birthdate" label="Fecha de nacimiento" />
                    <div>
                        <label className="text-sm font-medium text-gray-700">
                            Edad
                        </label>
                        <p className="mt-4">{props.patient.age}</p>
                    </div>
                </div>
                <div className="flex flex-wrap gap-x-8 gap-y-5 mt-8 px-4">
                    <TextInput value={props.patient.address} name="address" handleChange={props.handleChange} label="Domicilio (opcional)" placeholder="Opcional" className="shadow-sm h-8 w-60 px-2 border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm rounded-md" />
                    <SelectInput errorMessage={props.errors.neighbourhood} value={props.patient.neighbourhood} placeholder={true} name="neighbourhood" handleChange={props.handleChange} label="Compañía" options={props.locations} className="focus:ring-indigo-500 focus:border-indigo-500 block w-auto pr-10 py-1.5 sm:text-sm border-gray-300 rounded-md" />
                    <TextInput maxLength={20} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} value={props.patient.phone} name="phone" handleChange={props.handleChange} label="Celular / Teléfono (Opcional)" placeholder="Opcional" className="shadow-sm h-8 px-2 w-full border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm rounded-md" />
                </div>
                <div className="flex flex-wrap gap-x-8 gap-y-5 mt-8 px-4">
                    <div>
                        <label className="text-sm font-medium text-gray-700">Establecimiento</label>
                        <p className="mt-2 text-sm font-medium text-gray-900">
                            {props.establishments[establishment - 1]}
                        </p>
                    </div>
                    <DateInput maxdate={new Date().toISOString().substring(0, 10)} defaultValue={props.patient.registrationDate ? props.patient.registrationDate : new Date().toISOString().substring(0, 10)} handleChange={props.handleChange} name="registrationDate" label="Fecha de registro" />
                </div>
            </div>
            <div className="flex-col-reverse bg-gray-100" >
                <div className="flex p-4 justify-end">
                    <button
                        type="submit"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        Siguiente
                    </button>
                </div>
            </div>
        </form>
    )
}

export default function FormNewPatient(props) {

    const getAge = (dateString) => {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    useEffect(() => {
        if (props.patient.birthdate == new Date().toISOString().substring(0, 10) || !props.patient.birthdate)
            props.handleChange("age", "-")
        else {
            props.handleChange("age", getAge(props.patient.birthdate))
        }
    }, [props.patient.birthdate])

    return (
        <div>
            {props.tab === 'DATOS PERSONALES' && <PersonalData
                patient={props.patient}
                handleChange={props.handleChange}
                handleError={props.handleError}
                errors={props.errors}
                locations={props.locations}
                establishments={props.establishments}
                radio={props.radio}
                options={props.options}
                setTab={props.setTab}
            />
            }
            {props.tab === 'ANTECEDENTES' && <MedicalHistory
                patient={props.patient}
                errors={props.errors}
                setTab={props.setTab}
                handleError={props.handleError}
                handleChange={props.handleChange}
                loadingSubmit={props.loadingSubmit}
                handleSubmit={props.handleSubmit}
                submitError={props.submitError}
            />}
        </div>
    )
}