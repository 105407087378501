import React from "react";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function TextInput(props) {

    return (
        <span className={classNames("inline-flex rounded-full items-center py-0.5 px-3 text-sm font-medium bg-indigo-100 text-indigo-700", props.color)}>
            {props.text}
            {props.handleClose && <button
                type="button"
                className={classNames("flex-shrink-0 ml-1.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:outline-none focus:bg-indigo-500 focus:text-white", props.color, props.exitcolor)}
                onClick={props.handleClose}
            >
                <span className="sr-only">Remove large option</span>
                <svg className="h-2.5 w-2.5" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                    <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                </svg>
            </button>}
        </span>
    )

}
