import React, { useEffect, useState } from "react";
import {
    TrashIcon
} from '@heroicons/react/outline'
import InlineCheckbox from "./InlineCheckbox";
import SelectInput from "./SelectInput";
import TextInput from "./TextInput";
import DateInput from "./DateInput";
import DescribedSelectInput from "./DescribedSelectInput";

const Determinations = function (props) {

    const hours = [
        <option>1</option>,
        <option>2</option>,
        <option>3</option>,
        <option>4</option>,
        <option>5</option>,
        <option>6</option>,
        <option>7</option>,
        <option>8</option>,
        <option>9</option>,
        <option>10</option>,
        <option>11</option>,
        <option>12</option>,
        <option>13</option>,
        <option>14</option>,
        <option>15</option>,
        <option>16</option>,
        <option>17</option>,
        <option>18</option>,
        <option>19</option>,
        <option>20</option>,
        <option>21</option>,
        <option>22</option>,
        <option>23</option>,
    ]

    const goForward = function (props) {
        let error = false
        if (props.consultation.attentionDate === '') {
            props.handleError('attentionDate', 'Este campo es obligatorio')
            error = true
        }

        if (props.consultation.height === '' && props.consultation.place !== '3') {
            props.handleError('height', 'Este campo es obligatorio')
            error = true
        } else if (props.consultation.height > 300) {
            props.handleError('height', 'La altura no puede ser mayor a 300 cm')
            error = true
        } else {
            props.handleError('height', '')
        }

        if (props.consultation.weight === '' && props.consultation.place !== '3') {
            props.handleError('weight', 'Este campo es obligatorio')
            error = true
        } else if (props.consultation.weight && props.consultation.weight > 700) {
            props.handleError('weight', 'El peso no puede ser mayor a 700 kg')
            error = true
        } else {
            props.handleError('weight', '')
        }

        if (props.consultation.systolic === '') {
            props.handleError('systolic', 'Este campo es obligatorio')
            error = true
        } else if (props.consultation.systolic > 500) {
            props.handleError('systolic', 'La sistólica no puede ser mayor a 500 mmHg')
            error = true
        } else {
            props.handleError('systolic', '')
        }

        if (props.consultation.diastolic === '') {
            props.handleError('diastolic', 'Este campo es obligatorio')
            error = true
        } else if (props.consultation.diastolic > 500) {
            props.handleError('diastolic', 'La diastólica no puede ser mayor a 500 mmHg')
            error = true
        } else {
            props.handleError('diastolic', '')
        }

        if (!error) {
            props.setTab('Control anual')
        }
    }

    useEffect(() => {
        if (parseInt(props.consultation.bloodGlucose) < 128) {
            props.handleChange('poliuria', false)
            props.handleChange('polidipsia', false)
            props.handleChange('polifagia', false)
            props.handleChange('weightloss', false)
            props.handleChange('obsBloodGlucose', '')
        }
    }, [props.consultation.bloodGlucose])

    return (
        <div>
            <div className="px-4 py-5 sm:p-4">
                <div className="flex-col">
                    <h1 className="text-lg leading-6 font-medium text-gray-900">Determinaciones</h1>
                    <div className="flex flex-1 flex-wrap mt-2 gap-x-12">
                        <TextInput onKeyPress={(event) => { if (!/[0-9\.]/.test(event.key)) { event.preventDefault(); } }} errorOffset="-ml-19" errorMessage={props.errors.height} value={props.consultation.height} handleChange={props.handleChange} name="height" label="Talla" unit="cm" className="pr-10 shadow-sm h-8 w-28 pl-2 border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm rounded-md" />
                        <TextInput onKeyPress={(event) => { if (!/[0-9\.]/.test(event.key)) { event.preventDefault(); } }} errorOffset="-ml-18" errorMessage={props.errors.weight} value={props.consultation.weight} handleChange={props.handleChange} name="weight" label="Peso" unit="kg" className="pr-10 shadow-sm h-8 w-28 pl-2 border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm rounded-md" />
                        <div className="px-6 mt-1">
                            <label htmlFor="imc" className="block text-sm leading-5 font-medium text-gray-700">
                                IMC
                            </label>
                            <div className="mt-2">
                                <p className="text-sm leading-5 font-normal text-gray-900">{props.consultation.imc ? props.consultation.imc : '-'}</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-1 flex-wrap mt-2 gap-x-12">
                        <TextInput onKeyPress={(event) => { if (!/[0-9\.]/.test(event.key)) { event.preventDefault(); } }} offset="-ml-6" errorMessage={props.errors.waist} value={props.consultation.waist} handleChange={props.handleChange} name="waist" label="Cintura (opcional)" unit="cm" className="pr-10 shadow-sm h-8 w-28 pl-2 border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm rounded-md" />
                    </div>
                    <div className="flex flex-1 flex-wrap mt-2 gap-x-12">
                        <TextInput onKeyPress={(event) => { if (!/[0-9\.]/.test(event.key)) { event.preventDefault(); } }} offset="-ml-25" description="Rango aceptable: 130 mmHg" errorMessage={props.errors.systolic} value={props.consultation.systolic} handleChange={props.handleChange} name="systolic" label="Sistólica" unit="mmHg" className="pr-14 shadow-sm h-8 w-28 pl-2 border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm rounded-md" />
                        <TextInput onKeyPress={(event) => { if (!/[0-9\.]/.test(event.key)) { event.preventDefault(); } }} offset="-ml-25" description="Rango aceptable: 85 mmHg" errorMessage={props.errors.diastolic} value={props.consultation.diastolic} handleChange={props.handleChange} name="diastolic" label="Diastólica" unit="mmHg" className="pr-14 shadow-sm h-8 w-28 pl-2 border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm rounded-md" />
                    </div>
                    <div className="flex flex-1 mt-4 gap-x-12">
                        <TextInput onKeyPress={(event) => { if (!/[0-9\.]/.test(event.key)) { event.preventDefault(); } }} offset="-ml-27" value={props.consultation.bloodGlucose} handleChange={props.handleChange} name="bloodGlucose" label="Glucemia capilar (opcional)" unit="mg/dL" className="shadow-sm h-8 pl-2 pr-16 w-28 border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm rounded-md" />
                        <DescribedSelectInput defaultValue={props.consultation.lastMeal} handleChange={props.handleChange} name="lastMeal" label="Última comida (opcional)" placeholder="horas" options={hours} />
                    </div>
                    {parseInt(props.consultation.bloodGlucose) >= 126 ?
                        <>
                            <h1 className="text-sm leading-6 mt-2 font-semibold text-gray-700">*En caso de Glucemia mayor a 126, preguntar sobre:</h1>
                            <div className="flex gap-x-12">
                                <div className="flex gap-x-10 mt-4">
                                    <InlineCheckbox checked={props.consultation.poliuria} handleChange={props.handleChange} name="poliuria" label="Poliuria" />
                                    <InlineCheckbox checked={props.consultation.polidipsia} handleChange={props.handleChange} name="polidipsia" label="Polidipsia" />
                                    <InlineCheckbox checked={props.consultation.polifagia} handleChange={props.handleChange} name="polifagia" label="Polifagia" />
                                    <InlineCheckbox checked={props.consultation.weightloss} handleChange={props.handleChange} name="weightloss" label="Pérdida de peso" />
                                </div>
                                <TextInput value={props.consultation.obsBloodGlucose} handleChange={props.handleChange} name="obsBloodGlucose" label="Observaciones" />
                            </div>
                        </>
                        : null}
                    <div className="mt-6 flex flex-1 w-2/3">
                        <div className="w-48">
                            <label htmlFor="date" className="block text-base font-medium text-gray-900">
                                Próxima visita
                            </label>
                            <DateInput defaultValue={props.consultation.nextVisit} handleChange={props.handleChange} name="nextVisit" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="px-4 py-4 sm:px-6 bg-gray-100">
                <div className="flex justify-end">
                    <button
                        onClick={() => goForward(props)}
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        Siguiente
                    </button>
                </div>
            </div>
        </div >
    )
}

const AnualCheck = function (props) {

    const options = [
        <option value={0}>No realizado</option>,
        <option value={1}>Positivo</option>,
        <option value={2}>Negativo</option>
    ]

    const fundus = [
        <option value={0}>No realizado</option>,
        <option value={1}>Con hallazgos</option>,
        <option value={2}>Sin hallazgos</option>
    ]

    return (
        <div>
            <div className="px-4 py-5 sm:p-4">
                <div className="flex-col">
                    <h1 className="text-lg leading-6 font-medium text-gray-900">Control anual</h1>
                    <div>
                        <div className="mt-6 flex flex-1 gap-x-6">
                            <SelectInput defaultValue={props.consultation.diabeticFoot} handleChange={props.handleChange} name="diabeticFoot" label="Pie diabético" options={options} />
                            <TextInput value={props.consultation.diabeticFootObs} handleChange={props.handleChange} name="diabeticFootObs" containerClassName="w-full" className="shadow-sm h-8 pl-2 w-full border border-gray-300 block sm:text-sm rounded-md" label="Observaciones del pie diabético (opcional)" />
                        </div >
                        <div className="mt-4 flex gap-x-16">
                            <InlineCheckbox checked={props.consultation.diabeticFootDerive} handleChange={props.handleChange} name="diabeticFootDerive" label="Derivar" />
                            <InlineCheckbox checked={props.consultation.diabeticFootReferToLab} handleChange={props.handleChange} name="diabeticFootReferToLab" label="Derivar al lab" />
                        </div>
                    </div>
                    <div>
                        <div className="mt-12 flex flex-1 gap-x-6">
                            <SelectInput defaultValue={props.consultation.fundus} handleChange={props.handleChange} name="fundus" label="Fondo de ojo" options={fundus} />
                            <TextInput value={props.consultation.fundusObs} handleChange={props.handleChange} name="fundusObs" containerClassName="w-full" className="shadow-sm h-8 pl-2 w-full border border-gray-300 block sm:text-sm rounded-md" label="Observaciones de fondo de ojo (opcional)" />
                        </div >
                        <div className="mt-4 flex gap-x-16">
                            <InlineCheckbox checked={props.consultation.fundusDerive} handleChange={props.handleChange} name="fundusDerive" label="Derivar a oftalmólogo" />
                        </div>
                    </div>
                    <div>
                        <div className="mt-12 flex flex-1 gap-x-6">
                            <SelectInput defaultValue={props.consultation.urineAnalysis} handleChange={props.handleChange} name="urineAnalysis" label="Análisis de orina 24hs" options={options} />
                            <TextInput value={props.consultation.urineAnalysisObs} handleChange={props.handleChange} name="urineAnalysisObs" containerClassName="w-full" className="shadow-sm h-8 pl-2 w-full border border-gray-300 block sm:text-sm rounded-md" label="Observaciones de resultado del análisis de orina para función renal (opcional)" />
                        </div >
                        <div className="mt-4 flex gap-x-16">
                            <InlineCheckbox checked={props.consultation.urineAnalysisReferToLab} handleChange={props.handleChange} name="urineAnalysisReferToLab" label="Derivar al lab" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="px-4 py-4 sm:px-6 bg-gray-100">
                <div className="flex justify-between">
                    <button
                        onClick={() => props.setTab('Determinaciones')}
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm bg-white text-blue-600 hover:text-blue-800"
                    >
                        Anterior
                    </button>
                    <button
                        onClick={() => props.setTab('Medicamentos')}
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        Siguiente
                    </button>
                </div>
            </div>
        </div >
    )
}

const Medications = function (props) {

    const DmOptions = [
        <option value={0}>Metformina 850 mg</option>,
        <option value={1}>Glimepirida 2 mg</option>,
        <option value={2}>Glimepirida 4 mg</option>,
        <option value={3}>Sitagliptina 100 mg</option>,
        <option value={4}>Insulina NPH</option>,
        <option value={5}>Insulina Cristalina</option>,
        <option value={6}>Insulina Glargina 100 mg</option>,
        <option value={7}>Insulina Glargina 300 mg</option>,
        <option value={8}>Atorvastatina 40 mg</option>,
        <option value={9}>Gemfibrozilo 600 mg</option>,
        <option value={''}>Otro medicamento</option>
    ]

    const HtaOptions = [
        <option value={0}>AAS 125 mg</option>,
        <option value={1}>Atenolol 50 mg</option>,
        <option value={2}>Valsartan 50 mg</option>,
        <option value={3}>Losartan 50 mg</option>,
        <option value={4}>Losartan 100 mg</option>,
        <option value={5}>Enalapril 5 mg</option>,
        <option value={6}>Enalapril 10 mg</option>,
        <option value={7}>Enalapril 20 mg</option>,
        <option value={8}>Losartan 160 mg</option>,
        <option value={9}>Amlodipina 10 mg</option>,
        <option value={10}>Hidroclorotiazida 25 mg</option>,
        <option value={11}>Valsartan 160 mg</option>,
        <option value={12}>Anlodipino 5 mg</option>,
        <option value={13}>Anlodipino 10 mg</option>,
        <option value={14}>Atorvastatina 40 mg</option>,
        <option value={15}>Carvedidol 6,25 mg</option>,
        <option value={16}>Carvedidol 25 mg</option>,
        <option value={17}>Furosemida 40 mg</option>,
        <option value={18}>Espironolactona 25 mg</option>,
        <option value={''}>Otro medicamento</option>
    ]

    useEffect(() => {
        if (props.consultation.hasDmMedication && props.consultation.dmMedications.length == 0) {
            props.addDmMedication()
        } else if (!props.consultation.hasDmMedication) {
            props.handleChange('dmMedications', [])
        }
    }, [props.consultation.hasDmMedication])

    useEffect(() => {
        if (props.consultation.hasHtaMedication && props.consultation.htaMedications.length == 0) {
            props.addHtaMedication()
        } else if (!props.consultation.hasHtaMedication) {
            props.handleChange('htaMedications', [])
        }
    }, [props.consultation.hasHtaMedication])

    return (
        <div>
            <div className="px-4 py-5 sm:p-4">
                <div className="flex-col">
                    <h1 className="text-lg leading-6 font-medium text-gray-900">Medicamentos</h1>
                    <div className="flex-col flex-1 mt-6 space-y-6">
                        <InlineCheckbox checked={props.consultation.hasDmMedication} handleChange={props.handleChange} name="hasDmMedication" label="Toma medicamentos para DM" labelClassName="font-semibold text-gray-900" />
                        {props.consultation.hasDmMedication ?
                            <>
                                {props.consultation.dmMedications.map((element, Idx) => {
                                    return (
                                        <div className="flex mt-2 items-end gap-x-4">
                                            <SelectInput value={element.medicine} Idx={Idx} handleChange={props.handleDmMedication} name="medicine" label={`Medicamento ${Idx + 1}`} containerStyle="flex-1 max-w-xxs" className="shadow-sm h-9 w-full border border-gray-300 block sm:text-sm rounded-md" options={DmOptions} />
                                            {props.consultation.dmMedications[Idx].medicine == '' ?
                                                <TextInput value={element.otherMedicine} Idx={Idx} handleChange={props.handleDmMedication} name="otherMedicine" label="Nombre del medicamento" containerClassName="flex-1" className="shadow-sm h-9 w-full px-2 border border-gray-300 block sm:text-sm rounded-md" />
                                                : null}
                                            <TextInput value={element.indications} Idx={Idx} handleChange={props.handleDmMedication} name="indications" label="Indicaciones" containerClassName="flex-1" className="shadow-sm h-9 px-2 w-full border border-gray-300 block sm:text-sm rounded-md" />
                                            <button
                                                type="button"
                                                className="text-red-600 hover:text-red-900 ml-3 pb-1.5"
                                                onClick={() => props.removeDmMedication(Idx)}
                                            >
                                                <TrashIcon className="h-6 w-6" aria-hidden="true" />
                                            </button>
                                        </div>
                                    )
                                })}
                                <button type="button" onClick={() => props.addDmMedication()} className="text-blue-600 text-normal">Agregar otro</button>
                            </>
                            : null}
                    </div>
                    <div className="flex-col flex-1 mt-6 flex-wrap space-y-6">
                        <InlineCheckbox checked={props.consultation.hasHtaMedication} handleChange={props.handleChange} name="hasHtaMedication" label="Toma medicamentos para HTA" labelClassName="font-semibold text-gray-900" />
                        {props.consultation.hasHtaMedication ?
                            <>
                                {props.consultation.htaMedications.map((element, Idx) => {
                                    return (
                                        <div className="flex mt-2 items-end gap-x-4">
                                            <SelectInput value={element.medicine} Idx={Idx} handleChange={props.handleHtaMedication} name="medicine" label={`Medicamento ${Idx + 1}`} containerStyle="flex-1 max-w-xxs" className="shadow-sm h-9 w-full border border-gray-300 block sm:text-sm rounded-md" options={HtaOptions} />
                                            {props.consultation.htaMedications[Idx].medicine == '' &&
                                                <TextInput value={element.otherMedicine} Idx={Idx} handleChange={props.handleHtaMedication} name="otherMedicine" label="Nombre del medicamento" containerClassName="flex-1" className="shadow-sm h-9 w-full px-2 border border-gray-300 block sm:text-sm rounded-md" />
                                            }
                                            <TextInput value={element.indications} Idx={Idx} handleChange={props.handleHtaMedication} name="indications" label="Indicaciones" containerClassName="flex-1" className="shadow-sm h-9 px-2 w-full border border-gray-300 block sm:text-sm rounded-md" />
                                            <button
                                                type="button"
                                                className="text-red-600 hover:text-red-900 ml-3 pb-1.5"
                                                onClick={() => props.removeHtaMedication(Idx)}
                                            >
                                                <TrashIcon className="h-6 w-6" aria-hidden="true" />
                                            </button>
                                        </div>
                                    )
                                })}
                                <button type="button" onClick={() => props.addHtaMedication()} className="text-blue-600 text-normal">Agregar otro</button>
                            </>
                            : null}
                    </div>
                </div>
            </div>
            <div className="mt-2 px-4 py-4 sm:px-6 bg-gray-100">
                <div className="flex justify-between">
                    <button
                        onClick={() => props.setTab('Control anual')}
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm bg-white text-blue-600 hover:text-blue-800"
                    >
                        Anterior
                    </button>
                    <button
                        onClick={() => props.setTab('Laboratorio')}
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        Siguiente
                    </button>
                </div>
            </div>
        </div>
    )
}

const Laboratory = function (props) {
    return (
        <div>
            <div className="px-4 py-5 sm:p-4">
                <div className="flex-col">
                    <h1 className="text-lg leading-6 font-medium text-gray-900 mb-4">Laboratorio</h1>

                    <div className="flex flex-1 flex-wrap mb-8 gap-x-6 gap-y-2">
                        <TextInput onKeyPress={(event) => { if (!/[0-9\.]/.test(event.key)) { event.preventDefault(); } }} offset="-ml-26" description="Rango aceptable: < 100 mg/dL" value={props.consultation.basalGlucose} handleChange={props.handleChange} label="Glucosa basal" name="basalGlucose" unit="mg/dL" className="pr-14 shadow-sm h-8 w-28 pl-2 border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm rounded-md" />
                        <TextInput onKeyPress={(event) => { if (!/[0-9\.]/.test(event.key)) { event.preventDefault(); } }} offset="-ml-31" description="Rango aceptable: 181 a 199 mg/dL" value={props.consultation.totalCholesterol} handleChange={props.handleChange} label="Colesterol total" name="totalCholesterol" unit="mg/dL" className="pr-14 shadow-sm h-8 w-28 pl-2 border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm rounded-md" />
                        <TextInput onKeyPress={(event) => { if (!/[0-9\.]/.test(event.key)) { event.preventDefault(); } }} offset="-ml-23" description="Rango aceptable: 6.6 a 7.5 %" value={props.consultation.hba1c} handleChange={props.handleChange} label="HbA1C" name="hba1c" unit="%" className="pr-14 shadow-sm h-8 w-20 pl-2 border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm rounded-md" />
                    </div>
                    <div className="flex flex-1 flex-wrap mb-8 gap-x-6 gap-y-2">
                        <TextInput onKeyPress={(event) => { if (!/[0-9\.]/.test(event.key)) { event.preventDefault(); } }} offset="-ml-26" description="Rango aceptable: < 130 mg/dL" value={props.consultation.ldlCol} handleChange={props.handleChange} label="LDL col" name="ldlCol" unit="mg/dL" className="pr-14 shadow-sm h-8 w-28 pl-2 border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm rounded-md" />
                        <TextInput onKeyPress={(event) => { if (!/[0-9\.]/.test(event.key)) { event.preventDefault(); } }} offset="-ml-24" description="Rango aceptable: ≤ 35 mg/dL" value={props.consultation.hdlCol} handleChange={props.handleChange} label="HDL col" name="hdlCol" unit="mg/dL" className="pr-14 shadow-sm h-8 w-28 pl-2 border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm rounded-md" />
                    </div>
                    <div className="flex flex-1 flex-wrap gap-x-6 gap-y-2">
                        <TextInput onKeyPress={(event) => { if (!/[0-9\.]/.test(event.key)) { event.preventDefault(); } }} offset="-ml-32" description="Rango aceptable: 150 a 200 mg/dL" value={props.consultation.triglycerides} handleChange={props.handleChange} label="Triglicéridos" name="triglycerides" unit="mg/dL" className="pr-14 shadow-sm h-8 w-28 pl-2 border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm rounded-md" />
                        <TextInput onKeyPress={(event) => { if (!/[0-9\.]/.test(event.key)) { event.preventDefault(); } }} offset="-ml-31" description="Rango aceptable: 0.50 a 1.4 mg/dL" value={props.consultation.creatinine} handleChange={props.handleChange} label="Creatinina" name="creatinine" unit="mg/dL" className="pr-14 shadow-sm h-8 w-28 pl-2 border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm rounded-md" />
                    </div>

                </div>
            </div>
            <div className="mt-2 px-4 py-4 sm:px-6 bg-gray-100">
                <div className="flex justify-between">
                    <button
                        onClick={() => props.setTab('Medicamentos')}
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm bg-white text-blue-600 hover:text-blue-800"
                    >
                        Anterior
                    </button>
                    <button
                        onClick={() => props.setTab('Hábitos')}
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        Siguiente
                    </button>
                </div>
            </div>
        </div>
    )
}

const Habits = function (props) {

    const servings = [
        <option>1</option>,
        <option>2</option>,
        <option>3</option>,
        <option>4</option>,
        <option>5</option>,
        <option>6</option>,
        <option>7</option>,
        <option>8</option>,
        <option>9</option>,
        <option>10</option>,
    ]

    const hours = [
        <option>1</option>,
        <option>2</option>,
        <option>3</option>,
        <option>4</option>,
        <option>5</option>,
        <option>6</option>,
        <option>7</option>,
        <option>8</option>,
        <option>9</option>,
        <option>10</option>,
        <option>11</option>,
        <option>12</option>,
    ]

    const times = [
        <option>1</option>,
        <option>2</option>,
        <option>3</option>,
        <option>4</option>,
        <option>5</option>,
        <option>6</option>,
        <option>7</option>,
        <option>8</option>,
        <option>9</option>,
        <option>10</option>,
        <option>11</option>,
        <option>12</option>,
        <option>13</option>,
        <option>14</option>,
        <option>15</option>,
        <option>16</option>,
        <option>17</option>,
        <option>18</option>,
        <option>19</option>,
        <option>20</option>,
        <option>21</option>,
        <option>22</option>,
        <option>23</option>,
    ]

    const minutes = [
        <option>15</option>,
        <option>20</option>,
        <option>30</option>,
        <option>45</option>,
        <option>60</option>,
        <option>90</option>,
        <option>120</option>,
    ]

    return (
        <div>
            <div className="px-4 py-5 sm:p-4">
                <div className="flex-col">
                    <h1 className="text-lg leading-6 font-medium text-gray-900">Hábitos</h1>
                    <div className="flex-col flex-1">
                        <div className="mt-8 flex flex-1 gap-x-10">
                            <p className="w-30 leading-5 font-medium text-gray-900">
                                Alimentación
                            </p>
                            <div className="flex-col flex-1 pr-8 space-y-5">
                                <div className="flex flex-1 flex-wrap gap-x-4 gap-y-4 justify-between">
                                    <InlineCheckbox checked={props.consultation.addSaltWhenCooking} handleChange={props.handleChange} name="addSaltWhenCooking" label="Agrega sal al cocinar" />
                                    <InlineCheckbox checked={props.consultation.addSaltToFood} handleChange={props.handleChange} name="addSaltToFood" label="Agrega sal a la comida" description="Una vez servida" />
                                </div>
                                <div className="flex flex-1 flex-wrap gap-x-4 gap-y-4 justify-between">
                                    <DescribedSelectInput defaultValue={props.consultation.fruitServingsPerDay} handleChange={props.handleChange} label="Porciones de frutas" name="fruitServingsPerDay" options={servings} placeholder="por día" />
                                    <DescribedSelectInput defaultValue={props.consultation.vegetableServingsPerDay} handleChange={props.handleChange} label="Porciones de hortalizas" name="vegetableServingsPerDay" options={servings} placeholder="por día" />
                                </div>
                            </div>
                        </div>
                        <div className="mt-8 flex flex-1 gap-x-10">
                            <p className="w-30 leading-5 font-medium text-gray-900">
                                Actividad física
                            </p>
                            <div className="flex-col flex-1 pr-8 space-y-3">
                                <div className="flex flex-1 flex-wrap gap-x-4 gap-y-3 justify-between">
                                    <InlineCheckbox containerClassName="relative flex pr-2 items-start" checked={props.consultation.performPhysicalActivity} handleChange={props.handleChange} name="performPhysicalActivity" label="Realiza actividad física" />
                                    <TextInput containerClassName="" value={props.consultation.physicalActivityType} handleChange={props.handleChange} name="physicalActivityType" label="Tipo de actividad" />
                                </div>
                                <div className="flex flex-1 flex-wrap gap-x-4 gap-y-3 justify-between">
                                    <DescribedSelectInput containerClassName="" placeholderClassName="focus:ring-indigo-500 focus:border-indigo-500 block w-40 pl-16 sm:text-sm border-gray-300 rounded-md" defaultValue={props.consultation.physicalActivityDuration} handleChange={props.handleChange} label="Duración" name="physicalActivityDuration" options={minutes} placeholder="min por día" />
                                    <DescribedSelectInput placeholderClassName="focus:ring-indigo-500 focus:border-indigo-500 block w-44 pl-16 sm:text-sm border-gray-300 rounded-md" defaultValue={props.consultation.physicalActivityFrequency} handleChange={props.handleChange} label="Frecuencia" name="physicalActivityFrequency" options={hours} placeholder="hs por semana" />
                                </div>
                            </div>
                        </div>
                        <div className="mt-8 flex flex-1 gap-x-10">
                            <p className="w-30 leading-5 font-medium text-gray-900">
                                Tabaquismo
                            </p>
                            <div className="flex-col flex-1 pr-8 space-y-6">
                                <div className="flex flex-1 flex-wrap gap-x-4 gap-y-3 justify-between">
                                    <InlineCheckbox checked={props.consultation.smoke} handleChange={props.handleChange} name="smoke" label="Fuma" />
                                    <InlineCheckbox checked={props.consultation.vape} handleChange={props.handleChange} name="vape" label="Vapea" />
                                    <InlineCheckbox checked={props.consultation.wantToQuitSmoking} handleChange={props.handleChange} name="wantToQuitSmoking" label="Desea dejar de fumar" />
                                </div>
                                <div className="flex flex-1 flex-wrap gap-x-4 gap-y-3 justify-between">
                                    <DescribedSelectInput defaultValue={props.consultation.cigarettesPerDay} handleChange={props.handleChange} label="Cantidad de cigarrillos" placeholder="por día" options={servings} name="cigarettesPerDay" />
                                    <DescribedSelectInput defaultValue={props.consultation.timeOfTheFirstCigarette} handleChange={props.handleChange} label="Hora del día del primer cigarrillo" placeholder="hs" options={times} name="timeOfTheFirstCigarette" />
                                </div>
                            </div>
                        </div>
                        <div className="mt-8 flex flex-1 gap-x-10">
                            <p className="w-30 leading-5 font-medium text-gray-900">
                                Bebidas alcohólicas
                            </p>
                            <div className="flex flex-1 flex-wrap gap-x-4 gap-y-3 justify-between">
                                <InlineCheckbox containerClassName="relative flex pr-2 items-start" checked={props.consultation.drinkAlcoholicBeverages} handleChange={props.handleChange} name="drinkAlcoholicBeverages" label="Consume alcohol" />
                                <DescribedSelectInput defaultValue={props.consultation.drinksPerDay} handleChange={props.handleChange} name="drinksPerDay" label="Cantidad por día" placeholder="litros" options={servings} />
                                <DescribedSelectInput defaultValue={props.consultation.drinksPerWeek} handleChange={props.handleChange} name="drinksPerWeek" label="Cantidad por semana" placeholder="litros" options={servings} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-2 px-4 py-4 sm:px-6 bg-gray-100">
                <div className="flex justify-between">
                    <button
                        onClick={() => props.setTab('Laboratorio')}
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm bg-white text-blue-600 hover:text-blue-800"
                    >
                        Anterior
                    </button>
                    <button
                        onClick={() => props.setTab('Educación')}
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        Siguiente
                    </button>
                </div>
            </div>
        </div >
    )
}

const Education = function (props) {

    return (
        <div>
            <div className="px-4 py-5 sm:p-4">
                <div className="flex-col">
                    <h1 className="text-lg leading-6 font-medium text-gray-900">Educación</h1>
                    <div className="mt-6 flex flex-1 justify-between">
                        <p className="text-sm leading-5 font-medium text-gray-900">
                            ¿Recibió educación sobre <br /> factores de riesgo?
                        </p>
                        <div className="ml-6 mr-2 flex-col flex-1 space-y-6">
                            <div className="flex flex-1 flex-wrap gap-x-2 gap-y-2 justify-between">
                                <InlineCheckbox checked={props.consultation.nutritionEducation} handleChange={props.handleChange} name="nutritionEducation" label="Alimentación" />
                                <InlineCheckbox checked={props.consultation.physicalActivityEducation} handleChange={props.handleChange} name="physicalActivityEducation" label="Actividad física" />
                                <InlineCheckbox checked={props.consultation.smokingEducation} handleChange={props.handleChange} name="smokingEducation" label="Tabaquismo" />
                                <InlineCheckbox checked={props.consultation.alcoholicBeveragesEducation} handleChange={props.handleChange} name="alcoholicBeveragesEducation" label="Bebidas alcohólicas" />
                            </div>
                            <div className="flex flex-1 flex-wrap gap-x-2 gap-y-2 justify-between">
                                <InlineCheckbox checked={props.consultation.importanceHtaMedication} handleChange={props.handleChange} name="importanceHtaMedication" label="Importancia sobre medicamento HTA" />
                                <InlineCheckbox checked={props.consultation.importanceDmMedication} handleChange={props.handleChange} name="importanceDmMedication" label="Importancia sobre medicamento DM" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-2 px-4 py-4 sm:px-6 bg-gray-100">
                <div className="flex justify-between">
                    <button
                        onClick={() => props.setTab('Hábitos')}
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm bg-white text-blue-600 hover:text-blue-800"
                    >
                        Anterior
                    </button>
                    <button
                        onClick={(event) => props.handleSubmit(event)}
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        {props.loadingSubmit ?
                            <div className="w-5 h-5 mx-14 border-b-2 border-white rounded-full animate-spin"></div> :
                            'Finalizar y guardar'
                        }
                    </button>
                </div>
            </div>
        </div >
    )
}

export default function FormNewConsultation(props) {

    return (
        <div>
            {props.tab === 'Determinaciones' && <Determinations
                consultation={props.consultation}
                handleChange={props.handleChange}
                setTab={props.changeTab}
                errors={props.errors}
                handleError={props.handleError}
            />
            }
            {props.tab === 'Control anual' && <AnualCheck
                consultation={props.consultation}
                handleChange={props.handleChange}
                setTab={props.changeTab}
            />}
            {props.tab === 'Medicamentos' && <Medications
                consultation={props.consultation}
                handleChange={props.handleChange}
                setTab={props.changeTab}
                addDmMedication={props.addDmMedication}
                addHtaMedication={props.addHtaMedication}
                removeDmMedication={props.removeDmMedication}
                removeHtaMedication={props.removeHtaMedication}
                handleDmMedication={props.handleDmMedication}
                handleHtaMedication={props.handleHtaMedication}
            />}
            {props.tab === 'Laboratorio' && <Laboratory
                consultation={props.consultation}
                handleChange={props.handleChange}
                setTab={props.changeTab}
            />}
            {props.tab === 'Hábitos' && <Habits
                consultation={props.consultation}
                handleChange={props.handleChange}
                setTab={props.changeTab}
            />}
            {props.tab === 'Educación' && <Education
                loadingSubmit={props.loadingSubmit}
                consultation={props.consultation}
                handleChange={props.handleChange}
                setTab={props.changeTab}
                handleSubmit={props.handleSubmit}
            />}

        </div>
    )
}